import React from 'react'

const Last = () => {
  return (
    <div className='flex flex-col  items-center justify-center'>
        <h3 className='  text-[18px] md:text-[20px] mt-4 text-_25253C leading-[41.3px]  lg:text-[25px] lg:leading-[61.6px] lg:text-left'>
           RTO w/ Lease-Purchase Back To Seller
        </h3>
        <p className='font-normal text-[15px] md:text-[18px] text-center text-[#6F6C90] max-w-[801px]'>Thank you for submitting this form and offering us the opportunity to evaluate this possible transaction.</p>
        <button
          onClick={""}
          className="border mt-5 cursor-pointer border-[#002248] shadow px-5 h-[60px] py-2 rounded-[10px] "
        >
          Go to List
        </button>
    </div>
  )
}

export default Last