import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'classnames'
import { ReactComponent as Logo } from 'modules/common/assets/icons/logo-dark.svg'
import { ReactComponent as Hamburger } from 'modules/common/assets/icons/hamburger.svg'
import { ReactComponent as Exit } from 'modules/common/assets/icons/exit.svg'
import { getTopNavLinks } from 'modules/common/components/navigation/TopNav'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'
import { ReactComponent as LargeLogo } from 'modules/common/assets/icons/logo-large.svg'
import { useAuth, useWindowSize } from 'modules/common/hooks'
import { changeBodyScrollStatusTo, removeFromLS } from 'modules/common/utils/functions'
import { removeToken } from 'api'

const { routes } = CONSTANTS

export const adminLinks = [
    { text: "Add Listing", route: routes.admin.addListing },
    { text: "View Property List", route: routes.admin.properties },
    { text: "View Rtow Form", route: routes.admin.rtoFormList },
    { text: "Fair Cash Applications", route: routes.admin.fairCashApplications },
    { text: "Spy Form Submissions", route: routes.admin.spyFormSubmissions },
    { text: "Delete Listing", route: routes.admin.deleteProperties },
    { text: "Invite user", route: routes.admin.inviteUser }
]


export const AdminTopNavMenu = ({ isVisible, onClose, authInfo, setAuthInfo }) => {
    const navigate = useNavigate()
    useEffect(() => {
        changeBodyScrollStatusTo(!isVisible)
    }, [isVisible])


    return (
        <div className={clsx('fixed top-0 left-0 h-screen z-[4] flex flex-col space-y-[68px] pb-[100px] overflow-y-auto w-screen bg-_25253C transition-all',
            { "!pointer-events-none !opacity-0": !isVisible })}>
            <Exit className='top-[34px] right-[30px] absolute' onClick={() => onClose()} />
            <div className='mt-[17vh] flex items-center justify-center'>
                <Link to={routes.home}>
                    <LargeLogo />
                </Link>
            </div>
            <ul className='flex flex-col space-y-[50px] items-center'>
                {(authInfo ? adminLinks : getTopNavLinks(navigate)).map((link, index) => (
                    <li key={index} onClick={onClose}>
                        {link.handler ?
                            <button onClick={link.handler} className='text-base leading-[18px] text-white font-bold text-center'>
                                {link.text}
                            </button>
                            :
                            link.route
                                ?
                                <NavLink to={link.route} className={({ isActive }) => clsx("text-base leading-[18px] text-white font-bold text-center", {
                                    "!text-FEDF18": isActive
                                })}>{link.text}</NavLink>
                                :
                                <span className='text-base leading-[18px] text-white font-bold text-center'>{link.text}</span>
                        }
                    </li>
                ))}
                {authInfo && <li>
                    <button onClick={() => {
                        removeFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA)
                        removeToken()
                        setAuthInfo(null)
                    }} className='text-base leading-[18px] text-white font-bold text-center'>
                        Log out
                    </button>
                </li>}
            </ul>
        </div>
    )
}

const AdminTopNav = () => {
    const { authInfo, setAuthInfo } = useAuth()
    const [showMenu, setShowMenu] = useState(false)
    const { width } = useWindowSize()
    const navigate = useNavigate()
    const topNavMenuLinks = useMemo(() => getTopNavLinks(navigate), [navigate])
    const isSmall = width <= 1010


    return (
        <div className='h-[74px] bg-white fixed top-0 left-0 z-[2] w-full px-5 1010:px-[60px]'>
            <div className='max-w-[1440px] mx-auto w-full h-[74px] flex items-center justify-between'>
                <Logo onClick={() => navigate(CONSTANTS.routes.home)} className='cursor-pointer' />
                {

                    (isSmall) ?
                        <Hamburger
                            onClick={() => setShowMenu(true)} className='cursor-pointer stroke-_25253C' />
                        : <ul className='flex items-center space-x-[50px] justify-center h-full'>
                            {topNavMenuLinks.map((link, index) => (
                                <li key={index} className='flex items-center justify-center h-full'>
                                    {link.handler ?
                                        <button onClick={link.handler} className='text-base leading-[18px] text-_25253C font-bold flex items-center justify-center h-full'>
                                            {link.text}
                                        </button>
                                        :
                                        link.route
                                            ?
                                            <NavLink to={link.route} className={({ isActive }) => clsx("text-base leading-[18px] text-_25253C font-bold flex items-center justify-center h-full", {
                                                "!text-FEDF18": isActive
                                            })}>{link.text}</NavLink>
                                            :
                                            <span className='text-base leading-[18px] text-_25253C font-bold flex items-center justify-center h-full'>{link.text}</span>
                                    }
                                </li>
                            ))}
                        </ul>}
                {
                    !isSmall &&
                    <Logo className='opacity-0 pointer-events-none' />}
            </div>
            {isSmall && <AdminTopNavMenu isVisible={showMenu}
                onClose={() => setShowMenu(false)}
                authInfo={authInfo}
                setAuthInfo={setAuthInfo}
            />
            }
        </div>
    )
}

export default AdminTopNav