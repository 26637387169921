import React from 'react'
import { CommaSeperatedNumber } from 'modules/common/components'

const Label = ({ text, subtext }) => {
    return (
        <>
            {text && (
                <h3 className='font-bold text-[18px]  text-_25253C leading-6 lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold  lg:text-left'>
                    {text}
                </h3>
            )}
            {subtext && (
                <p className='font-normal text-[#6F6C90] !my-0'>
                    {subtext}
                </p>
            )}
        </>
    );
};

const Paragraph = ({ text }) => {
    return (
        <>
            {text && (
              <p className='text-_25253C  border-b border-b-[#EEEEEE] leading-6 1436:text-[18px] 1436:leading-[34px]'>
                  {text}
              </p>
            )}
        </>
    );
};

const MotgageDetails = ({detail}) => {
    const {firstMortgageExists, 
        payoffAmount,
        reInstatementAmount,
        interestRateOfLoan,
        isFixedInterestRate,
        totalMonthlyPayment,
        approximatePrincipalPaidDownMonthly,
        durationLeftOnLoanInYears,
        otherMortgagesAgainstPropertyExist,
        additionalEncumbrances,
    } = detail.mortgageDetails
   return (
         <div>  
             <div className='lg:flex  lg:justify-between 1400:mx-auto 1400:max-w-[1320px]'>
                 {/* <SellYourHouseDiamonds className='absolute h-full w-full left-0 top-0 lg:block hidden' /> */}
                 <div className='flex flex-col items-center justify-center'>
                     <div className=' shadow mx-auto z-[1]  rounded-[11.89px] lg:rounded-[20.22px]  px-[17px] lg:px-[30px]  lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] md:mb-[30px]'>
                      <h3 className='mt-[29px] font-black text-[20px] text-_25253C leading-[41.3px]  lg:text-[40px] md:mb-5 lg:font-bold lg:leading-[61.6px] lg:text-left'>
                        First Mortgage Details
                       </h3>
                         <div className='flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-5 md:mt-10'>
                         <Label text={"Is there a First Mortgage on the property?"}/> 
                         <Paragraph text={firstMortgageExists ? "Yes" : "No"}/>

                          <Label text={"Payoff Amount"}/> 
                          <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={payoffAmount}
                                         dollarSign
                                      />
                                    }
                            />  
                          
                          <div className='flex flex-col md:flex-row items md:space-x-3'>
                             <div className='flex-1'>
                              <Label text={"Reinstatement Amount"}/>
                              <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={reInstatementAmount}
                                         dollarSign
                                      />
                                    }
                                 />
                             </div>
                             <div className='flex-1'>
                              <Label text={"Interest Rate of the Loan (not APR)"}/>
                              <Paragraph text={`${interestRateOfLoan}%`} />
                             </div>
                            </div>

                            <Label text={"Is it a fixed rate?"}/>
                             <Paragraph text={isFixedInterestRate ? "Yes" : "No"}/>

                             <Label text={"Total Monthly Payment"} subtext={"(Are property taxes currently approx. 1% of the assessed value?)"}/>
                             <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={totalMonthlyPayment}
                                         dollarSign
                                      />
                                    }
                                 />

                             <Label text={"Approximately how much principal is paid down with each monthly payment?"}/>
                             <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={approximatePrincipalPaidDownMonthly}
                                         dollarSign
                                      />
                                    }
                                 />
                             <Label text={"Approximately how long is left on the loan (IN YEARS)?"} />
                             <Paragraph text={`${durationLeftOnLoanInYears} years`}/>

                             <Label text={"Are there any other mortgages, HELOCS, loan modifications, judgements, liens, etc. against the property"}/>
                             <Paragraph text={otherMortgagesAgainstPropertyExist ? "Yes" : "No"}/>

                              <Label text={'If, "YES," please elaborate on these additional encumbrances.'}/>
                              <Paragraph text={additionalEncumbrances}/>

                             
                             
                         </div>  
                     </div>
                 </div>
                 
             </div>
         </div>
  )
}

export default MotgageDetails