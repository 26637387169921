import React from 'react'
import clsx from 'classnames'
import CONSTANTS from 'modules/common/utils/constants'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'modules/common/hooks'
import { removeFromLS } from 'modules/common/utils/functions'
import { removeToken } from 'api'
const { routes } = CONSTANTS

const sideBarLinks = [
    { text: "Add Listing", route: routes.admin.addListing },
    { text: "Add Tours", route: routes.admin.addTours },
    { text: "View Property List", route: routes.admin.properties },
    { text: "View Rtow Form", route: routes.admin.rtoFormList },
    { text: "Fair Cash Applications", route: routes.admin.fairCashApplications },
    { text: "Spy Form Submissions", route: routes.admin.spyFormSubmissions },
    { text: "Delete Listing", route: routes.admin.deleteProperties },
    { text: "Invite user", route: routes.admin.inviteUser },
    { text: "Add blog", route: routes.admin.addBlog },
]



const AdminSideBar = () => {
    const { setAuthInfo } = useAuth()
    return (
        <div className='1010:flex hidden w-[270px] fixed flex-col left-0 top-[80px] px-5 pt-[50px] pb-6 h-[calc(100vh_-_103px)] bg-white z-[2] rounded-10'>
            <div className='w-full flex flex-col space-y-[29px]'>
                {sideBarLinks.map((link, index) => (
                    <NavLink
                        to={link.route}
                        key={index}
                        className={({ isActive }) => clsx('h-10 pl-[54px] flex items-center rounded-10', { "bg-_25253C": isActive })}
                        children={({ isActive }) => (
                            <>
                                <span className={clsx('text-_25253C text-base', { "text-white font-bold": isActive })}>{link.text}</span>
                                {isActive && <div className='flex items-center justify-center h-full ml-auto'>
                                    <div className='w-2 bg-white h-[22px] rounded-[10px]' />
                                </div>
                                }
                            </>

                        )}
                    />
                ))}
            </div>
            <button className='text-_25253C pl-[54px] mt-auto mb-8 text-left' onClick={() => {
                removeFromLS(CONSTANTS.STORAGE_KEYS.AUTH_DATA)
                removeToken()
                setAuthInfo(null)
            }}>
                Log out
            </button>
        </div >
    )
}

export default AdminSideBar

