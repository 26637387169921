import { Input } from "modules/common/components";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import StepButtons from "../StepButtons";
import { initialRTOFormState } from "modules/common/utils/constants";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";


const Important = ({ currentPage, onPrev, onNext }) => {
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			...initialRTOFormState.importantDetails,
		},
	});

    const { storeFormDetails } = useRtoForm({
		setter: reset,
		step: formSteps.importantDetails,
	});

	const onSubmit = async (data) => {
		if (await storeFormDetails(data)) {
			onNext();
		}
	};

	const Label = ({ text, star }) => {
		return (
			<div className="flex items-center gap-2">
				{star && <p className="text-[#FF0000] text-[30px]">*</p>}
				<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
					{text}
				</h3>
			</div>
		);
	};

	return (
		<div>
			<div className="bg-white  lg:flex lg:justify-between  1400:max-w-[1400px] 1400:mx-auto">
			<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
				<div className="flex flex-col   items-center justify-center">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-[29px] w-[90vw]   shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
					>
						{/* <h3 className="font-black md:max-w-[508px] text-[20px] text-_25253C leading-[24px] lg:text-[24px] lg:font-bold lg:leading-[40px] lg:text-left">
							Important Property Details
						</h3> */}
						<div className="flex flex-col space-y-3  mt-2">
							<Label text="Homestead Exemption (yes/no)" star />
							<Controller
								control={control}
								name="homesteadExemption"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										onChange={onChange}
										hasError={!!errors.homesteadExemption}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90] "
									/>
								)}
							/>
							<Label text="Current Yearly Tax Amount" star />
							<Controller
								control={control}
								name="currentYearlyTaxAmount"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.currentYearlyTaxAmount}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] w-[100%] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
								Loan 1
							</h3>
							<Label text="Payoff (rough estimate)" star />
							<Controller
								control={control}
								name="loan1Payoff"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan1Payoff}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Reinstatement (rough estimate)" star />
							<Controller
								control={control}
								name="loan1Reinstatement"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan1Reinstatement}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Current payment" star />
							<Controller
								control={control}
								name="loan1CurrentPayment"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan1CurrentPayment}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
								Loan 2 and on
							</h3>
							<Label text="Payoff (rough estimate)" star />
							<Controller
								control={control}
								name="loan2Payoff"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan2Payoff}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Reinstatement (rough estimate)" star />
							<Controller
								control={control}
								name="loan2Reinstatement"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan2Reinstatement}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Current payment" star />
							<Controller
								control={control}
								name="loan2CurrentPayment"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.loan2CurrentPayment}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Cash to seller" star />
							<Controller
								control={control}
								name="cashToSeller"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.cashToSeller}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Rent back amount" star />
							<Controller
								control={control}
								name="rentBackAmount"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.rentBackAmount}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Resale price" star />
							<Controller
								control={control}
								name="resalePrice"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.resalePrice}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Down payment for resale" star />
							 <Controller
								control={control}
								name="downPaymentForResale"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.downPaymentForResale}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
							<Label text="Dropbox Link" />
							 <Controller
								control={control}
								name="dropBoxLink"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder="Your answer"
										onChange={onChange}
										hasError={!!errors.dropBoxLink}
										value={value}
										ref_={ref}
										className="shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"
									/>
								)}
							/>
						</div>
					</form>
				</div>
			</div>
			<StepButtons {...{ currentPage, onPrev, onProceed: handleSubmit(onSubmit) }} /> 

		</div>
	);
};

export default Important;
