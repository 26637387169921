import React, { createContext, useEffect, useState } from "react";
import { retreiveFromLS } from "../utils/functions";
import CONSTANTS, { initialRTOFormState } from "../utils/constants";
import { BlockingLoader } from "../components";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	const [authInfo, setAuthInfo] = useState(null);
	const [userInfo, setUserInfo] = useState(null);
	const [searchResults, setSearchResults] = useState({});
	const [rtoFormDetails, setRtoFormDetails] = useState({
		...initialRTOFormState,
	});
	const [updatingRtoForm, setUpdatingRtoForm] = useState(false);

	useEffect(() => {
		const formData = retreiveFromLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA);
		if (formData) {
			setRtoFormDetails(formData);
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{
				searchResults,
				setSearchResults,
				authInfo,
				setAuthInfo,
				userInfo,
				setUserInfo,
				rtoFormDetails,
				setRtoFormDetails,
				setUpdatingRtoForm,
			}}
		>
			<BlockingLoader loading={updatingRtoForm} text={"Saving"} />
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
