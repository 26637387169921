import React, { useState, useEffect } from 'react';
import { NavigationWrapper } from 'modules/common/components';
import { FormListHead, RtoForm} from '../components';
import useRtoFormFilter from '../hooks/useRtoFormFilter';
import { ReactComponent as SearchIcon } from '../../../modules/common/assets/icons/search.svg';
import API from 'api';
import { useDebounce, useDebouncedLoader } from 'modules/common/hooks';
import { isEmpty } from 'lodash';

const RtoFormList = () => {

  const {
    applyingFilters,
    searchRtoForms,
    getAllRtoForms,
    loading,
    rtoForms,
    state,
    dispatch,
    metaData,
  } = useRtoFormFilter({
    allRtoFormsFetcher: API.getAllRtoForms,
    filteredRtoFormsFetcher: API.searchRtoForms,
  });

  // console.log(rtoForms)
  const [textQuery, setTextQuery] = useState('');
  const [hasMounted, setHasMounted] = useState(false);
  const debouncedTextQuery = useDebounce(textQuery, 1000);

  useEffect(() => {
    getAllRtoForms();
    setHasMounted(true);
  }, [getAllRtoForms]);

  const isLoading = useDebouncedLoader(loading);

  useEffect(() => {
    if (!hasMounted) {
      return;
    }
    const { lastAppliedFilters, ...rest } = state;
    if (!isEmpty(debouncedTextQuery.trim())) {
      searchRtoForms({ ...rest, query: debouncedTextQuery });
    } else {
      searchRtoForms({ ...rest });
    }
  }, [debouncedTextQuery]);

  // Function to handle applying filters
  const handleApplyFilters = () => {
    applyingFilters(); // This function should trigger applying filters in your custom hook
  };

  return (
      <div className=" pb-6">
        <FormListHead total={rtoForms.length}/>
        <div className="rounded-[30px] mt-4 bg-white mx-auto  max-w-[1000px] px-3 md:px-5 py-5  shadow ">
          <div>
            <div>
              <div className="flex flex-col items-center md:flex-row gap-3 space-y-4 p-4  justify-between  border-E8EBEC bg-white border rounded-10  md:pr-[10px] md:pl-[35px]">
                <button className="flex items-center space-x-[9px]" onClick={handleApplyFilters}>
                  <span className="text-base text-_25253C leading-[18px] font-bold">RTO w/ Lease-Purchase</span>
                </button>

                <div className="h-[37.12px] max-w-[270px] w-full border-[0.6px] rounded-[29.93px] border-_25253C bg-white px-[13.17px] flex items-center space-x-[5.99px]">
                  <SearchIcon className="h-[22.75px] w-[22.75px] flex-shrink-0" />
                  <input
                    className="flex-1 text-[16px] text-_25253C border-none outline-none"
                    placeholder="Search Form"
                    value={textQuery}
                    onChange={({ target: { value } }) => setTextQuery(value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
          <div className="grid grid-cols-2 rounded-sm bg-gray-2  sm:grid-cols-6 border-b border-stroke">
          <div className="p-2.5 xl:p-5">
            <h5 className=" font-medium text-[#B5B7C0] uppercase ">
              Address
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="font-medium text-[#B5B7C0] uppercase ">
              submitted by
            </h5>
          </div>
          <div className="hidden sm:block p-2.5 text-center xl:p-5">
            <h5 className="font-medium uppercase text-[#B5B7C0] ">
              Phone Number
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="font-medium uppercase text-[#B5B7C0] ">
              Email
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="font-medium uppercase text-[#B5B7C0] ">
              Date Submitted
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="font-medium uppercase text-[#B5B7C0]  ">
              Status
            </h5>
          </div>
         </div>
          {rtoForms.map((form) => {
            const date = new Date(form.createdAt);
             const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            return (
             <RtoForm 
             address={form.contactDetails.fullAddressOfProperty}
             submittedBy={form.contactDetails.name}
             phone={form.contactDetails.phoneNumber}
             email={form.contactDetails.email}
             date={formattedDate}
             status={form.status}
             key={form._id}
             id={form._id}
           />
           );
          })}
          </div>
        </div>
      </div>
  );
};

export default RtoFormList;
