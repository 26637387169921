import React from "react";
import "./MultiStepBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = ({ page, onPageNumberClick }) => {
    let stepPercentage = 0;
    if (page ===1) {
        stepPercentage = 20;
    } else if (page === 2) {
        stepPercentage = 40;
    } else if (page === 3) {
        stepPercentage = 60;
    } else if (page === 4) {
        stepPercentage = 80;
    } else if (page === 5) {
        stepPercentage = 100; 
    } else if (page >= 6) {
        stepPercentage = 100; 
    }

    return (
        <ProgressBar percent={stepPercentage}>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(0)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(1)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(2)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(3)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(4)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
            <Step>
                {({ accomplished, index }) => (
                    <div
                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                        onClick={() => onPageNumberClick(5)}
                    >
                        {index + 1}
                    </div>
                )}
            </Step>
        </ProgressBar>
    );
};

export default MultiStepProgressBar;
