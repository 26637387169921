import { useState } from "react";

const useData = () => {
	const [value, setValue] = useState({
		data: [],
		metadata: {},
	});

	/** newValues should be of the format:
	 * {data, metadata}
	 * where data is the data being stored and metadata is pagination info
	 *
	 * replaceData is a boolean. If true, it will replace the data completely
	 * If false, it will append the new data to the existing data
	 */
	const setValues = (newValues, replaceData = false) => {
		if (replaceData) {
			setValue({
				data: newValues.data,
				metadata: newValues.metadata,
			});
		} else {
			setValue({
				data: [...value.data, ...newValues.data],
				metadata: newValues.metadata,
			});
		}
	};

	return {
		data: value.data,
		metadata: value.metadata,
		setValues,
	};
};

export default useData;
