// import {  CommaSeperatedNumber, NavigationWrapper } from 'modules/common/components'
// import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import { ReactComponent as Bathroom } from 'modules/properties/assets/icons/bathroom.svg'
// import { ReactComponent as Bedroom } from 'modules/properties/assets/icons/bedroom.svg'
// import { ReactComponent as Area } from 'modules/properties/assets/icons/area.svg'
// import { ReactComponent as Location } from 'modules/properties/assets/icons/location.svg'
// import { ReactComponent as Star } from 'modules/properties/assets/icons/star.svg'
// import { useNavigate, useParams } from 'react-router-dom'
// import API from 'api'

// import { formatText, parseAddress, pluralize, } from 'modules/common/utils/functions'
// import CONSTANTS from 'modules/common/utils/constants'
// import { ExtensiveTourDetail, PropertyImagesCarousel } from '../components'

// import { getEditedImageUrl } from '../utils/functions'
// import PropertyLocation from '../components/PropertyLocation'
// const Ratings = ({ rating = 0 }) => {
//     return Array(5).fill(0).map((item, index) => {
//         const isFilled = (rating || 0) > index
//         return (
//             <Star key={index} className={isFilled ? 'filled-star' : ''} />
//         )
//     })
// }

// export const SimilarProperty = ({ images, address, bathroomCount, bedroomCount, area, price, propertyType, id }) => {
//     const navigate = useNavigate()
//     const goToDetail = () => {
//         navigate(CONSTANTS.routes.tourDetail(id), { replace: true })
//         window.scroll(0, 0)
//     }

//     const displayableImage = useMemo(() => {
//         if (images?.length > 0) {
//             return images[0]?.url
//         }
//     }, [images])

//     return (

//         <div role='presentation' onClick={goToDetail} className='cursor-pointer bg-white border-[0.55px] border-E8EBEC rounded-[5.48px] p-[11px] min-h-[271px]'>
//             <div className='h-[112px] rounded-10 overflow-hidden relative'>
//                 <img src={getEditedImageUrl(displayableImage)} className='absolute top-0 left-0 object-cover w-full h-full' alt='Property' />
//             </div>
//             <div className='mt-[13px]'>
//                 <CommaSeperatedNumber
//                     number={price}
//                     dollarSign
//                     className={"font-bold text-sm leading-base text-_25253C mb-[3px]"}
//                 />
//                 <p className='text-_25253C text-[7px] leading-[10.5px] font-bold mb-[10px]'>{parseAddress(address)}</p>
//                 <div className='flex flex-col space-y-[11.49px]'>
//                     <div className='flex items-center space-x-[14.8px]'>
//                         <div className='flex items-center space-x-[5.65px] flex-1'>
//                             <Bathroom className='h-[11.34px] w-[11.34px]' />
//                             <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{bathroomCount || "-"} {pluralize(bathroomCount, "Bathroom")}</span>
//                         </div>
//                         <div className='flex items-center space-x-[5.65px] flex-1'>
//                             <Bedroom className='h-[11.34px] w-[11.34px]' />
//                             <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{bedroomCount || "-"} {pluralize(bedroomCount, "Bedroom")}</span>
//                         </div>
//                     </div>
//                     <div className='flex items-center space-x-[14.8px]'>
//                         <div className='flex items-center space-x-[5.65px] flex-1'>
//                             <Area className='h-[11.34px] w-[11.34px]' />
//                             {area ? <CommaSeperatedNumber
//                                 number={area}
//                                 className='text-sm leading-4 text-_25253C'
//                                 suffix='ft²'
//                             /> : "-"}
//                         </div>
//                         <div className='flex items-center space-x-[5.65px] flex-1'>
//                             <Location className='h-[12.06px] w-[12.06px]' />
//                             <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{address?.state}</span>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='mt-[11.66px]'>
//                     <p className='text-_25253C text-[7.67px] leading-[8.77px]'><span className='font-bold'>Property Type:</span> {propertyType}</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// const TourDetail = () => {
//     // const { width } = useWindowSize()
//     const [detail, setDetail] = useState(null)
//     const { tourId } = useParams()
//     const navigate = useNavigate()

//     const hasThreeDFrames = detail?.property?.threeDFrame && detail.property.threeDFrame.length > 0;
//     const homeValues = {
//       price: detail?.property?.price,
//       pricePerSquareFoot: Math.floor(detail?.property?.price/600),
//       homeType: detail?.property?.homeType,
//       cooling: "central",
//       yearBuilt: "1915",
//       heating: "Forced Air, Gas",
//       lot: detail?.property?.area,
//       bathroomCount: detail?.property?.bathroomCount,
//       bedroomCount: detail?.property?.bedroomCount,
//     }
//    const homeDetails = {
//     homeType: detail?.property?.homeType,
//     cooling: "central",
//     yearBuilt: "1915",
//     heating: "Forced Air, Gas",
//     lot: detail?.property?.area,
//     bathroomCount: detail?.property?.bathroomCount,
//     bedroomCount: detail?.property?.bedroomCount,
//     location: "Indianapolis"

//    }
   
//     const goToList = useCallback(() => {
//         navigate(CONSTANTS.routes.tourList)
//     }, [navigate])

//     const getDetail = React.useCallback(async (tourId) => {
//         try {
//             const data = await API.getSingleTour(tourId)
//             // console.log(data)
//             setDetail(data)
//         }
//         catch (e) {
//             goToList()
//         }
//     }, [goToList])

//     useEffect(() => {
//         getDetail(tourId)
//     }, [tourId, getDetail])

//     return (
//         <NavigationWrapper
//             handleSEO={false}
//         >
//             {detail &&
//                 <div
                 
//                 >
//                     {/* <OtherPropertySearch
//                         address={otherPropertyAddress}
//                         onClose={() => setOtherPropertyAddress(null)}
//                         onOtherPropertyClick={address => {
//                             if (otherPropertyAddress) {
//                                 setOtherPropertyAddress('')
//                             }
//                             setOtherPropertyAddress(address)
//                         }}
//                     /> */}

//                     <div className='bg-_25253C h-[83.07px] lg:hidden' />
//                     <div className=' bg-white  pt-[59px] lg:pt-[150px] pb-5 px-5 relative 1400:mx-auto 1400:max-w-[1320px]'>
                       
//                          <div className='mt-[17px] lg:mt-[10px] mb-10 flex items-center justify-between space-x-2  border-b border-_25253C'>
//                                 <h3 className='text-[18px] font-semibold text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{detail?.property?.tag}</h3>
//                                 {/* <CommaSeperatedNumber
//                                  number={detail?.property?.price}
//                                  dollarSign
//                                  className={"text-_25253C font-bold text-[27.23px] leading-[29.95px] text-center 1436:text-[30px] 1436:leading-[61.6px]"}
//                                 /> */}
//                         </div>
//                        {hasThreeDFrames && 
//                                  <iframe 
//                                   title={detail?.property?.id} 
//                                   width="100%"  
//                                   height="100%" 
//                                   className='rounded-lg shadow-md overflow-hidden h-[80vh] md:h-[65vh] ' 
//                                   id="matterportFrame"  
//                                   src={detail?.property?.threeDFrame} 
//                                   frameborder="0" 
//                                   allowfullscreen>

//                                   </iframe>
//                         }
//                         <div className='1400:mx-auto 1400:max-w-[1320px]'>
//                             <div className='mt-[51px] lg:mt-[50px] flex justify-between'>
//                                 <h3 className='text-_25253C font-bold text-[32px]'>{detail?.property?.type}</h3>
//                                 <div className='flex items-center space-x-[5.87px]'>
//                                     <span className='font-bold text-[19.01px] text-_25253C'>5.0</span>
//                                     <Ratings rating={detail?.property?.rating} />
//                                 </div>
//                             </div>
//                             <div className='mt-[17px] lg:mt-[10px] flex items-center space-x-[1.68px]'>
//                                 <Location className='1436:h-[46px] 1436:w-[46px]' />
//                                 <p className='text-[13.41px] text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{detail?.property?.tag}</p>
//                             </div>
                           

//                             <div className='flex flex-col  1436:flex-row 1436:space-x-[59px]'>
//                                 <div className='mt-[46.3px] flex flex-col space-y-6 mb-[37px] 1436:mt-[95px] 1436:mb-0 1436:flex-1'>
//                                     <h2 className='text-[24px] text-_25253C font-bold leading-[34px] 1436:text-[48px] 1436:leading-[52.8px]'>Property Description</h2>
//                                     <p className='text-_25253C text-base leading-6 1436:text-[24px] 1436:leading-[34px]'>
//                                         {formatText(detail?.property?.description)}
//                                     </p>

//                                 </div>
                               
//                                 <ExtensiveTourDetail
//                                         homeDetails={homeDetails}
//                                         homeValue={homeValues}
//                                         basicInfo={detail?.property?.basicInfo}
//                                         neighborhoodDetails={detail?.property?.neighbourHoodDetails}
                                        
//                                 />
                                
//                             </div>
//                         </div>
//                     </div>
//                      <div className='md:py-10 py-10 px-5 md:my-10 mt-10 bg-[#f1f1f1]'>
//                          <h3 className='text-[20px] 1400:max-w-[1320px] text-end border-r-2 border-_25253C  font-semibold text-_25253C leading-[19px] 1436:text-[30px] 1436:leading-[34px] p-3 md:m-4'>Photo Gallery</h3>
//                              <PropertyImagesCarousel
//                                showBackButton="false"
//                                 address={parseAddress(detail?.property?.address || {})}
//                                 imageUrls={(detail?.property?.pictures || []).map(image => image.url)}
//                                 smallImg={(detail?.property?.pictures || []).length > 1 ? detail?.property?.basicInfo?.smallImage : null}
//                              />
//                       </div>
//                        {/* <div className='md:py-10 md:my-10 mt-10 px-5 relative h-[500px] md:h-[400px]'>
//                          <h3 className='text-[20px] 1400:max-w-[1320px]  border-l-2 border-_25253C  font-semibold text-_25253C leading-[19px] 1436:text-[30px] 1436:leading-[34px] p-3 md:m-4'>Map</h3>
//                           <div 
//                            className='cursor-pointer absolute top-20 md:top-40  overflow-hidden z-[1] transition-all duration-300  left-[0px]  bottom-[27px] h-[400px] w-[100vw] md:h-[300px] md:w-[100vw] rounded-10 border border-gray-500 object-cover '
//                           >
//                            <PropertyLocation
//                             address={parseAddress(detail?.property?.address || {})}
//                           />
//                           </div>
//                      </div> */}
                  
//                 </div>
//             }
//         </NavigationWrapper>
//     )
// }

// export default TourDetail

import { CommaSeperatedNumber, NavigationWrapper } from 'modules/common/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Bathroom } from 'modules/properties/assets/icons/bathroom.svg';
import { ReactComponent as Bedroom } from 'modules/properties/assets/icons/bedroom.svg';
import { ReactComponent as Area } from 'modules/properties/assets/icons/area.svg';
import { ReactComponent as Location } from 'modules/properties/assets/icons/location.svg';
import { ReactComponent as Star } from 'modules/properties/assets/icons/star.svg';
import { useNavigate, useParams } from 'react-router-dom';
import API from 'api';

import { formatText, parseAddress, pluralize } from 'modules/common/utils/functions';
import CONSTANTS from 'modules/common/utils/constants';
import { ExtensiveTourDetail, PropertyImagesCarousel } from '../components';

import { getEditedImageUrl } from '../utils/functions';
import PropertyLocation from '../components/PropertyLocation';

const Ratings = ({ rating = 0 }) => {
    return Array(5).fill(0).map((item, index) => {
        const isFilled = (rating || 0) > index;
        return (
            <Star key={index} className={isFilled ? 'filled-star' : ''} />
        );
    });
};

export const SimilarProperty = ({ images, address, bathroomCount, bedroomCount, area, price, propertyType, id }) => {
    const navigate = useNavigate();
    const goToDetail = () => {
        navigate(CONSTANTS.routes.tourDetail(id), { replace: true });
        window.scroll(0, 0);
    };

    const displayableImage = useMemo(() => {
        if (images?.length > 0) {
            return images[0]?.url;
        }
    }, [images]);

    return (
        <div role='presentation' onClick={goToDetail} className='cursor-pointer bg-white border-[0.55px] border-E8EBEC rounded-[5.48px] p-[11px] min-h-[271px]'>
            <div className='h-[112px] rounded-10 overflow-hidden relative'>
                <img src={getEditedImageUrl(displayableImage)} className='absolute top-0 left-0 object-cover w-full h-full' alt='Property' />
            </div>
            <div className='mt-[13px]'>
                <CommaSeperatedNumber
                    number={price}
                    dollarSign
                    className={"font-bold text-sm leading-base text-_25253C mb-[3px]"}
                />
                <p className='text-_25253C text-[7px] leading-[10.5px] font-bold mb-[10px]'>{parseAddress(address)}</p>
                <div className='flex flex-col space-y-[11.49px]'>
                    <div className='flex items-center space-x-[14.8px]'>
                        <div className='flex items-center space-x-[5.65px] flex-1'>
                            <Bathroom className='h-[11.34px] w-[11.34px]' />
                            <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{bathroomCount || "-"} {pluralize(bathroomCount, "Bathroom")}</span>
                        </div>
                        <div className='flex items-center space-x-[5.65px] flex-1'>
                            <Bedroom className='h-[11.34px] w-[11.34px]' />
                            <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{bedroomCount || "-"} {pluralize(bedroomCount, "Bedroom")}</span>
                        </div>
                    </div>
                    <div className='flex items-center space-x-[14.8px]'>
                        <div className='flex items-center space-x-[5.65px] flex-1'>
                            <Area className='h-[11.34px] w-[11.34px]' />
                            {area ? <CommaSeperatedNumber
                                number={area}
                                className='text-sm leading-4 text-_25253C'
                                suffix='ft²'
                            /> : "-"}
                        </div>
                        <div className='flex items-center space-x-[5.65px] flex-1'>
                            <Location className='h-[12.06px] w-[12.06px]' />
                            <span className='text-[7.67px] leading-[8.77px] text-_25253C'>{address?.state}</span>
                        </div>
                    </div>
                </div>
                <div className='mt-[11.66px]'>
                    <p className='text-_25253C text-[7.67px] leading-[8.77px]'><span className='font-bold'>Property Type:</span> {propertyType}</p>
                </div>
            </div>
        </div>
    );
};

const TourDetail = () => {
    // const { width } = useWindowSize();
    const [detail, setDetail] = useState(null);
    const { tourId } = useParams();
    const navigate = useNavigate();

    const hasThreeDFrames = detail?.property?.threeDFrame && detail.property.threeDFrame.length > 0;
    const homeValues = {
      price: detail?.property?.price,
      pricePerSquareFoot: Math.floor(detail?.property?.price / 600),
      homeType: detail?.property?.homeType,
      cooling: "central",
      yearBuilt: "1915",
      heating: "Forced Air, Gas",
      lot: detail?.property?.area,
      bathroomCount: detail?.property?.bathroomCount,
      bedroomCount: detail?.property?.bedroomCount,
    };
    const homeDetails = {
      homeType: detail?.property?.homeType,
      cooling: "central",
      yearBuilt: "1915",
      heating: "Forced Air, Gas",
      lot: detail?.property?.area,
      bathroomCount: detail?.property?.bathroomCount,
      bedroomCount: detail?.property?.bedroomCount,
      location: "Indianapolis",
    };

    const goToList = useCallback(() => {
        navigate(CONSTANTS.routes.tourList);
    }, [navigate]);

    const getDetail = React.useCallback(async (tourId) => {
        try {
            const data = await API.getSingleTour(tourId);
            // console.log(data);
            setDetail(data);
        } catch (e) {
            goToList();
        }
    }, [goToList]);

    useEffect(() => {
        getDetail(tourId);
    }, [tourId, getDetail]);

    return (
        <NavigationWrapper handleSEO={false}>
            {detail && (
                <div>
                    <div className='bg-_25253C h-[83.07px] lg:hidden' />
                    <div className='bg-white pt-[59px] lg:pt-[150px] pb-5 px-5 relative 1400:mx-auto 1400:max-w-[1320px]'>
                        <div className='mt-[17px] lg:mt-[10px] mb-10 flex items-center justify-between space-x-2 border-b border-_25253C'>
                            <h3 className='text-[18px] font-semibold text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{detail?.property?.tag}</h3>
                        </div>
                        {hasThreeDFrames && (
                            <iframe
                                title={detail?.property?.id}
                                width="100%"
                                height="100%"
                                className='rounded-lg shadow-md overflow-hidden h-[80vh] md:h-[65vh]'
                                id="matterportFrame"
                                src={detail?.property?.threeDFrame}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        )}
                        <div className='1400:mx-auto 1400:max-w-[1320px]'>
                            <div className='mt-[51px] lg:mt-[50px] flex justify-between'>
                                <h3 className='text-_25253C font-bold text-[32px]'>{detail?.property?.type}</h3>
                                <div className='flex items-center space-x-[5.87px]'>
                                    <span className='font-bold text-[19.01px] text-_25253C'>5.0</span>
                                    <Ratings rating={detail?.property?.rating} />
                                </div>
                            </div>
                            <div className='mt-[17px] lg:mt-[10px] flex items-center space-x-[1.68px]'>
                                <Location className='1436:h-[46px] 1436:w-[46px]' />
                                <p className='text-[13.41px] text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{detail?.property?.tag}</p>
                            </div>

                            <div className='flex flex-col 1436:flex-row 1436:space-x-[59px]'>
                                <div className='mt-[46.3px] flex flex-col space-y-6 mb-[37px] 1436:mt-[95px] 1436:mb-0 1436:flex-1'>
                                    <h2 className='text-[24px] text-_25253C font-bold leading-[34px] 1436:text-[48px] 1436:leading-[52.8px]'>Property Description</h2>
                                    <p className='text-_25253C text-base leading-6 1436:text-[24px] 1436:leading-[34px]'>
                                        {formatText(detail?.property?.description)}
                                    </p>
                                </div>
                                <ExtensiveTourDetail
                                    homeDetails={homeDetails}
                                    homeValue={homeValues}
                                    basicInfo={detail?.property?.basicInfo}
                                    neighborhoodDetails={detail?.property?.neighbourHoodDetails}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='md:py-10 py-10 px-5 md:my-10 mt-10 bg-[#f1f1f1]'>
                        <h3 className='text-[20px] 1400:max-w-[1320px] text-end border-r-2 border-_25253C font-semibold text-_25253C leading-[19px] 1436:text-[30px] 1436:leading-[34px] p-3 md:m-4'>Photo Gallery</h3>
                        <PropertyImagesCarousel
                            showBackButton="false"
                            address={parseAddress(detail?.property?.address || {})}
                            imageUrls={(detail?.property?.pictures || []).map(image => image.url)}
                            // phoneNumber={"765.507.6789"}
                            smallImg={(detail?.property?.pictures || []).length > 1 ? detail?.property?.basicInfo?.smallImage : null}
                        />
                        <div className='flex-1 md:py-10 py-10 px-5 md:my-10 mt-10 md:mt-0 '>
                        <h3 className='text-[20px] 1400:max-w-[1320px] border-l-2 border-_25253C font-semibold text-_25253C leading-[19px] 1436:text-[30px] 1436:leading-[34px] p-3 md:m-4'>Contact</h3>
                        <p className='text-center md:text-left text-_25253C text-[18px] leading-[22px]'>
                         Office phone: <a href="tel:7655076789" className='text-_25253C underline'>765.507.6789</a>
                        </p>

                        {/* <p className='text-center md:text-left text-_25253C text-[18px] leading-[22px]'>Address: 2955 North Highway 97, Bend, OR, USA</p> */}
                        </div>
                    </div>
                    
                </div>
            )}
        </NavigationWrapper>
    );
};

export default TourDetail;
