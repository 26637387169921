import { CommaSeperatedNumber } from 'modules/common/components'
import React, { useState } from 'react'

    const Label = ({ text, subtext }) => {
        return (
            <>
                {text && (
                    <h3 className='font-bold text-[18px]   text-_25253C leading-6 lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold  lg:text-left'>
                        {text}
                    </h3>
                )}
                {subtext && (
                    <p className='font-normal text-[#6F6C90] !my-0'>
                        {subtext}
                    </p>
                )}
            </>
        );
    };

    const Paragraph = ({ text }) => {
        return (
            <>
                {text && (
                  <p className='text-_25253C  border-b border-b-[#EEEEEE] leading-6 1436:text-[18px] 1436:leading-[34px]'>
                      {text}
                  </p>
                )}
            </>
        );
    };
    
    
const PropertyDetails = ({detail}) => {

     const {notesAboutSeller, 
           composition, 
            acreage, 
            totalFinishedSquareFootageOfHouse, 
            propertyAmenities,
            homesteadExemptionCurrentlyInPlace,
            yearlyPropertyTaxes,
            linkToProperty,
            monthlyRentOfferedForLeasePurchase,
            zillowEstimate,
            estimatedWorthAsIs,
            otherNotesAboutProperty
       } = detail.propertyDetails
    return (
            <div>  
                <div className='  lg:flex lg:justify-between 1400:mx-auto 1400:max-w-[1320px]'>
                    {/* <SellYourHouseDiamonds className='absolute h-full w-full left-0 top-0 lg:block hidden' /> */}
                    <div className='flex flex-col items-center justify-center'>
                        <div className='mt-[29px]  shadow mx-auto z-[1]  rounded-[11.89px] lg:rounded-[20.22px]   px-[17px] lg:px-[30px]  lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] md:mb-[30px]'>
                         <h3 className='font-black text-[20px] text-_25253C leading-[41.3px]  lg:text-[40px] md:mb-5 lg:font-bold lg:leading-[61.6px] lg:text-left'>
                           Property Details
                          </h3>
                            <div className='flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-4 md:mt-10'>
                            <Label text={"Notes about the seller and their situation"}/> 
                            <Paragraph text={notesAboutSeller}/>
                            
                             <Label text={"Composition (e.g. beds, baths, loft, etc.)? "}/> 
                             <Paragraph text={composition}/>  
                             
                             
                               <Label text={"Acreage"}/>
                                <Paragraph text={acreage}/> 

                                 <Label text={"Total Finished Square Footage of House?"}/>
                                  <Paragraph text={totalFinishedSquareFootageOfHouse}/>

                               <Label text={"Property Amenities"} subtext={"(e.g. 40x60 pole barn, whole house generator, fenced yard, pond, finished basement, etc)"}/>
                                <Paragraph text={propertyAmenities}/>

                                <Label text={"Is the Homestead Exemption currently in place?"} subtext={"(Are property taxes currently approx. 1% of the assessed value?)"}/>
                                <Paragraph text={homesteadExemptionCurrentlyInPlace}/>

                                <Label text={"How much are the current YEARLY property taxes?"} subtext={"(e.g. 40x60 pole barn, whole house generator, fenced yard, pond, finished basement, etc)"}/>
                                <Paragraph 
                                 text={
                                     <CommaSeperatedNumber
                                        number={yearlyPropertyTaxes}
                                         dollarSign
                                      />
                                    }
                                 />

                                <Label text={"Link to Property on Zillow (or other if not available on Zillow)?"} subtext={"https://www.zillow.com"}/>
                                 <a href={linkToProperty} target='_blank' className='text-[#0000FF] underline' rel="noreferrer">{linkToProperty}</a>
                                
                                <Label text={"Zillow Zestimate(value)"}/>
                                 <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={zillowEstimate}
                                         dollarSign
                                      />
                                    }
                                 />
                                 <Label text={"Monthly rent amount being offered by seller for the lease purchase?"}/>
                                 <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={monthlyRentOfferedForLeasePurchase}
                                         dollarSign
                                      />
                                    }
                                 />

                                 <Label text="How much do you think it is worth AS-IS (no repairs)?"/>
                                 <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={estimatedWorthAsIs}
                                         dollarSign
                                      />
                                    }
                                 />
                                 

                                <Label text={"Other Notes about the Property"}/>
                                 <Paragraph text={ otherNotesAboutProperty}/>
                                
                            </div>  
                        </div>
                    </div>
                    
                </div>
            </div>
    )
}

export default PropertyDetails;