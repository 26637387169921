import React from "react";
import clsx from "classnames";
import { NumericFormat } from "react-number-format";

const Input = ({ value, onChange, hasError, className, ref_, isNumeric = false, ...rest }) => {
	if (isNumeric) {
		return (
			<NumericFormat
				value={value}
				onValueChange={(values) => onChange(values.floatValue)}
				thousandSeparator=","
				decimalScale={2}
				allowNegative={false}
				isAllowed={(values) => {
					if (!values.value) return true;
					const { floatValue } = values;
					return floatValue > -1;
				}}
				className={clsx(
					"h-[40px] lg:h-[59.98px] text-[14px] lg:text-base leading-[14.12px] lg:leading-6 w-full  outline-none rounded-[7.93px] lg:rounded-[13.48px] px-[12.69px] lg:px-[21.57px] bg-white placeholder:text-C9C7C7",
					className,
					{ "!border !border-[red] !border-solid": hasError }
				)}
				{...rest}
			/>
		);
	}
	return (
		<input
			ref={ref_}
			value={value}
			onChange={onChange}
			className={clsx(
				"h-[40px] lg:h-[59.98px] text-[14px] lg:text-base leading-[14.12px] lg:leading-6 w-full  outline-none rounded-[7.93px] lg:rounded-[13.48px] px-[12.69px] lg:px-[21.57px] bg-white placeholder:text-C9C7C7",
				className,
				{ "!border !border-[red] !border-solid": hasError }
			)}
			{...rest}
		/>
	);
};

export default Input;
