import React from 'react'
import clsx from 'classnames'

const MessageDetails = ({ isVisible, message, onClose }) => {
    return (
        <div onClick={() => onClose()} className={clsx('z-[100000] pointer-events-none opacity-0 h-screen w-screen fixed flex pt-5 justify-center top-0 left-0 transition-all', { 'pointer-events-auto opacity-100 bg-black/[.6]': isVisible })}>
            <div onClick={e => e.stopPropagation()} className='w-[calc(100vw_-_50px)] lg:w-[600px] bg-white rounded-lg p-3 h-[calc(100vh_-_200px)]'>
                {message &&
                    <div>
                        <div className='h-[100px]'>
                            <h3 className='text-_25253C font-bold text-center text-[25px]'>{message.firstName} {message.lastName}</h3>
                            <p className='text-_25253C font-medium text-center text-[18px]'>{message.email}</p>
                            <p className='text-_25253C font-medium text-center text-[14px]'>{message.phoneNumber}</p>
                        </div>
                        <div className='h-[100vh_-_300px] pt-3'>
                            <p className='text-_25253C text-[18px]'>
                                {message.message}
                            </p>
                            <div className='mt-4 flex flex-col'>
                                <p className='text-center text-_25253C font-semibold'>Property Address</p>
                                <p className='text-_25253C text-[18px] text-center'>
                                    {message.propertyAddress}
                                </p>
                            </div>
                            {
                                message?.pictures?.length && message.pictures.map(picture => (
                                    <div className='h-[400px] mt-4' key={picture._id}>
                                        <img alt='property' className='h-[400px] w-full object-cover' src={picture.url}></img>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default MessageDetails