import CONSTANTS from 'modules/common/utils/constants'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const BlogCard = ({ title, createdAt, id, coverPhotoUrl, showDeleteBtn, onDeleteTrial }) => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate(CONSTANTS.routes.blogDetail(id))
    }

    return (
        <div
            role='presentation'
            onClick={onClick}
            className='cursor-pointer bg-F6F7FB min-h-[418px] border-[0.82px] border-E8EBEC rounded-[8.21px] overflow-hidden relative'>
            <div className='h-[256.22px] rounded-[8.21px] relative overflow-hidden' >
                <img src={coverPhotoUrl || 'https://images.pexels.com/photos/1559825/pexels-photo-1559825.jpeg'}
                    alt='blog'
                    className='object-cover absolute h-full w-full left-0 top-0'
                />
            </div>
            <div className=' px-5 py-[15px] h-full flex flex-col space-y-5'>
                <h3 className='text-_25253C font-bold text-[19.71px] leading-[27.92px]'>{title}</h3>
                <span className='text-[#848484] font-bold text-[11.5px]'>{moment(createdAt).format("MMM DD, YYYY")}</span>
            </div>
            {showDeleteBtn && <button onClick={(e) => {
                e.stopPropagation()
                onDeleteTrial()
            }} className='h-6 w-6 flex items-center rounded-full absolute bottom-[10px] right-[10px] bg-[red]'>
                <svg viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M667.8 362.1H304V830c0 28.2 23 51 51.3 51h312.4c28.4 0 51.4-22.8 51.4-51V362.2h-51.3z" fill="#ffffff"></path><path d="M750.3 295.2c0-8.9-7.6-16.1-17-16.1H289.9c-9.4 0-17 7.2-17 16.1v50.9c0 8.9 7.6 16.1 17 16.1h443.4c9.4 0 17-7.2 17-16.1v-50.9z" fill="#ffffff"></path><path d="M733.3 258.3H626.6V196c0-11.5-9.3-20.8-20.8-20.8H419.1c-11.5 0-20.8 9.3-20.8 20.8v62.3H289.9c-20.8 0-37.7 16.5-37.7 36.8V346c0 18.1 13.5 33.1 31.1 36.2V830c0 39.6 32.3 71.8 72.1 71.8h312.4c39.8 0 72.1-32.2 72.1-71.8V382.2c17.7-3.1 31.1-18.1 31.1-36.2v-50.9c0.1-20.2-16.9-36.8-37.7-36.8z m-293.5-41.5h145.3v41.5H439.8v-41.5z m-146.2 83.1H729.5v41.5H293.6v-41.5z m404.8 530.2c0 16.7-13.7 30.3-30.6 30.3H355.4c-16.9 0-30.6-13.6-30.6-30.3V382.9h373.6v447.2z" fill="#211F1E"></path><path d="M511.6 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.4 9.3 20.7 20.8 20.7zM407.8 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0.1 11.4 9.4 20.7 20.8 20.7zM615.4 799.6c11.5 0 20.8-9.3 20.8-20.8V467.4c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.5 9.3 20.8 20.8 20.8z" fill="#211F1E"></path></g></svg>
            </button>}
        </div>
    )
}

export default BlogCard