import { BlockingLoader, Input } from "modules/common/components";
import { ReactComponent as Picture } from "modules/common/assets/images/picture.svg";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { pluralize, validators } from "modules/common/utils/functions";
import { useDropzone } from "react-dropzone";
import { convertBlobToFile, createBlobFromFile } from "modules/rtoForm/util";
import toast from "react-hot-toast";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";
import StepButtons from "../StepButtons";
import API from "api";
import CONSTANTS, { initialRTOFormState } from "modules/common/utils/constants";
import { useNavigate } from "react-router-dom";
import UploadedFormMedia from "../UploadedFormMedia";

const StepFive = ({ currentPage, onPrev, onNext, isUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [propertyDocuments, setPropertyDocuments] = useState([]);
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            ...initialRTOFormState.leasePurchaseDetails,
        },
    });

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/csv": [".csv"],
            "image/*": [".jpeg", ".png"]
        },
        maxFiles: 20,
        maxSize: 3145728,
        onDrop: (acceptedFiles) => {
            setPropertyDocuments(acceptedFiles);
        },
    });

    const navigate = useNavigate();

    const customResetter = useCallback(async (data) => {
        const prevData = data || {};
        const { documents = [], ...rest } = prevData;
        reset(rest);
        const errors = [];
        const availableDocuments = [];

        await Promise.all(
            documents.map(async (picture) => {
                const { file: newFile, error } = await convertBlobToFile(picture, "picture");
                if (error || !newFile) {
                    errors.push("Error");
                } else {
                    availableDocuments.push(newFile);
                }
            })
        );
        setPropertyDocuments(availableDocuments);
        if (errors.length) {
            toast.error("Please reupload the property documents");
        }
    }, []);

    const { storeFormDetails, rtoFormDetails, clearFormState, documents, submissionId } = useRtoForm({
        setter: customResetter,
        step: formSteps.leasePurchaseDetails,
    });

    const onSubmit = async (data, isSubmit = false) => {
        const formData = new FormData();
        const documents = [];
        propertyDocuments.forEach((file) => {
            const { blob, error } = createBlobFromFile(file);
            if (!error) {
                documents.push(blob);
            }
        });

        const { contactDetails, propertyDetails, mortgageDetails, purchaseRelatedDetails, leasePurchaseDetails, importantDetails } = rtoFormDetails;
        const availablePictures = [];
        const pictureErrors = [];

        if (propertyDetails && Array.isArray(propertyDetails.pictures)) {
            await Promise.all(
                propertyDetails.pictures.map(async (picture) => {
                    const { file: newFile, error } = await convertBlobToFile(picture, "picture");
                    if (error || !newFile) {
                        pictureErrors.push("Error");
                    } else {
                        availablePictures.push(newFile);
                    }
                })
            );

            availablePictures.forEach((picture) => {
                formData.append("pictures", picture);
            });

            if (!availablePictures.length) {
                console.warn("No property pictures available. Proceeding without pictures.");
            }
        } else {
            console.warn("Property pictures are missing or invalid. Proceeding without pictures.");
        }

        if (contactDetails) {
            Object.keys(contactDetails).forEach((key) => {
                formData.append(`contactDetails[${key}]`, contactDetails[key]);
            });
        } else {
            console.warn("Contact details are missing. Proceeding without contact details.");
        }

        if (propertyDetails) {
            const { pictures, ...rest } = propertyDetails;
            Object.keys(rest).forEach((key) => {
                formData.append(`propertyDetails[${key}]`, rest[key]);
            });
        } else {
            console.warn("Property details are missing. Proceeding without property details.");
        }

        if (mortgageDetails) {
            Object.keys(mortgageDetails).forEach((key) => {
                formData.append(`mortgageDetails[${key}]`, mortgageDetails[key]);
            });
        }

        if (purchaseRelatedDetails) {
            Object.keys(purchaseRelatedDetails).forEach((key) => {
                formData.append(`purchaseRelatedDetails[${key}]`, purchaseRelatedDetails[key]);
            });
        }

        if (importantDetails) {
            Object.keys(importantDetails).forEach((key) => {
                formData.append(`importantDetails[${key}]`, importantDetails[key]);
            });
        }

        if (leasePurchaseDetails) {
            Object.keys(leasePurchaseDetails).forEach((key) => {
                formData.append(`leasePurchaseDetails[${key}]`, leasePurchaseDetails[key]);
            });
        }

        if (propertyDocuments.length > 0) {
            propertyDocuments.forEach((doc, index) => {
                formData.append(`propertyDocuments[${index}]`, doc);
            });
        } else {
            console.warn("Property documents are missing or invalid. Proceeding without documents.");
        }

        if (isSubmit) {
            formData.append("submit", true);
        }

        try {
            if (isUpdate) {
                const { relevantDocuments, ...rest } = data;
                const payload = { ...rest, documents: propertyDocuments };
                if (isSubmit) {
                    payload.submit = true;
                }
                await storeFormDetails(payload, propertyDocuments, "documents");
                toast.success("Saved!");
                if (isSubmit) {
                    clearFormState();
                    navigate(CONSTANTS.routes.rtoFormSubmissions);
                }
            } else {
                await storeFormDetails({ ...data, documents: propertyDocuments });
                toast.success(isSubmit ? "Form submitted successfully" : "Form saved successfully");
                if (isSubmit) {
                    clearFormState();
                    navigate(CONSTANTS.routes.rtoFormSubmissions);
                }
            }
        } catch (error) {
            toast.error("Failed to save form");
        }
    };

    const Label = ({ text, subtexts, star }) => (
        <div>
            {text && (
                <div className="flex items-center gap-2">
                    {star && <p className="text-[#FF0000] text-[30px]">*</p>}
                    <h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
                        {text}
                    </h3>
                </div>
            )}
            {subtexts &&
                subtexts.map((subtext, index) => (
                    <p key={index} className="font-normal text-[#6F6C90] !my-0">
                        {subtext}
                    </p>
                ))}
        </div>
    );

    console.log(errors);

    return (
        <div>
            <div className="bg-white lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
                <SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
                <div className="flex flex-col items-center justify-center">
                    <form
                        onSubmit={handleSubmit((data) => onSubmit(data, true))}
                        className="mt-[29px]  shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px]  px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
                    >
                        <h3 className="font-black max-w-[508px] text-[20px] text-_25253C leading-[41.3px]  lg:text-[24px] lg:font-bold lg:leading-[61.6px] lg:text-left">
                            LEASE-PURCHASE QUESTIONS
                        </h3>
                        <p className="font-normal text-[#6F6C90]">Investor is SELLER in this section</p>

                        <div className="flex flex-col space-y-[13.08px]  mt-10">
                            <Label star text={"At what price will the property be re-purchased?"} />
                            <Controller
                                control={control}
                                name="priceToBeRepurchased"
                                rules={{ validate: validators.validateNonRequiredNumber }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Input
                                        ref_={ref}
                                        isNumeric
                                        prefix="$"
                                        placeholder={"Your answer"}
                                        value={value}
                                        onChange={onChange}
                                        hasError={!!errors.priceToBeRepurchased}
                                        className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
                                    />
                                )}
                            />

                            <Label text={"How much is the real estate agent fee to the SELLER for the re-purchase if any?"} />
                            <Controller
                                control={control}
                                name="realEstateAgentFeeToSeller"
                                rules={{ validate: validators.validateNonRequiredNumber }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Input
                                        placeholder={"Your Answer"}
                                        ref_={ref}
                                        value={value}
                                        onChange={onChange}
                                        isNumeric
                                        prefix="$"
                                        hasError={!!errors.realEstateAgentFeeToSeller}
                                        className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
                                    />
                                )}
                            />
                            <Label
                                text={"Please list any other SELLER expenses for the re-purchase."}
                                subtexts={[
                                    "May look like:",
                                    "Closing cost assistance: $5,000",
                                    "Some other expense: $1,500",
                                ]}
                            />
                            <Controller
                                control={control}
                                name="otherSellerExpensesForRepurchase"
                                rules={{ validate: validators.validateNonRequiredString }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Input
                                        placeholder={"Your Answer"}
                                        ref_={ref}
                                        value={value}
                                        onChange={onChange}
                                        hasError={!!errors.otherSellerExpensesForRepurchase}
                                        className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
                                    />
                                )}
                            />

                            <Label text={"How many MONTHS will the property be leased prior to re-purchasing?"} />
                            <Controller
                                control={control}
                                name="monthsLeasedBeforeRepurchase"
                                rules={{ validate: validators.validateNonRequiredNumber }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Input
                                        placeholder={"Your Answer"}
                                        ref_={ref}
                                        value={value}
                                        onChange={onChange}
                                        isNumeric
                                        hasError={!!errors.monthsLeasedBeforeRepurchase}
                                        className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
                                    />
                                )}
                            />
                            <Label
                                text={"How much of a down payment is the buyer willing to pay at the signing of the lease-purchase agreement?"}
                                subtexts={["Signed at the same time as the first closing where they sell to us"]}
                            />
                            <Controller
                                control={control}
                                name="downPaymentBuyerWillingToPay"
                                rules={{ validate: validators.validateNonRequiredNumber }}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Input
                                        placeholder={"Your Answer"}
                                        ref_={ref}
                                        value={value}
                                        isNumeric
                                        prefix="$"
                                        onChange={onChange}
                                        hasError={!!errors.downPaymentBuyerWillingToPay}
                                        className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
                                    />
                                )}
                            />
                            <div className="flex flex-col space-y-4">
                                {isUpdate && (
                                    <div className="flex space-x-1 mb-4">
                                        {documents.map((picture) => (
                                            <UploadedFormMedia
                                                key={picture._id}
                                                mediaType={"document"}
                                                mediaId={picture._id}
                                                mediaUrl={picture.url}
                                                submissionId={submissionId}
                                            />
                                        ))}
                                    </div>
                                )}

                                <Label text={"Upload relevant documents"} />
                                <div
                                    {...getRootProps()}
                                    className="!my-5 shadow lg:!my-[0px] border-[2px] border-white rounded-[13.48px] border-dashed h-[102.72px] lg:h-[156px] flex flex-col items-center justify-center space-y-[6px]"
                                >
                                    <input {...getInputProps()} />
                                    <Picture />
                                    <p className="text-center text-[#C9C7C7] text-[10.54px] lg:text-base lg:leading-6">
                                        {propertyDocuments.length
                                            ? `${propertyDocuments?.length} ${pluralize(
                                                    propertyDocuments?.length,
                                                    "document"
                                                )} uploaded`
                                            : "Upload PDF, JPEG, PNG "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <StepButtons
                currentPage={currentPage}
                onPrev={onPrev}
                onProceed={handleSubmit((data) => onSubmit(data, false))}
                isLastPage={true}
                onSubmit={handleSubmit((data) => onSubmit(data, true))}
                rtoFormDetails={rtoFormDetails}
            />
            <BlockingLoader loading={loading} text={"Submitting"} />
        </div>
    );
};

export default StepFive;
