import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import clsx from 'classnames'
import { ExtensivePropertyDetail } from 'modules/properties/components'
import { ReactComponent as Location } from 'modules/properties/assets/icons/location.svg'
import { getObjectUrl, getRandomID, parseAddress, pluralize } from 'modules/common/utils/functions'
import { ReactComponent as Star } from 'modules/admin/assets/icons/star.svg'
import { Button } from 'modules/common/components'
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'

const FILE_IDENTIFIER = "-frontierstreetfile"

const APISearchResultImagePreview = ({ imageUrl, onClose }) => {
    if (!imageUrl) {
        return null
    }
    return (
        <div className='bg-black/[.7] fixed h-screen w-screen top-0 left-0 p-2 z-[100] flex items-center justify-center' onClick={onClose}>
            <div className='h-[80vh] w-[80vh] bg-white flex items-center justify-center'>
                <img src={imageUrl} alt='Result' className='h-[80vh] w-[80vh] object-contain' onClick={e => e.stopPropagation()} />
            </div>
        </div>
    )
}

const APISearchResultImageModal = ({ imageUrl, onClose, onImageDelete, imageId }) => {
    if (!imageUrl) {
        return null
    }
    return (
        <div className='bg-black/[.7] fixed h-screen w-screen top-0 left-0 p-2 z-[100] flex items-center justify-center' onClick={onClose}>
            <div className='bg-white rounded-lg max-w-[600px] lg:w-[600px] flex flex-col space-y-4 items-center justify-center p-5' onClick={e => e.stopPropagation()}>
                <img src={imageUrl} alt='Result' className='h-[100px] w-[100px]' />
                <p>Are you sure you want to delete this image?</p>
                <div className='flex flex-col space-y-2 md:space-y-0 md:flex-row items-center justify-center md:space-x-3'>
                    <Button text={"Delete"} classNames={"bg-[red]"} textClassNames={"text-white"}
                        onClick={() => onImageDelete(imageId ?? imageUrl)}
                    />
                    <Button text={"Cancel"} onClick={onClose} />
                </div>
            </div>
        </div>
    )
}

const APISearchResultImage = ({ isManuallyUploaded, onClick, source, onDelete }) => {
    return (
        <div className='h-[200px] relative' onClick={onClick}>
            <img src={source} alt='Result' className='h-full w-full absolute top-0 left-0' />
            <button onClick={(e) => {
                e.stopPropagation()
                onDelete(source, isManuallyUploaded)
            }} className='h-6 w-6 flex items-center rounded-full absolute bottom-[10px] right-[10px] bg-[red]'>
                <svg viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M667.8 362.1H304V830c0 28.2 23 51 51.3 51h312.4c28.4 0 51.4-22.8 51.4-51V362.2h-51.3z" fill="#ffffff"></path><path d="M750.3 295.2c0-8.9-7.6-16.1-17-16.1H289.9c-9.4 0-17 7.2-17 16.1v50.9c0 8.9 7.6 16.1 17 16.1h443.4c9.4 0 17-7.2 17-16.1v-50.9z" fill="#ffffff"></path><path d="M733.3 258.3H626.6V196c0-11.5-9.3-20.8-20.8-20.8H419.1c-11.5 0-20.8 9.3-20.8 20.8v62.3H289.9c-20.8 0-37.7 16.5-37.7 36.8V346c0 18.1 13.5 33.1 31.1 36.2V830c0 39.6 32.3 71.8 72.1 71.8h312.4c39.8 0 72.1-32.2 72.1-71.8V382.2c17.7-3.1 31.1-18.1 31.1-36.2v-50.9c0.1-20.2-16.9-36.8-37.7-36.8z m-293.5-41.5h145.3v41.5H439.8v-41.5z m-146.2 83.1H729.5v41.5H293.6v-41.5z m404.8 530.2c0 16.7-13.7 30.3-30.6 30.3H355.4c-16.9 0-30.6-13.6-30.6-30.3V382.9h373.6v447.2z" fill="#211F1E"></path><path d="M511.6 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.4 9.3 20.7 20.8 20.7zM407.8 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0.1 11.4 9.4 20.7 20.8 20.7zM615.4 799.6c11.5 0 20.8-9.3 20.8-20.8V467.4c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.5 9.3 20.8 20.8 20.8z" fill="#211F1E"></path></g></svg>
            </button>
        </div>
    )
}


const APISearchResult = ({ result, onList, onOtherPropertyClick }) => {
    const [detail, setDetail] = useState({ ...result })
    const [rating, setRating] = useState(5)
    const [extraImages, setExtraImages] = useState([])
    const [imageToDelete, setImageToDelete] = useState(null)
    const [imageToPreview, setImageToPreview] = useState(null)
    const homeValue = detail.homeValue
    const basicInfo = detail.basicInfo
    const homeDetails = detail.homeDetails
    const neighbourHoodDetails = detail.neighbourHoodDetails
    const miscData = detail.miscData

    const onDrop = files => {
        const goodImages = files.filter(file => getObjectUrl(file) !== "-")
        if (goodImages.length !== files.length) {
            toast.error("Some images were unable to display")
        }
        const newFiles = goodImages.map(file => ({ id: `${new Date().toISOString()}${FILE_IDENTIFIER}-${getRandomID()}`, file }))
        setExtraImages([...newFiles, ...extraImages])
    }

    const onBasicInfoChange = (key, value) => {
        setDetail({
            ...detail, basicInfo: {
                ...detail.basicInfo,
                [key]: value
            }
        })
    }


    const onHomeDetailChange = (key, value) => {
        setDetail({
            ...detail, homeDetails: {
                ...detail.homeDetails,
                [key]: value
            },
            basicInfo: {
                ...detail.basicInfo,
                [key]: value
            }
        })
    }

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png']
        },
        maxFiles: 50,
        maxSize: 3145728
    });


    const onImageDelete = (imageUrlOrId) => {
        if (imageUrlOrId?.includes(FILE_IDENTIFIER)) {
            setExtraImages(extraImages.filter(image => image.id !== imageUrlOrId))
        }
        else {
            setDetail({
                ...detail, basicInfo: {
                    ...basicInfo,
                    images: basicInfo.images.filter(url => url !== imageUrlOrId)
                }
            })
        }
        setImageToDelete(null)
        toast.success("Deleted!")
    }

    console.log(basicInfo)

    return (
        <div className='px-5 bg-white pt-[59px] pb-5 rounded-10'>
            <div className='pb-2 flex items-center justify-between'>
                {onList && <Button
                    text={"Publish Property"}
                    classNames={"ml-auto"}
                    onClick={() => {
                        onList({
                            rating,
                            basicInfo,
                            homeDetails,
                            neighbourHoodDetails,
                            miscData,
                            homeValue,
                            extraImages
                        })
                    }}
                />}
            </div>
            <APISearchResultImagePreview
                imageUrl={imageToPreview}
                onClose={() => setImageToPreview(null)}
            />
            <APISearchResultImageModal
                imageUrl={imageToDelete?.includes(FILE_IDENTIFIER) ? getObjectUrl(
                    extraImages.find(image => image.id === imageToDelete)?.file
                ) : imageToDelete}
                onClose={() => setImageToDelete(null)}
                onImageDelete={onImageDelete}
                imageId={imageToDelete?.includes(FILE_IDENTIFIER) ? imageToDelete : null}
            />
            <div className='flex flex-col space-y-[11px] mb-7'>
                <span className='text-base leading-6 text-_25253C'>Add Photos</span>
                <div
                    {...getRootProps()}
                    className='bg-white relative h-[162px] border border-E8EBEC rounded-10 flex flex-col items-center justify-center'>
                    <input
                        {...getInputProps()}
                    />
                    <div className='h-6 w-6 rounded-full flex items-center justify-center shadow-[1px_1px_4px_0px_#D9D9D9] mb-[9px]'
                    >
                        <span>+</span>
                    </div>
                    <div>
                        <h3 className='text-base leading-6 text-[#848484] text-center'>{extraImages.length ? `${extraImages?.length} ${pluralize(extraImages?.length, "picture")} uploaded` : "Upload Pictures here"}</h3>
                        <p className='text-E8EBEC italic text-[10px] text-center'>Add up to 50 pictures</p>
                    </div>
                </div>

            </div>

            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                {(basicInfo.images || []).map((image, index) => (
                    <APISearchResultImage
                        key={index}
                        source={image}
                        onClick={() => setImageToPreview(image)}
                        onDelete={() => setImageToDelete(image)}
                    />
                ))}
                 {(extraImages || []).map((imageFile) => (
                    <APISearchResultImage
                        isManuallyUploaded={true}
                        key={imageFile.id}
                        source={getObjectUrl(imageFile.file)}
                        onClick={() => setImageToPreview(getObjectUrl(imageFile.file))}
                        onDelete={() => setImageToDelete(imageFile.id)}
                    />
                ))}
            </div>
            {/* <PropertyImagesCarousel
                imageUrls={(basicInfo.images || [])}
                smallImg={(basicInfo.images || []).length > 1 ? basicInfo.smallImage : null}
                showBackButton={false}
            /> */}
            <div className='1400:mx-auto 1400:max-w-[1320px]'>
                <div className='mt-[51px] lg:mt-[95px] flex justify-between'>
                    <h3 className='text-_25253C font-bold text-[32px]'>{basicInfo?.tag}</h3>
                    <div className='flex flex-col space-y-[11px] flex-shrink-0'>
                        <span className='text-base leading-none text-_25253C ml-auto'>Ratings</span>
                        <div className='flex space-x-[3px] items-center'>
                            <NumericFormat
                                decimalScale={0}
                                allowNegative={false}
                                value={rating}
                                onValueChange={(values) => setRating(values.floatValue)}
                                isAllowed={(values) => {
                                    if (!values.value) return true;
                                    const { floatValue } = values;
                                    return floatValue < 6 && floatValue > 0;
                                }}
                                className={clsx("h-8 w-[39px] text-center bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base")}
                            />
                            <div className='flex space-x-[2px]'>
                                {
                                    Array(5).fill(0).map((item, index) => {
                                        const isFilled = (rating || 0) > index
                                        return (
                                            <Star key={index} className={isFilled ? 'filled-star' : ''} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[17px] lg:mt-[10px] flex items-center space-x-[1.68px]'>
                    <Location className='1436:h-[46px] 1436:w-[46px]' />
                    <p className='text-[13.41px] text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{parseAddress(basicInfo?.address || {})}</p>
                </div>
                <div className='flex flex-col space-y-[59px] w-full'>
                    <div className='mt-[46.3px] flex flex-col space-y-6 mb-[37px] 1436:mt-[95px] 1436:mb-0 1436:flex-1'>
                        <h2 className='text-[24px] text-_25253C font-bold leading-[34px] 1436:text-[48px] 1436:leading-[52.8px]'>Property Description</h2>
                        <textarea value={basicInfo?.description} className='text-_25253C outline-none text-base leading-6 1436:text-[24px] 1436:leading-[34px]'
                            onChange={(e) => onBasicInfoChange("description", e.target.value)}
                        />
                    </div>
                    <ExtensivePropertyDetail
                        className={"!max-w-full !w-full"}
                        homeDetails={homeDetails}
                        homeValue={homeValue}
                        neighborhoodDetails={neighbourHoodDetails}
                        basicInfo={basicInfo}
                        onOtherPropertyClick={onOtherPropertyClick}
                        onHomeDetailChange={onHomeDetailChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default APISearchResult