import React,{useState} from 'react'
import { CommaSeperatedNumber } from 'modules/common/components'

const Label = ({ text, subtext }) => {
  return (
      <>
          {text && (
              <h3 className='font-bold text-[18px]  text-_25253C leading-6 lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold  lg:text-left'>
                  {text}
              </h3>
          )}
          {subtext && (
              <p className='font-normal text-[#6F6C90] !my-0'>
                  {subtext}
              </p>
          )}
      </>
  );
};

const Paragraph = ({ text }) => {
  return (
      <>
          {text && (
            <p className='text-_25253C  border-b border-b-[#EEEEEE] leading-6 1436:text-[18px] 1436:leading-[34px]'>
                {text}
            </p>
          )}
      </>
  );
};

const LeaseDetails = ({detail}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    const handlePreview = (url) => {
        setSelectedImageUrl(url);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImageUrl('');
        setModalOpen(false);
    };

    const { 
        priceToBeRepurchased,
        realEstateAgentFeeToSeller,
        otherSellerExpensesForRepurchase,
        monthsLeasedBeforeRepurchase,
        downPaymentBuyerWillingToPay,
        relevantDocuments
    } = detail.leasePurchaseDetails
   return (
         <div>  
             <div className='  lg:flex  lg:justify-between 1400:mx-auto 1400:max-w-[1320px]'>
                 {/* <SellYourHouseDiamonds className='absolute h-full w-full left-0 top-0 lg:block hidden' /> */}
                 <div className='flex flex-col items-center justify-center'>
                     <div className='mt-[29px]  shadow mx-auto z-[1]  rounded-[11.89px] lg:rounded-[20.22px]   px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] md:mb-[30px]'>
                      <h3 className='font-black text-[20px] text-_25253C leading-[24px]  lg:text-[40px] mb-5 lg:font-bold lg:leading-[61.6px] lg:text-left'>
                         Lease Purchase Questions
                       </h3>
                         <div className='flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-10'>
                         <Label text={"At what price will the property be re-purchased?"}/> 
                         <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={priceToBeRepurchased}
                                         dollarSign
                                      />
                                    }
                            />  

                          <Label text={"How much is the real estate agent fee to the SELLER for the re-purchase if any?"}/> 
                          <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={realEstateAgentFeeToSeller}
                                         dollarSign
                                      />
                                    }
                            />
                          
                          <Label text={"Please list any other SELLER expenses for the re-purchase."}/>
                          <Paragraph text= {otherSellerExpensesForRepurchase} /> 

                          <Label text={"How many MONTHS will the property be leased prior to re-purchasing?"}/>
                          <Paragraph text={`${monthsLeasedBeforeRepurchase} months`}/>
                          
                          
                          <Label text={'How much of a down payment is the buyer willing to pay at the signing of the lease-purchase agreement?'}/>
                          <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={downPaymentBuyerWillingToPay}
                                         dollarSign
                                      />
                                    }
                            />

                          <Label text={'Relevant documents'}/>
                          {relevantDocuments.map((doc, index) => {
                            return (
                                <div key={index}>
                                    {/* Check the file type and render accordingly */}
                                    {doc.url.endsWith('.pdf') ? (
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                                    ) : doc.url.endsWith('.jpeg') || doc.url.endsWith('.png') || doc.url.endsWith('.jpg') ? (
                                        <img src={doc.url} width="300px" height="300px" alt={doc.name} onClick={() => handlePreview(doc.url)} />
                                    ) : doc.url.endsWith('.doc') || doc.url.endsWith('.docx') ? (
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                                    ) : doc.url.endsWith('.xls') || doc.url.endsWith('.xlsx') ? (
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                                    ) : doc.url.endsWith('.csv') ? (
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                                    ) : (
                                        <span>Unsupported file type</span>
                                    )}
                                </div>
                               );
                              })}
                          
                         </div>  
                     </div>
                 </div>
                 
             </div>
         </div>
  )
}

export default LeaseDetails