import { Input } from "modules/common/components";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";
import StepButtons from "../StepButtons";
import { initialRTOFormState } from "modules/common/utils/constants";

const StepFour = ({ currentPage, onPrev, onNext }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		watch,
		setValue,
	} = useForm({
		defaultValues: {
			...initialRTOFormState.purchaseRelatedDetails,
		},
	});

	const { storeFormDetails } = useRtoForm({
		setter: reset,
		step: formSteps.purchaseRelatedDetails,
	});

	const buyerNeedsToPayForFurnitureOrMovingExpenses = watch("buyerNeedsToPayForFurnitureOrMovingExpenses");

	const onSubmit = async (data) => {
		if (await storeFormDetails(data)) {
			onNext();
		}
	};
	const Label = ({ text, subtexts,star }) => {
		return (
			<div>
				{text && (
				<div className="flex items-center gap-2">
				{star && <p className="text-[#FF0000] text-[30px]">*</p>}
				<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
					{text}
				</h3>
				</div>
            )}
				{subtexts &&
					subtexts.map((subtext, index) => (
						<p key={index} className="font-normal text-[14px] md:text-[16px] text-[#6F6C90] !my-0">
							{subtext}
						</p>
					))}
			</div>
		);
	};

	return (
		<div>
			<div className="bg-white lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
				<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
				<div className="flex flex-col items-center justify-center">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-[29px]  shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px]  px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
					>
						<h3 className="font-black max-w-[508px] text-[18px] text-_25253C leading-[24px]  lg:text-[24px] lg:font-bold lg:leading-[61.6px] lg:text-left">
							PURCHASE RELATED QUESTIONS
						</h3>
						<p className="font-normal text-[#6F6C90]">Investor is BUYER in this section</p>
						<Label
								text={"Gross Cash to Seller"}
								star
								subtexts={[
									"Included in this number should be things like what the seller actually walks away from closing with, seller's agent fee, sewer liens, back taxes, anything the seller keeps or is paying to close the house, etc",
									"DO NOT INCLUDE MORTGAGES, JUDGEMENT, LIENS, ETC, LISTED ABOVE.",
									"Example: Seller wants to walk with $5,000, their agent is $10,000, back property taxes of $2,000, and paying off the alarm system is $1,500 = $18,500 would be what you put as this answer.",
								]}
							/>
							<Controller
								control={control}
								name="grossCashToSeller"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.grossCashToSeller}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										isNumeric
										prefix="$"
									/>
								)}
							/>
						<div className="flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-5">
							<Label text={"How much is the wholesale fee for the BUYER if any?"} />
							<Controller
								control={control}
								name="wholesaleFeeForBuyer"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										ref_={ref}
										placeholder={"Your answer"}
										value={value}
										onChange={onChange}
										isNumeric
										prefix="$"
										hasError={!!errors.wholesaleFeeForBuyer}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>

							<Label text={"How much is the real estate agent fee for the BUYER if any?"} />
							<Controller
								control={control}
								name="realEstateAgentFee"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.realEstateAgentFee}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										isNumeric
										prefix="$"
									/>
								)}
							/>
							
							<Label
								text={
									"Does the BUYER need to pay for furniture, or moving expenses, or anything like that?"
								}
							/>
							<Controller
								control={control}
								name="buyerNeedsToPayForFurnitureOrMovingExpenses"
								render={({ field }) => (
									<div>
										<input
											type="radio"
											id="compositionOption1"
											value="Yes"
											{...field}
											checked={field.value === true}
											onChange={(value) =>
												setValue("buyerNeedsToPayForFurnitureOrMovingExpenses", true)
											}
										/>
										<label>Yes</label>

										<input
											type="radio"
											id="compositionOption2"
											value="No"
											{...field}
											checked={field.value === false}
											onChange={(value) =>
												setValue("buyerNeedsToPayForFurnitureOrMovingExpenses", false)
											}
										/>
										<label>No</label>
									</div>
								)}
							/>
							<Label
								text={'If, "YES," please line-item those BUYER expenses.'}
								subtexts={[
									"A recent mortgage statement or the online account may tell you",
									"Might look like:",
									"Furniture - $1,500",
									"Moving Costs - $1,500",
								]}
							/>
							<Controller
								control={control}
								name="buyerExpenses"
								rules={
									buyerNeedsToPayForFurnitureOrMovingExpenses
										? {
												validate: validators.validateString,
												required: true,
										  }
										: {}
								}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={buyerNeedsToPayForFurnitureOrMovingExpenses && !!errors.buyerExpenses}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>

							{/* <Button
                                    loading={loading}
                                    type="submit" text={"Submit"} classNames={"!mt-0 py-[13.57px] w-full lg:!py-[23.07px]"} textClassNames={"lg:!text-[20px]"} /> */}
						</div>
					</form>
				</div>
			</div>
			<StepButtons currentPage={currentPage} onPrev={onPrev} onProceed={handleSubmit(onSubmit)} />
		</div>
	);
};

export default StepFour;
