import { Button, Input, NavigationWrapper, PhoneNumberInput } from 'modules/common/components'
import { ReactComponent as SellYourHouseDiamonds } from 'modules/home/assets/images/sell-your-house-diamonds.svg'
import React, { useState } from 'react'
import housesSample from 'modules/home/assets/images/houses-sample.png'
import sellYourHouseLg from 'modules/home/assets/images/sell-your-house-lg.png'
import { Controller, useForm } from 'react-hook-form'
import { validators } from 'modules/common/utils/functions'
import { useWindowSize, BREAKPOINTS } from 'modules/common/hooks'
import API from 'api'

const SellYourHouse = () => {
    const { width } = useWindowSize()
    const [loading, setLoading] = useState(false)
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: "",
            phoneNumber: "",
            propertyAddress: "",
            message: ""
        }
    })

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            await API.applyForFairCash(data)
            reset()
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
        }
    }

    return (
        <NavigationWrapper containerClassName="bg-_25253C lg:bg-white" title={"Sell your house"}>
            <div className='pt-[80.37px] lg:pt-[309px]'>
                <div className='flex items-center justify-between pt-5 bg-white lg:pl-[171px] lg:hidden'>
                    <div className='ml-auto'>
                        <img src={housesSample} alt="Houses sample" className='h-[206px] object-contain translate-x-[25.07px]' />
                    </div>
                </div>
                <div className='bg-white px-5 lg:flex lg:justify-between lg:pl-[171px] lg:pr-0 1400:max-w-[1400px] 1400:mx-auto'>
                    <SellYourHouseDiamonds className='absolute h-full w-full left-0 top-0 lg:block hidden' />
                    <div className='relative'>
                        <h3 className='font-black text-[33.38px] text-_25253C leading-[41.3px] text-center lg:text-[56px] lg:font-bold lg:leading-[61.6px] lg:text-left'>Get A Fair Cash Offer</h3>
                        <p className='mt-[5px] lg:mt-1 text-[27.28px] lg:text-[48px] leading-[30.01px] lg:leading-[52.8px] text-_25253C font-bold text-center lg:text-left'>No Fees. No Commissions!</p>
                        <form onSubmit={handleSubmit(onSubmit)} className='mt-[79px] lg:mt-[53px] mx-auto bg-_25253C rounded-[11.89px] lg:rounded-[20.22px] max-w-[310px] lg:max-w-[527px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[30.46px] mb-[197.08px]'>
                            <div className='flex flex-col space-y-[13.08px] lg:space-y-[22.24px]'>
                                <Controller
                                    control={control}
                                    name='firstName'
                                    rules={{ validate: validators.validateString, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Input placeholder={"First Name"}
                                            onChange={onChange}
                                            hasError={!!errors.firstName}
                                            value={value}
                                            ref_={ref}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name='lastName'
                                    rules={{ validate: validators.validateString, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Input placeholder={"Last Name"}
                                            onChange={onChange}
                                            hasError={!!errors.lastName}
                                            value={value}
                                            ref_={ref}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name='email'
                                    rules={{ validate: validators.validateEmail, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Input placeholder={"Email"} type="email"
                                            onChange={onChange}
                                            hasError={!!errors.email}
                                            value={value}
                                            ref_={ref}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name='phoneNumber'
                                    rules={{ validate: validators.validatePhoneNumber, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <PhoneNumberInput
                                            ref_={ref}
                                            value={value}
                                            onChange={onChange}
                                            hasError={!!errors.phoneNumber}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="propertyAddress"
                                    rules={{ validate: validators.validateString, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Input placeholder={"Property Address"}
                                            ref_={ref}
                                            value={value}
                                            onChange={onChange}
                                            hasError={!!errors.propertyAddress}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="message"
                                    rules={{ validate: validators.validateString, required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Input placeholder={"Message"}
                                            ref_={ref}
                                            value={value}
                                            onChange={onChange}
                                            hasError={!!errors.message}
                                        />
                                    )}
                                />

                                <Button
                                    loading={loading}
                                    type="submit" text={"YES, I ACCEPT!"} classNames={"py-[13.57px] w-full lg:!py-[23.07px]"} textClassNames={"lg:!text-[20px]"} />
                            </div>
                            <div className='flex items-start gap-2'>
                                <input type="checkbox" id="myCheckbox" name="myCheckbox" className='mt-[6px]'></input>
                                <p className=' text-[10px] lg:text-[11px] leading-[14.12px] lg:leading-6 text-white'>By clicking 'Yes, I accept' above, I agree to let Frontier street LLC and its partners send me marketing, transactional, and informational messages about buying or selling my property. Fontier street LLC will send up to 4 to 5 messages a month, phone calls, pre-recorded or artificial voice messages, either from their phone system or with their CRM or Automatic Telephone Dialing System (ATDS), regarding my submitted property to provide additional information, property valuation, a request to buy my house, or make an offer. I also confirm that I am inquiring about my property from the number I own and provided. I can unsubscribe at any time by simply texting the word STOP to +17652469435 . Frontier street LLC  assures me that my privacy will always be safeguarded and respected. This consent applies even if I am previously registered on any Federal, State, and/or internal Do Not Call (DNC) list or registries. I confirm that I am at least 18 years old and have read and accepted your website's <a className='underline' href='/privacy-policy'>Privacy Policy</a> and <a className='underline' href='/terms-of-service'>Terms & Conditions</a>. Msg&data rates may apply.   </p>
                            </div>


                        </form>
                    </div>
                    {width >= BREAKPOINTS.lg &&
                        <img src={sellYourHouseLg} alt="Houses sample" className='h-[812.92px] object-contain translate-x-[40.07px]' />
                    }
                </div>
            </div>
        </NavigationWrapper>
    )
}

export default SellYourHouse