import { Button, NavigationWrapper } from 'modules/common/components'
import React from 'react'
import { ReactComponent as DiamondsLg } from 'modules/home/assets/images/diamonds-lg.svg'
import housesSample from 'modules/home/assets/images/houses-sample.png'
import { ReactComponent as Binoculars } from 'modules/home/assets/icons/binoculars.svg'
import { ReactComponent as Correct } from 'modules/home/assets/icons/correct.svg'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'

const SpyForUs = () => {
    const navigate = useNavigate()
    return (
        <NavigationWrapper containerClassName="bg-_25253C lg:bg-white" title={"Spy for us"}>
            <div className='bg-_25253C h-[83.07px] lg:hidden' />
            <div className='px-5 bg-white pt-[175.27px] lg:pt-[188px] relative'>
                <DiamondsLg className='absolute pointer-events-none h-full w-full top-0 left-0' />
                <img src={housesSample} alt="Houses sample" className='absolute translate-x-[50.07px] scale-105 lg:block hidden right-0 top-[200px]' />
                <div className='lg:max-w-[1200px] lg:mx-auto'>
                    <div className='flex items-center space-x-[15.29px]'>
                        <h2 className='text-_25253C font-bold text-[31.83px] leading-[35.02px] lg:text-[56px] lg:leading-[61.6px]'>SPY FOR US</h2>
                        <Binoculars className='lg:h-[70px] lg:w-[70px]' />
                    </div>
                    <div className='mt-[19.33px] flex space-x-[21.03px]'>
                        <div className='w-[3.98px] lg:w-[7px] h-[76.17px] lg:h-[134px] bg-D50100' />
                        <p className='text-_25253C text-[18.19px] leading-[20.01px] max-w-[577px] lg:text-[32px] lg:leading-[35.2px]'>
                            Please click the button below to share property details . You'll be eligible to <span className='font-bold'>claim $1,000</span> when we purchase a property you recommend.
                        </p>
                    </div>
                    <div className='mt-[13.07px] flex space-x-[21.03px] lg:mt-[23px]'>
                        <div className='w-[3.98px] lg:w-[7px] opacity-0 h-full pointer-events-none' />
                        <Button text={"Fill Out The Spy Form"}
                            onClick={() => navigate(CONSTANTS.routes.spyForm)}
                            textClassNames={"lg:!text-[24px]"} classNames={"lg:!py-4 lg:!px-[50px]"} />
                    </div>
                    <div className='mt-[146.78px] lg:mt-[195px] flex flex-col space-y-[25px] lg:space-y-[22px] lg:max-w-[913px]'>
                        <h3 className='text-xl leading-[22px] text-_25253C font-bold'>
                            Earn $1,000 For Something You Already Do Everyday; But Don't Get Paid For
                        </h3>
                        <div>
                            <p className='text-base leading-6 text-_25253C mb-8'>
                                In the complex world of real estate, distinguishing unique opportunities can significantly differentiate successful agents from average ones.
                                Among these, distressed properties present a remarkable chance. For real estate agents, such properties offer the opportunity to serve members of the community in ways other than just helping buyers and sellers facilitate a transaction and earn a commission.
                                Not all home sellers are in a position where hiring a real estate agent is their best course of action.
                                Not all homes are suited for an MLS listing.
                                Sometimes the home will be unable to get underwritten for a mortgage, or the seller needs to close on the property very quickly.
                                In these situations, selling off market to an investor is what is best for the seller.
                                That&apos;s where you come in.
                            </p>
                            <p className='text-base leading-6 text-_25253C mb-12'>
                                Starting today, get paid for more opportunity you find everyday. As you are out and about; if you find a property that is:
                            </p>
                            <div className='flex flex-col space-y-2'>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>For Sale By Owner</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>Vacant</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>Could be considered a distressed property.</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>A rental property that has a tenant, but the house isn&apos;t taken care of.</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>Pre foreclosure</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>Death in the family</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>Relocation</p>
                                </div>
                                <div className='flex space-x-1'>
                                    <Correct className="flex-shrink-0" />
                                    <p className='text-_25253C text-base'>A tired landlord</p>
                                </div>
                            </div>
                            <div className='mt-10'>
                                <p className='text-base font-bold text-_25253C  mb-8'>
                                    I am an unrepresented investor. Anything you find we can run it through you. So you get the commission. Bring me a distressed property you have listed. You get both sides.
                                </p>
                                <p className='text-base leading-6 text-_25253C'>
                                    Gather as much information as you can, submit it in the form below, include as many pictures as you are able to. I will do the rest. I will go by the property. Research and find the owners information I will run comps on the property to see what it’s worth in current condition. I will come up with an ARV (after repair value). I will call and make a cash offer to buy the property. If the offer is accepted, after the closing; I will pay you $1,000. I will keep you updated with every process of our investigation via email. And all the properties you submit will be listed in your account.
                                </p>
                                <p className='text-base font-bold text-_25253C mt-6 mb-12'>
                                    What do you have to lose?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white pt-8 pb-[90px] flex items-center justify-between'>
                <div className='ml-auto'>
                    <img src={housesSample} alt="Houses sample" className='h-[206px] object-contain translate-x-[5.07px] scale-150 lg:hidden' />
                </div>
            </div>
        </NavigationWrapper>
    )
}

export default SpyForUs