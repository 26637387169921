/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ReactComponent as Search } from 'modules/common/assets/icons/search.svg'
import { isEmpty } from 'lodash'
import { BlockingLoader } from 'modules/common/components'
import API from 'api'
import PROPERTIES_CONSTANTS from 'modules/properties/utils/constants'
import { useDebouncedLoader } from 'modules/common/hooks'
import OtherPropertySearch from './OtherPropertySearch'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import toast from 'react-hot-toast'
import APISearchResult from './APISearchResult'


const ListUsingAPI = () => {
    const [query, setQuery] = useState(null)
    const [response, setResponse] = useState(null)
    const [searching, setSearching] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [otherPropertyAddress, setOtherPropertyAddress] = useState(null)

    const searchForProperty = async (address) => {
        setSearching(true)
        if (response) {
            setResponse(null)
        }
        try {
            const data = await API.admin.searchWithAddress(address)
            setResponse(data)
            setSearching(false)
        }
        catch (e) {
            setSearching(false)
        }
    }


    useEffect(() => {
        if (isEmpty(query?.label?.trim())) {
            if (response) {
                setResponse(null)
            }
        }
        else {
            searchForProperty(query?.label?.trim())
        }
    }, [query])


    const onList = async ({ rating, homeValue,
        basicInfo,
        homeDetails,
        neighbourHoodDetails,
        miscData,
        extraImages
    }) => {
        if (!basicInfo?.images?.length && !extraImages?.length) {
            toast.error("No images!")
            return
        }
        setSubmitting(true)
        try {
            const { address } = basicInfo;
            const { city, neighborhood, state, streetAddress, zipcode: zipCode } = address;
            const payload = {
                tag: basicInfo?.tag,
                bathroomCount: homeDetails?.bathroomCount,
                bedroomCount: homeDetails?.bedroomCount,
                area: basicInfo?.area,
                price: basicInfo?.price,
                type: basicInfo?.tag,
                status: PROPERTIES_CONSTANTS.PROPERTY_STATUSES.AVAILABLE,
                rating,
                description: basicInfo?.description,
                location: {
                    city,
                    neighborhood,
                    state,
                    streetAddress,
                    zipCode
                },
                basicInfo,
                homeValue,
                homeDetails,
                neighbourHoodDetails,
                miscData,
            }
            const data = await API.admin.listPropertyWithAPI(payload)
            if (extraImages?.length) {
                const formData = new FormData()
                formData.append('id', data._id)
                extraImages.forEach(image => {
                    formData.append('pictures', image.file)
                })
                await API.admin.attachImages(formData)
            }
            setResponse(null)
            setSubmitting(false)
            setQuery("")
            if (otherPropertyAddress) {
                setOtherPropertyAddress(null)
            }
        }
        catch (e) {
            setSubmitting(false)
        }
    }

    const isLoading = useDebouncedLoader(searching || submitting)


    return (
        <div>
            <BlockingLoader loading={isLoading} text={searching ? "Searching" : submitting ? "Submitting" : null} />
            <div className='flex flex-1'>
                {/* <input className='h-[62px] pl-[70px] pr-2 w-full outline-none border-none text-base text-_25253C placeholder:text-E8EBEC'
                        placeholder='Search Property'
                        value={query}
                        autoFocus
                        onChange={({ target }) => setQuery(target.value)}
                    /> */}
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_KEY}
                    debounce={500}
                    minLengthAutocomplete={4}
                    onLoadFailed={(error) => (
                        toast.error("Could not inject Google script. Please reload the page", error)
                    )}
                    selectProps={{
                        isClearable: true,
                        placeholder: "Type in an address",
                        query,
                        onChange: setQuery,
                        components: {
                            IndicatorSeparator: null,
                            DropdownIndicator: () => <Search />
                        },
                        classNames: {
                            container: () => `!w-full !pr-5`,
                            control: state => '!border-none !outline-none !shadow-none !rounded-[999px] h-[62px] !w-full pl-[30px] pr-7 w-full outline-none border-none text-base text-_25253C placeholder:text-E8EBEC'
                        }
                    }}
                />
            </div>
            <div className='mt-5'>
                {response &&
                    <APISearchResult
                        result={response}
                        onList={onList}
                        onOtherPropertyClick={address => {
                            if (otherPropertyAddress) {
                                setOtherPropertyAddress('')
                            }
                            setOtherPropertyAddress(address)
                        }}
                    />
                }
            </div>
            <OtherPropertySearch
                address={otherPropertyAddress}
                onClose={() => setOtherPropertyAddress(null)}
                onList={onList}
                onOtherPropertyClick={address => {
                    if (otherPropertyAddress) {
                        setOtherPropertyAddress('')
                    }
                    setOtherPropertyAddress(address)
                }}
            />
        </div>
    )
}

export default ListUsingAPI