import React, { useMemo } from 'react'
import clsx from 'classnames'
import { ReactComponent as Bathroom } from 'modules/properties/assets/icons/bathroom.svg'
import { ReactComponent as Bedroom } from 'modules/properties/assets/icons/bedroom.svg'
import { ReactComponent as Area } from 'modules/properties/assets/icons/area.svg'
import { ReactComponent as Location } from 'modules/properties/assets/icons/location.svg'
import { CommaSeperatedNumber } from 'modules/common/components'
import { parseAddress, pluralize } from 'modules/common/utils/functions'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'
import { getEditedImageUrl } from '../utils/functions'

const PropertyStatus = ({ status }) => (
  <button className={clsx("px-[5.7px] py-[1.43px] md:px-[10.4px] md:py-[2.6px] text-[9.98px] font-bold leading-[11.4px] md:text-[18.2px] md:leading-[20.8px]", {
    "bg-[#41F153] text-[#085610]": status === "Available",
    "text-[#6C0C0C] bg-[#FF0202]": status === "Sold",
    "text-white bg-[purple]": status === "Pass",
    "text-[#6C0C0C] bg-FEDF18": status === "Pending",
  })}>
    {status}
  </button>
)


const Property = ({ images, address, bathroomCount, bedroomCount, area, price, propertyType, status, id }) => {
  const navigate = useNavigate()
  const displayableImage = useMemo(() => {
    if (images?.length > 0) {
      return images[0]?.url
    }
  }, [images])

  const goToDetail = () => navigate(CONSTANTS.routes.propertyDetail(id))

  return (
    <div role='presentation' onClick={goToDetail} className='cursor-pointer min-h-[110.72px] 390:min-h-[271px] 390:space-x-0 md:min-h-[202px] rounded-[5.48px] md:rounded-10 border-[0.55px] md:border border-E8EBEC bg-white p-[9.32px] 390:pt-[11px] 390:px-[11px] md:p-[17px] pb-[9.82px] md:pb-[18.8px] flex flex-col  space-x-[10.96px] 1436:space-x-5 390:min-w-[167px] 1436:w-[644px]'>
      <div className='w-full mb-4 390:w-full 390:rounded-10 390:h-[200px] 1436:w-full h-[220px] md:h-[250px] flex-shrink-0 rounded-[5.48px] overflow-hidden bg-black relative'>
        {displayableImage &&
          <img className='absolute top-0 left-0 h-full w-full object-cover' src={getEditedImageUrl(displayableImage)}
            alt={propertyType}
          />}
      </div>
      <div className='max-w-[257.63px] md:max-w-full flex-1 436:hidden 1436:block'>
        <p className='font-bold text-base md:text-[18px]  text-_25253C mb-[3.89px] md:mb-[9px]'>{parseAddress(address)}</p>
        <div className='flex flex-col space-y-4 max-w-[400px] 1436:flex-row 1436:space-y-0 1436:justify-between 1436:items-center'>
          <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
            <Bedroom className='md:h-[20.69px] md:w-[20.69px]' />
            <span className='text-sm text-_25253C md:text-sm md:leading-4'>{bedroomCount || "-"} {pluralize(bedroomCount, "Bedroom")}</span>
          </div>
          <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
            <Bathroom className='md:h-[20.69px] md:w-[20.69px]' />
            <span className='text-sm text-_25253C md:text-sm md:leading-4'>{bathroomCount || "-"} {pluralize(bathroomCount, "Bathroom")}</span>
          </div>
          <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
            <Area className='md:h-[20.69px] md:w-[20.69px]' />
            {area ? <CommaSeperatedNumber
              number={area}
              className='text-sm leading-4 text-_25253C'
              suffix='ft²'
            /> : "-"}
          </div>
        </div>
        <div className='mt-[3.72px] md:mt-[7px] flex space-x-[4.36px] md:space-x-2 items-center'>
          <Location className='md:w-[22px] md:h-[22px]' />
          <span className='font-bold text-sm'>{address.state}</span>
        </div>
        <div className='mt-[3.81px] md:mt-[7px] flex flex-col space-y-4'>
          <CommaSeperatedNumber
            number={price}
            className='text-_25253C text-base md:text-2xl md:leading-[34px] mr-8 flex-shrink-0 font-bold'
            prefix='$'
          />
          <p className='text-_25253C text-base md:text-base md:leading-4'><span className='font-bold'>Property Type:</span> {propertyType}</p>
        </div>
        <div className='mt-[4.23px] md:mt-2 flex items-center justify-between'>
          <div />
          <PropertyStatus status={status} />
        </div>
      </div>
      <div className='hidden 436:block 1436:hidden pt-[13px]'>
        <CommaSeperatedNumber className={"text-_25253C text-sm font-bold mb-[3px]"} number={price} dollarSign />
        <p className='text-_25253C text-base font-bold mb-[10px]'>{parseAddress(address)}</p>
        <div className='flex flex-col space-y-[10.49px]'>
          <div className='flex items-center space-x-[14.8px]'>
            <div className='flex items-center space-x-[5.65px] flex-1'>
              <Bedroom className='h-[11.34px] w-[11.34px]' />
              <span className='text-sm text-_25253C'>{bedroomCount || "-"} {pluralize(bedroomCount, "Bedroom")}</span>
            </div>
            <div className='flex items-center space-x-[5.65px] flex-1'>
              <Bathroom className='h-[11.34px] w-[11.34px]' />
              <span className='text-sm text-_25253C'>{bathroomCount || "-"} {pluralize(bathroomCount, "Bathroom")}</span>
            </div>

          </div>
          <div className='flex items-center space-x-[14.8px]'>
            <div className='flex items-center space-x-[5.65px] flex-1'>
              <Area className='h-[11.34px] w-[11.34px]' />
              {area ? <CommaSeperatedNumber
                number={area}
                className='text-sm leading-4 text-_25253C'
                suffix='ft²'
              /> : "-"}
            </div>
            <div className='flex items-center space-x-[5.65px] flex-1'>
              <Location className='h-[12.06px] w-[12.06px]' />
              <span className='text-sm text-_25253C'>{address?.state}</span>
            </div>
          </div>
        </div>
        <div className='mt-[11.66px]'>
          <p className='text-_25253C text-base'><span className='font-bold'>Property Type:</span> {propertyType}</p>
        </div>
        <div className='mt-[11px] flex'>
          <div className='ml-auto'>
            <PropertyStatus status={status} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Property