import classNames from "classnames";
import { Button } from "modules/common/components";
import React, { useMemo } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import API from "api";

const StepButtons = ({ currentPage, onPrev, onProceed, onSubmit, isLastPage = false, rtoFormDetails }) => {
    const formData = useMemo(() => {
        const formData = new FormData();
        // Append data from rtoFormDetails to formData
        if (rtoFormDetails) {
            for (const section in rtoFormDetails) {
                const sectionData = rtoFormDetails[section];
                if (sectionData) {
                    for (const key in sectionData) {
                        formData.append(`${section}[${key}]`, sectionData[key]);
                    }
                }
            }
        }
        return formData;
    }, [rtoFormDetails]);
    
    const confirmSubmit = () => {
        withReactContent(Swal)
            .fire({
                title: "Are you sure you want to submit?",
                text: "You won't be able to edit this form after submitting. If you still want to edit this submission in the future, click on 'Save' instead",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#25253C",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, submit",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    onSubmit();
                    sendEmailNotification(formData);
                }
            });
    };

    const sendEmailNotification = async (formData) => {
        const emailContent = generateHtmlFromFormData(formData);

        const emailData = {
            to: ["jack.crenshaw@frontierstreet.us"],
            cc:["Modernreig@gmail.com","donnyrcook@gmail.com" ],
            subject: "New RTOW FORM Submitted",
            text: "An RTOW FORM has been submitted successfully.",
            html: `<p>An RTOW FORM has been submitted successfully.</p>${emailContent}`,
        };

        try {
            const response = await API.admin.sendEmail(emailData);
            console.log("Email sent successfully:", response);
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    const generateHtmlFromFormData = (formData) => {
        const entries = Array.from(formData.entries());
        const excludeFields = ['_id', 'user', 'createdAt', 'updatedAt'];
        
        return `
            <ul>
                ${entries
                    .filter(([key]) => !excludeFields.some(field => key.includes(field)))
                    .map(([key, value]) => `<li style="margin-bottom:12px"><strong>${key}:</strong> ${value}</li>`)
                    .join('')}
            </ul>
        `;
    };

    return (
        <div className="flex mx-auto md:space-x-5 mt-5 md:max-w-[950px] justify-end pr-3 relative">
            {currentPage > 0 && (
                <button
                    onClick={onPrev}
                    className="z-10 absolute left-3 border cursor-pointer border-[#002248] shadow px-5 h-[60px] py-2 rounded-[10px] "
                >
                    Previous step
                </button>
            )}
            <div className="flex space-x-2">
                <Button
                    text={isLastPage ? "Save" : currentPage < 5 ? "Next step" : "Submit"}
                    onClick={onProceed}
                    bgColor="bg-[#14142b]"
                    textClassNames={classNames(
                        "lg:!text-[20px]",
                        { "!text-[#fff]": !isLastPage },
                        { "!text-[#002248]": isLastPage }
                    )}
                    classNames={classNames("lg:!py-4 h-[60px] font-[400] lg:!px-[20px]", {
                        "!bg-white !border !border-[#002248]": isLastPage,
                    })}
                />
                {onSubmit && isLastPage ? (
                    <Button
                        text={"Submit"}
                        onClick={confirmSubmit}
                        bgColor="bg-[#14142b]"
                        textClassNames={"lg:!text-[20px] !text-[#fff] "}
                        classNames={"lg:!py-4 h-[60px] font-[400] lg:!px-[20px]"}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default StepButtons;
