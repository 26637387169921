const PROPERTIES_CONSTANTS = {
    filterKeys: {
        showPopup: "showPopup",
        minPrice: "minPrice",
        maxPrice: "maxPrice",
        bathroomCount: "bathroomCount",
        bedroomCount: "bedroomCount",
        minArea: "minArea",
        maxArea: "maxArea",
        lastAppliedFilters: "lastAppliedFilters",
    },
    FILTER_TYPES: {
        price: "PRICE",
        availability: "AVAILABILITY",
        bathrooms: "BATHROOMS",
        bedrooms: "BEDROOMS",
        area: "AREA",
        type: "TYPE"
    },
    PROPERTY_STATUSES: {
        PASS: "Pass",
        PENDING: "Pending",
        AVAILABLE: "Available",
        SOLD: "Sold"
    },
}

export default PROPERTIES_CONSTANTS