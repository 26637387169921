import React from 'react'
import clsx from 'classnames'
import AdminTopNav from './AdminTopNav'
import AdminSideBar from './AdminSideBar'
import { Outlet } from 'react-router-dom'

const AdminNavigationWrapper = ({ containerClassName, sideBar = true }) => {

    return (
        <div>
            <AdminTopNav />
            {sideBar && <AdminSideBar />}
            <div className={clsx('min-h-screen md:pt-[74px] px-5 bg-F6F7FB', containerClassName, { "1010:pl-[270px]": sideBar })}>
                <div className='max-w-[1440px] mx-auto'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AdminNavigationWrapper