import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { BlockingLoader, Button } from 'modules/common/components'
import landingVideo from 'modules/home/assets/videos/landing-video.mp4'
import landingBg from 'modules/home/assets/images/landing-bg.png'
import housesSmall from 'modules/home/assets/images/houses-small.png'
import housesLg from 'modules/home/assets/images/houses-lg.png'
import { ReactComponent as DiamondsMobile } from 'modules/home/assets/images/diamonds-mobile.svg'
import { ReactComponent as DiamondsLarge } from 'modules/home/assets/images/diamonds-lg.svg'
import { useWindowSize, BREAKPOINTS, useDebouncedLoader } from 'modules/common/hooks';
import CONSTANTS from 'modules/common/utils/constants';

const Hero = () => {
    const navigate = useNavigate()
    const { width = 0 } = useWindowSize()
    const [videoLoaded, setVideoLoaded] = useState(false)
    const isVideoLoading = useDebouncedLoader(!videoLoaded)

    return (
        <div className='relative h-screen px-5 bg-_25253C'>
            <BlockingLoader
                solid
                loading={isVideoLoading}
            />
            <img src={landingBg} className='absolute top-0 left-0 w-full h-full pointer-events-none' alt="Moving background" />
            <video autoPlay muted loop playsInline
                preload="auto"
                src={landingVideo}
                onLoadedData={() => setVideoLoaded(true)}
                onError={() => setVideoLoaded(true)}
                className='absolute top-0 left-0 object-cover w-full h-full pointer-events-none'
            />
            <div className='absolute w-full h-full left-0 pointer-events-none top-0 bg-black/[.6]' />
            <div className='pointer-events-none absolute w-full left-0 bottom-[-98.5px] lg:bottom-[-320.53px]'>
                <Marquee>
                    <img src={width > BREAKPOINTS.lg ? housesLg : housesSmall} alt='moving houses' />
                </Marquee>
            </div>
            <div className='bg-_25253C absolute bottom-[-98.5px] h-[98.5px] lg:h-[320.53px] lg:bottom-[-320.53px] left-0 w-full z-[-1]' />
            <DiamondsMobile className='absolute top-0 left-0 h-full pointer-events-none lg:hidden' />
            <DiamondsLarge className='absolute left-[-200px] pointer-events-none h-full w-[1440px] top-0 lg:block hidden scale-150' />
            <div className='relative z-[2] 1400:max-w-[1320px] 1400:mx-auto'>
                <h3 className='pt-[33vh] md:pt-[150px] md:ml-[50px] md:text-[60px] md:leading-[75px] 1400:ml-[104px] 950:pt-[150px] 950:ml-[164px] 950:text-[80px] 950:leading-[96px] text-[41.04px] leading-[49.24px] text-white font-black'>FRONTIER STREET<br />HAS HOUSES</h3>
                <div className='mt-[3.95vh] md:ml-[100px] md:mt-[18px] 950:ml-[254px] 1400:ml-[194px] flex space-x-[14.6px] md:space-x-5 items-center'>
                    <div className='w-[6.57px] h-[76.63px] bg-D50100 950:w-[9px] 950:h-[105px]' />
                    <div>
                        <p className='text-white text-[23.35px] leading-[28.03px] md:text-[27px] md:leading-[32px] 950:text-[32px] 950:leading-[38.4px]'>Sell Your House</p>
                        <p className='text-white text-[23.35px] leading-[28.03px] md:text-[27px] md:leading-[32px] 950:text-[32px] 950:leading-[38.4px]'>Fast In The</p>
                        <p className='text-white text-[23.35px] leading-[28.03px] md:text-[27px] md:leading-[32px] 950:text-[32px] 950:leading-[38.4px]'>Greater Indianapolis Areas</p>
                    </div>
                </div>
                <div className='mt-[2.6vh] md:mt-10 md:ml-[100px] 950:mt-[75px] 950:ml-[260px] 1400:ml-[194px] relative z-[2]'>
                    <Button text={"Get your Cash Offer"}
                        onClick={() => navigate(CONSTANTS.routes.sellYourHouse)}
                        classNames={"950:!rounded-[15.35px] 950:!py-[18.42px] 950:!px-[36.84px] md:!py-[15px] md:!px-[30px]"}
                        textClassNames={"950:!text-[24.56px]"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Hero