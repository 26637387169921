import React, { useState } from 'react'
import { ListManually, ListUsingAPI } from '../components'


const AddListing = () => {
    const [listWithAPI, setListWithAPI] = useState(true)
    return (
        <div className='pt-5 1010:pt-[66px] 1010:py-[47px]  1010:pl-[66px]'>
            <label className="inline-flex items-center cursor-pointer mb-7">
                <span className="mr-3 text-sm font-medium text-gray-400 dark:text-gray-500">List manually</span>
                <div className="relative">
                    <input type="checkbox" checked={listWithAPI} className="sr-only peer" onChange={({ target: { checked } }) => setListWithAPI(checked)} />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-FEDF18"></div>
                </div>
                <span className="ml-3 text-sm font-medium text-gray-400 dark:text-gray-500">List using API</span>
            </label>
            {listWithAPI ? <ListUsingAPI /> : <ListManually />}
        </div>
    )
}

export default AddListing