import React from 'react'
import clsx from 'classnames'

const CarouselIndicator = ({ activeColor, inActiveColor, activeIndex, total }) => {
    const bubblesToRender = Array(total).fill(0)
    return (
        <div className='flex items-center justify-center space-x-[10px] h-[14px]'>
            {bubblesToRender.map((bubble, index) => {
                const isActiveBubble = activeIndex === index
                return (
                    <div key={index} className={clsx('transition-all h-2 w-2 rounded-full', {
                        "h-[14px] w-[14px]": isActiveBubble
                    })} style={{ backgroundColor: isActiveBubble ? activeColor : inActiveColor }} />
                )
            })}
        </div>
    )
}

export default CarouselIndicator