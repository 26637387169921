import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const MessageSkeleton = () => {
    return (
        <Skeleton
            className='h-[50px]  rounded-10 flex-shrink-0'
        />
    )
}

export default MessageSkeleton