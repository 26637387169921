import API from "api";
import { useAuth } from "modules/common/hooks";
import CONSTANTS, { initialRTOFormState } from "modules/common/utils/constants";
import { removeFromLS, storeToLS } from "modules/common/utils/functions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const formSteps = {
	contactDetails: "contactDetails",
	importantDetails:"importantDetails",
	propertyDetails: "propertyDetails",
	mortgageDetails: "mortgageDetails",
	purchaseRelatedDetails: "purchaseRelatedDetails",
	leasePurchaseDetails: "leasePurchaseDetails",
};

const useRtoForm = ({ setter, step }) => {
	const params = useParams();
	const formId = params.formId;
	const { rtoFormDetails, setRtoFormDetails, setUpdatingRtoForm } = useAuth();

	const clearFormState = () => {
		removeFromLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA);
		setRtoFormDetails({ ...initialRTOFormState });
	};

	// get the prev data from the context and set it
	useEffect(() => {
		const data = rtoFormDetails[step];
		setter(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setter, step, rtoFormDetails]);

	const isEmpty = (value) => {
		if (typeof value === "string") {
			if (value.trim().length === 0) {
				return true;
			}
			if (value === "undefined") {
				return true;
			}
			return false;
		}
		if (value === "undefined") {
			return true;
		}
		return false;
	};

	//   store the form details to localstorage and to the context
	const storeFormDetails = async (data, media, mediaName) => {
		const newData = structuredClone(rtoFormDetails);
		newData[step] = data;
		if (formId) {
			try {
				setUpdatingRtoForm(true);
				const formData = new FormData();
				const excluded = ["_id", "submit", "documents", "pictures"];
				Object.keys(data).forEach((key) => {
					if (!excluded.includes(key)) {
						const value = data[key];
						if (!isEmpty(value)) {
							return formData.append(`${step}[${key}]`, data[key]);
						}
					}
				});
				if (media && mediaName) {
					media.forEach((item) => {
						formData.append(mediaName, item);
					});
				}
				if ("submit" in data) {
					const shouldSubmit = data["submit"];
					if (shouldSubmit) {
						formData.append("submit", true);
					}
				}
				const response = await API.updateRTOSubmission(formId, formData);
				storeToLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA, response);
				setRtoFormDetails(response);
				setUpdatingRtoForm(false);
				return true;
			} catch (e) {
				setUpdatingRtoForm(false);
			}
		} else {
			storeToLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA, newData);
			setRtoFormDetails(newData);
			return true;
		}
	};

	return {
		storeFormDetails,
		rtoFormDetails,
		clearFormState,
		submissionId: formId,
		isEmpty,
		documents: rtoFormDetails?.leasePurchaseDetails?.relevantDocuments || [],
		pictures: rtoFormDetails?.propertyDetails?.propertyPictures || [],
	};
};

export default useRtoForm;
