import React from 'react'
import ReactSelect from 'react-select';

const customClassNames = (hasError) => ({
    control: (state) =>
        `!h-[39px] !rounded-10 lg:h-[59.98px] text-[9.41px] lg:text-base leading-[14.12px] lg:leading-6 w-full border-none outline-none rounded-[7.93px] lg:rounded-[13.48px] px-[12.69px] lg:px-[21.57px] bg-white placeholder:!text-E8EBEC ${state.isFocused ? '!outline-none !border-E8EBEC !shadow-none' : ''
        } ${hasError ? '!border-[red]' : '!border-E8EBEC'}`,
    option: (state) =>
        state.data === state.selectProps.value
            ? '!text-[#1e1e1e] !text-12 font-semibold !bg-[#EFEFEF]'
            : 'hover:!bg-[#EFEFEF] !text-12 !text-[#1e1e1e]',
    placeholder: (state) => `!text-E8EBEC italic`,
    valueContainer: state => '!px-0'
});


const Select = ({ hasError, className, ref_, ...rest }) => {
    return (
        <ReactSelect
            ref={ref_}
            classNames={{
                ...customClassNames(!!hasError),
            }}
            components={{
                IndicatorSeparator: null,
            }}
            {...rest}
            value={!!rest?.value?.value ? rest?.value : null}
        />
    )
}
// text-[9.41px] lg:text-base
export default Select