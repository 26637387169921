import CONSTANTS from "modules/common/utils/constants";
import { useCallback, useReducer, useState } from "react";
import PROPERTIES_CONSTANTS from "../utils/constants";
import { isEmpty } from "lodash";


const { filterKeys } = PROPERTIES_CONSTANTS

const initialState = {
    [filterKeys.showPopup]: false,
    [filterKeys.minPrice]: "",
    [filterKeys.maxPrice]: "",
    [filterKeys.bathroomCount]: CONSTANTS.ANY,
    [filterKeys.bedroomCount]: CONSTANTS.ANY,
    [filterKeys.minArea]: "",
    [filterKeys.maxArea]: "",
}

function filterReducer(state, action) {
    if (action.type in filterKeys) {
        if (!state?.lastAppliedFilters) {
            return { ...state, lastAppliedFilters: state, [action.type]: action.value }
        }
        return { ...state, [action.type]: action.value }
    }
    else if (action.type === "set_all") {
        return { ...action.value }
    }
    else if (action.type === "clear_all") {
        return { ...initialState, showPopup: true }
    }
    else {
        return state
    }
}


/**
 * @param {function} allPropertiesFetcher - an API call that fetches all the properties without using any filters
 * @param {function} filteredPropertiesFetcher - an API call that fetches properties using a set of filters
 * @returns object
 */
const usePropertyFilters = ({ allPropertiesFetcher, filteredPropertiesFetcher }) => {
    const [applyingFilters, setApplyingFilters] = useState(false)
    const [metaData, setMetadata] = useState(null)
    const [loading, setLoading] = useState(true)
    const [properties, setProperties] = useState([])
    const [state, dispatch] = useReducer(filterReducer, {
        ...initialState
    });

    const hideLoader = () => {
        setLoading(false)
    }


    const getProperties = useCallback(async (callback, page = 1, replace = true) => {
        try {
            const { data, metadata } = await allPropertiesFetcher(page)
            setProperties(properties => replace ? data : [...properties, ...data])
            setMetadata(metadata)
            callback && callback()
            hideLoader()
            window.scroll(0, 0)
        }
        catch (e) {
            hideLoader()
        }
    }, [allPropertiesFetcher])


    const extractFilters = (query) => {
        let queryObject = {}
        const queryKeys = Object.keys(query).filter(key => !!query[key] && query[key] !== CONSTANTS.ANY && key !== PROPERTIES_CONSTANTS.filterKeys.showPopup)
        queryKeys.map(key => {
            return queryObject[key] = query[key]
        })
        return queryObject
    }

    const searchProperties = async (query, onSuccess, page = 1, replace = true) => {
        setApplyingFilters(true)
        const queryObject = extractFilters(query)
        if (isEmpty(queryObject)) {
            getProperties(() => {
                onSuccess && onSuccess()
                setApplyingFilters(false)
            }, page, replace)
            return
        }
        try {
            const { data, metadata } = await filteredPropertiesFetcher(queryObject, page)
            setProperties(properties => replace ? data : [...properties, ...data])
            setMetadata(metadata)
            onSuccess && onSuccess()
            setApplyingFilters(false)
            window.scroll(0, 0)
        }
        catch (e) {
            setApplyingFilters(false)
        }
    }

    return {
        searchProperties,
        getProperties,
        applyingFilters,
        loading,
        properties,
        state,
        dispatch,
        metaData,
        setProperties
    }
}

export default usePropertyFilters