import React from 'react'
import FormImg from "../assests/images/form.png"
import CONSTANTS, { initialRTOFormState } from "modules/common/utils/constants";
import { useAuth } from "modules/common/hooks";
import { removeFromLS } from "modules/common/utils/functions";
import { useNavigate } from "react-router-dom";

const { routes } = CONSTANTS;

const FormListHead = ({total}) => {
const { setRtoFormDetails } = useAuth();
const navigate = useNavigate();

  return (
     <div className=' mx-auto bg-white rounded-[30px] shadow max-w-[1000px] mt-[150px]'>
        <div className='flex flex-col md:flex-row gap-5  justify-between items-center px-5 py-5 '>
          <div className='flex justify-between items-center space-x-4 '>
             <img src={FormImg} alt='formimg'  width="40px" height="40px"/>
             <div className=''>
                <p className='font-light text-[14px]'>Total Form</p>
                <p className='font-bold text-[20px] md:text-[32px]'>{total}</p>
                {/* <p className='font-light text-[14px]'>16% this month</p> */}
             </div>
           </div>
          <button
            onClick={() => {
							setRtoFormDetails({ ...initialRTOFormState });
							removeFromLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA);
							navigate(routes.rtoFormHome);
						}}
            className='border-[#E8EBEC] bg-white border rounded-[10px] h-[60px] py-3 px-5 flex items-center space-x-[9px]'>
            <span className=' text-[14px] md:text-[16px] leading-[18px] font-bold text-_25253C'>Fill another form</span>
            <div className='h-[27px] w-[27px] rounded-full flex items-center justify-center bg-_25253C'>
              <span className='font-bold text-[21px] leading-6 text-white'>+</span>
             </div>
            </button>
          

        </div>

    </div>
  )
}


export default FormListHead