import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PropertySkeleton = () => {
    return (
        <div className='min-h-[110.72px] md:min-h-[202px] flex flex-col space-x-[10.96px] md:space-x-5 393:w-[353px] md:w-[644px]'>
            <Skeleton
                className='!w-full  !h-[200px] md:!h-[250px] flex-shrink-0 rounded-[5.48px] md:rounded-10'
            />
            <div className='max-w-[257.63px] md:!max-w-full'>
                <Skeleton className='!h-9.85px] md:!h-[18px] mb-[3.89px] md:mb-[9px]' />
                <div className='flex space-x-[34.99px] items-center md:space-x-[64px]'>
                    <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
                        <Skeleton height={10} width={10} />
                        <Skeleton className='!h-2 md:!h-[14px] !w-[40px]' />
                    </div>
                    <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
                        <Skeleton height={10} width={10} />
                        <Skeleton className='!h-2 md:!h-[14px] !w-[40px]' />
                    </div>
                    <div className='flex items-center space-x-[5.62px] md:space-x-[10.31px]'>
                        <Skeleton height={10} width={10} />
                        <Skeleton className='!h-2 md:!h-[14px] !w-[40px]' />
                    </div>
                </div>
                <div className='mt-[3.72px] md:mt-[7px] flex space-x-[4.36px] md:space-x-2 items-center'>
                    <Skeleton height={10} width={10} />
                    <Skeleton className='!h-2 md:!h-[14px] !w-[100px]' />
                </div>
                <div className='mt-[3.81px] md:mt-[7px] flex justify-between items-center'>
                    <Skeleton className='!h-[13.13px] !md:h-[24px]' width={100} />
                    <Skeleton className='!h-2 md:!h-[16px] !w-[100px]' />
                </div>
                <div className='mt-[4.23px] md:mt-2 flex items-center justify-between'>
                    <div />
                    <Skeleton className='!w-[54.4px] !h-[14.85px] md:!w-[57.8px] md:!h-[26.2px]' />
                </div>
            </div>
        </div>
    )
}

export default PropertySkeleton