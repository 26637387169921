import React, { useState } from "react";
import clsx from "classnames";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "modules/common/utils/constants";
import API from "api";

const RtoForm = ({ address, submittedBy, phone, email, date, id, status }) => {
  const [currentStatus, setCurrentStatus] = useState(status || "Active"); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    setCurrentStatus(newStatus);
    setLoading(true);

    try {
      const response = await API.updateRTOFormStatus(id, { status: newStatus });
      console.log("Status updated successfully", response);
    } catch (error) {
      console.error("Failed to update status", error.response ? error.response.data : error.message);
      alert(`Failed to update status: ${error.response?.data?.message || error.message}`);
      setCurrentStatus(status); // Revert to previous status on error
    } finally {
      setLoading(false);
    }
  };

  const goToDetail = () => {
    const route = "/admin/" + CONSTANTS.routes.admin.rtoFormDetail(id);
    navigate(route);
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-6 border-b border-stroke">
      <div
        className="flex items-center gap-3 p-2.5 xl:p-5 cursor-pointer"
        onClick={goToDetail}
      >
        <p className="text-black text-[14px] md:text-[16px] sm:block">
          {address}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black text-[14px] md:text-[16px]">{submittedBy}</p>
      </div>

      <div className="hidden sm:flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-[14px] md:text-[16px]">{phone}</p>
      </div>

      <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
        <p className="text-black md:text-[16px]">{email}</p>
      </div>

      <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
        <p className="md:text-[16px]">{date}</p>
      </div>

      <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
        <select
          className={clsx(
            "px-[5.7px] py-[1.43px] md:px-[10.4px] md:py-[2.6px] text-[9.98px] font-bold leading-[11.4px] md:text-[18.2px] md:leading-[20.8px]",
            {
              "bg-[#41F153] text-[#085610]": currentStatus === "Active",
              "text-[#6C0C0C] bg-[#FF0202]": currentStatus === "Done",
              "text-white bg-[purple]": currentStatus === "Pass",
              "text-[#6C0C0C] bg-[#FEDF18]": currentStatus === "Pending",
              "text-[#ffffff] bg-[#181503]": currentStatus === "Sold",
              "text-[#fdfdfd] bg-[#7b1cd5]": currentStatus === "Bought",

            }
          )}
          value={currentStatus || "Active"}
          onChange={handleChange}
          disabled={loading}
        >
          <option value="Done">Done</option>
          <option value="Active">Active</option>
          <option value="Pass">Pass</option>
          <option value="Pending">Pending</option>
          <option value="Sold">Sold</option>
          <option value="Bought">Bought</option>

        </select>
      </div>
    </div>
  );
};

export default RtoForm;
