import React from 'react'
import SectionHeader from './SectionHeader'
import { Button } from 'modules/common/components'
import FadeIn from './FadeIn'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'

const CashOfferInfo = () => {
    const navigate = useNavigate()
    return (
        <div className='bg-white xl:px-[172px]'>
            <FadeIn>
                <div className=' px-5 pt-[61px] pb-[94px] max-w-[1096px] xl:pt-[164px]  xl:mx-auto xl:pb-[143px] xl:flex xl:space-x-[211px]'>
                    <div className='flex items-center justify-center xl:items-start xl:justify-start'>
                        <SectionHeader
                            text={"Who We Help"}
                            className={"!text-_25253C"}
                        />
                    </div>
                    <div>
                        <div className='mt-[27px] xl:mt-0'>
                            <p className='text-black text-base leading-6'>
                                If you want to sell your house, but don't want to deal with agents, fees, commissions, showings, being listed on the MLS,
                                or waiting months to close... then click the button below now.
                            </p>
                            <br />
                            <p className='text-black text-[20px] leading-6 font-bold'>
                                "We will give a fair cash offer for your home in its as-is condition and close quickly."
                                <span className='mt-3 block'>Leave the rest.</span>
                            </p>
                        </div>
                        <div className='mt-[27px] xl:mt-[19px] flex items-center justify-center xl:items-start xl:justify-start'>
                            <Button text={"Get your Cash Offer"}
                                onClick={() => navigate(CONSTANTS.routes.sellYourHouse)}
                            />
                        </div>
                    </div>
                </div>
            </FadeIn>
        </div>
    )
}

export default CashOfferInfo