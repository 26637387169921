import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion';
import whyUsBg from 'modules/home/assets/images/why-us-bg.png'
import SectionHeader from './SectionHeader'
import CarouselIndicator from './CarouselIndicator'
import { useHorizontalScroll } from 'modules/home/hooks'
import { useInView } from 'react-intersection-observer';

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
};

const reasons = [
    {
        header: "Provides a Quick Solution:",
        description: `
        We will buy your house within a few days, in most cases only 3-7 days;
        giving you immediate cash for your property. This is an excellent option for those who need
        to sell their home quickly due to financial difficulties, foreclosure, or other personal reasons
        `
    },
    {
        header: "No Obligation or Commissions:",
        description: `
        When working with a real estate agent, you'll typically have to pay commissions and other fees.
        However, in most cases, Frontier Street investors won't charge any commissions, so you'll receive the agreed-upon price for your home.
        `
    },
    {
        header: "No Need for Repairs",
        description: `
        Frontier Street will purchase your property as it is, even if it has extensive damage or requires significant repairs.
        This will save you the time and money that would have otherwise been spent on upgrading and renovating your home
        `
    },
    {
        header: "Simple and Convenient",
        description: `
        Working with Frontier Street is hassle-free and straight forward.
        You'll usually receive an offer within 24-48 hours and can close the deal in as little as a week, depending on the terms and conditions.
        `
    }
]

const WhyUs = () => {
    const [framerContainerRef, inView] = useInView({
        rootMargin: '-200px 0px',
        triggerOnce: true
    });
    const containerControls = useAnimation();
    useEffect(() => {
        if (inView) {
            containerControls.start('visible');
        }
        else {
            containerControls.start("hidden")
        }
    }, [inView, containerControls]);

    const { containerRef, activeItemIndex } = useHorizontalScroll(282)
    return (
        <div className='px-5 pt-[64px] pb-[99px] bg-cover bg-center bg-no-repeat lg:px-[81px] lg:pt-[65px] lg:pb-[77px]' style={{ backgroundImage: `url(${whyUsBg})` }}>
            <div className='bg-white/[.9] rounded-10 pt-[35px] pb-[49px] lg:pt-[42.36px] lg:pb-[68px] lg:px-[84px] 1400:max-w-[1277px] 1400:mx-auto'>
                <div className='flex flex-col space-y-[26.36px] lg:space-y-6 items-center justify-center'>
                    <SectionHeader text={"Why Us"} className={"!text-_25253C"} />
                    <p className='leading-base text-sm text-_25253C px-6 lg:px-0'>
                        <span className='font-bold'>"We buy houses"</span> is a phrase often used by real estate investors like Frontier Street, that purchase homes for cash.
                        As investors, we typically buy properties that are distressed, inherited, or in a state of disrepair.
                        If you are considering selling your home or know someone who wants to sell quickly, working with a "we buy houses" company like Frontier Street, can be a viable option.
                        Here are some benefits of working with Frontier Street:
                    </p>
                </div>
                <motion.div ref={framerContainerRef}>
                    <motion.div
                        className='flex space-x-[37px] overflow-x-auto whitespace-no-wrap mt-[63px] px-6 no-scrollbar scroll-snap-container lg:px-0 lg:mt-[34px]'
                        variants={containerVariants}
                        initial="hidden"
                        ref={containerRef}
                        animate={containerControls}
                    >
                        {reasons.map((reason, index) => (
                            <motion.div
                                className='rounded-10 w-[245px] bg-_25253C py-[111px] px-7 pb-4 flex-shrink-0 flex flex-col space-y-[26px] card'
                                key={index}
                                variants={itemVariants}
                            >
                                <h4 className='font-bold text-base leading-[18px] text-white'>{reason.header}</h4>
                                <p className='text-sm leading-4 text-white'>{reason.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </motion.div>
                <div className='pt-[31px] px-6 lg:hidden'>
                    <CarouselIndicator
                        total={reasons.length}
                        activeIndex={activeItemIndex}
                        activeColor={"#25253C"}
                        inActiveColor={"#848484"}
                    />
                </div>
                <div className='mt-[47px] px-6 lg:mt-[34px] lg:px-0'>
                    <p className='text-_25253C text-sm leading-base'>
                        In conclusion, working with Frontier Street is an excellent option for those in need of a quick, expedient sale of their property.
                        Before making any decisions, contact Frontier Street to ensure you get the best deal possible.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WhyUs