import React from "react";
import { ReactComponent as Contact } from "modules/common/assets/icons/contact.svg";
import { ReactComponent as Email } from "modules/common/assets/icons/email.svg";
import { ReactComponent as Phone } from "modules/common/assets/icons/phone.svg";
import { ReactComponent as House } from "modules/common/assets/icons/house.svg";

const ContactDetails = ({ detail }) => {
  const data = detail.contactDetails
  return (
    <div className="1400:mx-auto 1400:max-w-[1320px] ">
      <div className="shadow p-[16px] md:p-10 rounded-10">
        <h2 className="mt-[29px] text-[24px]  text-_25253C font-[600] leading-[34px] mb-4 md:mb-0 1436:text-[30px] 1436:leading-[52.8px]">
          Contact Details
        </h2>
        <div className="flex flex-col md:flex-row items-start md:space-x-12">
          <div className="flex flex-col flex-1">
            <h2 className="text-[16px] md:text-[18px] mb-2 text-_25253C font-[600] leading-[24px]  1436:text-[20px] 1436:leading-[52.8px]">
               Primary contact name of home owner
            </h2>
            <div className="flex space-x-3  items-center pb-2 border-b border-b-[#EEEEEE]">
              <Contact />
              <p className="text-_25253C text-base leading-6 1436:text-[18px] 1436:leading-[34px]">
                {data?.name}
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <h2 className="text-[18px] text-_25253C mb-2 font-[600] leading-[34px] 1436:text-[20px] 1436:leading-[52.8px]">
            Primary contacts primary email
            </h2>
            <div className="flex space-x-3  items-center pb-2 border-b border-b-[#EEEEEE]">
              <Email />
              <p className="text-_25253C text-base leading-6 1436:text-[18px] 1436:leading-[34px]">
                {data?.email}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:space-x-12 md:mt-6">
          <div className="flex flex-col flex-1">
            <h2 className="text-[18px] text-_25253C mb-2 font-[600] leading-[34px] 1436:text-[20px] 1436:leading-[52.8px]">
              Primary contacts phone number
            </h2>
            <div className="flex space-x-3  items-center pb-2 border-b border-b-[#EEEEEE]">
              <Phone />
              <p className="text-_25253C text-base leading-6 1436:text-[18px] 1436:leading-[34px]">
                {data?.phoneNumber}
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-1">
            <h2 className="text-[18px] text-_25253C mb-2 font-[600] leading-[34px] 1436:text-[20px] 1436:leading-[52.8px]">
              Full Property address
            </h2>
            <div className="flex space-x-3  items-center pb-2 border-b border-b-[#EEEEEE]">
              <House />
              <p className="text-_25253C text-base leading-6 1436:text-[18px] 1436:leading-[34px]">
                {data?.fullAddressOfProperty}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
