/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as Search } from "modules/common/assets/icons/search.svg";
import { useAuth, useData, useDebounce, useDebouncedLoader } from "modules/common/hooks";
import API from "api";
import { MessageSkeleton } from "modules/admin/components";
import { Button, NavigationWrapper, Pagination } from "modules/common/components";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CONSTANTS, { initialRTOFormState } from "modules/common/utils/constants";
import { removeFromLS } from "modules/common/utils/functions";

const { routes } = CONSTANTS;

const MyRtoFormSubmissions = () => {
	const { setRtoFormDetails } = useAuth();
	const [loading, setLoading] = useState(true);
	const [query, setQuery] = useState("");
	const { data, metadata, setValues } = useData();
	const isLoading = useDebouncedLoader(loading && !metadata?.nextPage);
	const debouncedQuery = useDebounce(query);
	const navigate = useNavigate();

	const columns = [
		{
			name: "Property",
			selector: (row) => row.contactDetails.fullAddressOfProperty || "-",
		},
		{
			name: "Last Updated",
			selector: (row) => moment(row.updatedAt).format("Do MMM YYYY, hh:mm A"),
		},
		{
			name: "Action",
			cell: (row) => (
				<button
					className="text-_25253C"
					onClick={() => {
						if (row.submittedAt) {
							navigate(routes.rtoFormDetail(row._id));
						} else {
							navigate(routes.rtoFormUpdate(row._id));
						}
					}}
				>
					{row.submittedAt ? "View" : "Edit"}
				</button>
			),
		},
	];

	const getRTOFormSubmissions = async (query, page = 1) => {
		setLoading(true);
		try {
			const result = await API.getRTOSubmissions(query, page);
			setValues(result, page === 1);
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (debouncedQuery?.trim()?.length) {
			getRTOFormSubmissions(debouncedQuery);
		} else {
			getRTOFormSubmissions();
		}
	}, [debouncedQuery]);

	return (
		<NavigationWrapper title={"My RTO/W Lease Form Submissions"} hasFooter={false}>
			<div className="bg-_25253C h-[83.07px] lg:hidden" />
			<div className="lg:max-w-[1200px] px-2 mx-auto lg:pl-9 pt-[75.27px] lg:pt-[188px]">
				<div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:justify-between lg:pr-12">
					<div className="h-12 lg:h-[62px] rounded-[50px] border border-E8EBEC bg-white relative lg:w-[451px] overflow-hidden">
						<Search className="hidden lg:block absolute left-[22px] top-[12px] h-[38px] w-[38px] pointer-events-none" />
						<input
							className="h-12 lg:h-[62px] pl-4 lg:pl-[70px] pr-2 w-full outline-none border-none text-base text-_25253C placeholder:text-E8EBEC"
							placeholder="Search..."
							value={query}
							onChange={({ target: { value } }) => setQuery(value)}
						/>
					</div>
					<Button
						classNames={"ml-auto"}
						text={"Add new submission"}
						onClick={() => {
							setRtoFormDetails({ ...initialRTOFormState });
							removeFromLS(CONSTANTS.STORAGE_KEYS.RTOFORM_DATA);
							navigate(routes.rtoFormHome);
						}}
					/>
					{debouncedQuery && isLoading ? <span>Searching...</span> : <div />}
				</div>
				<div>
					{isLoading && !data.length && !debouncedQuery ? (
						<>
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
							<MessageSkeleton />
						</>
					) : (
						<div className="mt-6">
							<DataTable
								keyField="_id"
								columns={columns}
								data={data}
								customStyles={{
									headCells: {
										style: {
											fontSize: "16px",
											fontWeight: "bold",
										},
									},
									rows: {
										style: {
											fontSize: "16px",
										},
									},
								}}
							/>
						</div>
					)}
					<Pagination
						metadata={metadata}
						onPageChange={(page) => {
							getRTOFormSubmissions(debouncedQuery, page);
						}}
					/>
				</div>
			</div>
		</NavigationWrapper>
	);
};

export default MyRtoFormSubmissions;
