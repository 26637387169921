import React, { useState } from "react";
import clsx from "classnames";
import { CommaSeperatedNumber } from "modules/common/components";
// import { ReactComponent as PriceRange } from 'modules/properties/assets/icons/price-range.svg'
// import { ReactComponent as DaysChange } from 'modules/properties/assets/icons/days-change.svg'
// import { ReactComponent as AreaEstimate } from 'modules/properties/assets/icons/area-estimate.svg'
// import { ReactComponent as Dropdown } from 'modules/properties/assets/icons/dropdown.svg'
// import { ReactComponent as Key } from 'modules/properties/assets/icons/key.svg'
// import { ReactComponent as Refinance } from 'modules/properties/assets/icons/refinance.svg'
import { ReactComponent as FamilyType } from "modules/properties/assets/icons/family-type.svg";
import { ReactComponent as Cooling } from "modules/properties/assets/icons/cooling.svg";
import { ReactComponent as Heating } from "modules/properties/assets/icons/heating.svg";
import { ReactComponent as YearBuilt } from "modules/properties/assets/icons/year-built.svg";
import { ReactComponent as Parking } from "modules/properties/assets/icons/parking.svg";
import { ReactComponent as Lot } from "modules/properties/assets/icons/lot.svg";
import ComparableProperty from "./ComparableProperty";
import { isLastItem } from "modules/common/utils/functions";
import moment from "moment";

const TABS = {
	HOME_VALUE: "Home value",
	// OWNER_TOOLS: "Owner tools",
	HOME_DETAILS: "Home details",
	NEIGBOURHOOD_DETAILS: "Neighbourhood details",
};

const HomeValue = ({ data, onOtherPropertyClick }) => {
	return (
		<div className="pb-5">
			<div className={`flex flex-wrap gap-5 items-start ${data?.price ? "mt-10 md:mt-20" : ""}`}>
				{data?.price && (
					<div className="flex flex-col">
						<span className="text-[20px] leading-[13.37px] 1436:text-[30px] 1436:leading-[27.5px] text-_25253C text-center">
							Price
						</span>
						<CommaSeperatedNumber
							number={data?.price}
							dollarSign
							className={
								"text-_25253C font-bold text-[27.23px] leading-[29.95px] text-center 1436:text-[45px] 1436:leading-[61.6px]"
							}
						/>
					</div>
				)}
				{data?.rehab && (
					<div className="flex flex-col">
						<span className="text-[20px] leading-[13.37px] 1436:text-[30px] 1436:leading-[27.5px] text-_25253C text-center">
							Rehab
						</span>
						<CommaSeperatedNumber
							number={data?.rehab}
							dollarSign
							className={
								"text-_25253C font-bold text-[27.23px] leading-[29.95px] text-center 1436:text-[45px] 1436:leading-[61.6px]"
							}
						/>
					</div>
				)}
				{data?.arv && (
					<div className="flex flex-col">
						<span className="text-[20px] leading-[13.37px] 1436:text-[30px] 1436:leading-[27.5px] text-_25253C text-center">
							ARV
						</span>
						<CommaSeperatedNumber
							number={data?.arv}
							dollarSign
							className={
								"text-_25253C font-bold text-[27.23px] leading-[29.95px] text-center 1436:text-[45px] 1436:leading-[61.6px]"
							}
						/>
					</div>
				)}

				{/* <div className='flex flex-col space-y-[17.52px]'>
                    <div className='flex items-center space-x-[15.79px]'>
                        <PriceRange />
                        <div className='flex flex-col space-y-[5.93px]'>
                            <span className='text-[20px] text-_25253C leading-[13.37px] 1436:text-[20px] 1436:leading-[27.5px]'>Estimated Range</span>
                            <div className='flex items-center'>
                                <CommaSeperatedNumber
                                    number={207150}
                                    className={"text-_25253C text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold"}
                                    dollarSign
                                />
                                <p className='text-_25253C text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold mx-1'>-</p>
                                <CommaSeperatedNumber
                                    number={276000}
                                    className={"text-_25253C text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold"}
                                    dollarSign
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center space-x-[15.79px]'>
                        <DaysChange />
                        <div className='flex flex-col space-y-[5.93px]'>
                            <span className='text-[20px] text-_25253C leading-[13.37px] 1436:text-[20px] 1436:leading-[27.5px]'>Last 30 days change</span>
                            <div className='flex items-center'>
                                <CommaSeperatedNumber
                                    number={2800}
                                    className={"text-_25253C text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold"}
                                    dollarSign
                                />
                                <p className='text-D50100 text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold ml-1'>(-1.04%)</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center space-x-[15.79px]'>
                        <AreaEstimate />
                        <div className='flex flex-col space-y-[5.93px]'>
                            <span className='text-[20px] text-_25253C leading-[13.37px] 1436:text-[20px] 1436:leading-[27.5px]'>Price per sq feet</span>
                            <div className='flex items-center'>
                                <CommaSeperatedNumber
                                    number={data?.pricePerSquareFoot}
                                    className={"text-_25253C text-[11.67px] 1436:text-[24px] 1436:leading-[34px] leading-[16.53px] font-bold"}
                                    dollarSign
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
			</div>
			{/* <div className='mt-[50px] flex flex-col space-y-[17px] justify-center 1436:mt-[150px]'>
                <div className='flex items-center justify-center space-x-2'>
                    <span className='text-[10px] text-_25253C 1436:text-[20px]'>Estimate history & details</span>
                    <Dropdown className='h-[7px] 1436:w-[13.76px] 1436:h-[10.75px]' />
                </div>
                <div className='border-E8EBEC border-[2px] pt-[30px] pb-[27px] px-[43px] rounded-[25px] mb-8'>
                    <div className='flex flex-col items-center justify-center space-y-3'>
                        <span className='text-[10px] text-_25253C text-center 1436:text-[20px]'>Estimated net proceeds</span>
                        <CommaSeperatedNumber className={"text-[20px] text-_25253C font-bold 1436:text-[32px]"} number={137914} dollarSign />
                    </div>
                    <div className='flex items-center justify-between py-5 border-b border-b-FEDF18'>
                        <span className='text-[10px] text-_25253C 1436:text-[20px]'>Est. selling price of your home</span>
                        <div className='border border-E8EBEC px-4 py-3 rounded-[15px] bg-F6F7FB'>
                            <CommaSeperatedNumber number={225000} dollarSign className={"text-_25253C text-[10px] 1436:text-[24px]"} />
                        </div>
                    </div>
                    <div className='flex items-center justify-between py-5 border-b border-b-FEDF18'>
                        <span className='text-[10px] text-_25253C 1436:text-[20px]'>Est. remaining mortgage</span>
                        <div className='border border-E8EBEC px-4 py-3 rounded-[15px] bg-F6F7FB'>
                            <CommaSeperatedNumber number={55000} dollarSign className={"text-_25253C text-[10px] 1436:text-[24px]"} />
                        </div>
                    </div>
                    <div className='flex items-center justify-between py-5 border-b border-b-FEDF18'>
                        <span className='text-[10px] text-_25253C 1436:text-[20px]'>Est. closing cost</span>
                        <div className='border border-none px-4 py-3 rounded-[15px] bg-transparent'>
                            <CommaSeperatedNumber number={225000} dollarSign className={"text-_25253C text-[10px] 1436:text-[24px]"} />
                        </div>
                    </div>
                    <div className='flex items-center justify-between pt-9'>
                        <span className='text-[10px] text-_25253C font-bold 1436:text-[20px]'>Total Cost</span>
                        <div className='border border-none px-4 py-3 rounded-[15px] bg-transparent'>
                            <CommaSeperatedNumber number={280000} dollarSign className={"text-_25253C font-bold text-[15px] 1436:text-[24px]"} />
                        </div>
                    </div>
                </div>
            </div> */}
			{/* <div className='mt-[50px] 1436:mt-[75px]'>
                <div className='flex flex-col space-y-[9px] 1436:space-y-[19px]'>
                    <h3 className='font-bold text-[20px] text-_25253C 1436:text-[32px]'>Comparable homes</h3>
                    <p className='text-sm text-_25253C 1436:text-[20px]'>
                        These are recently sold homes with similar features to this home, such as bedrooms, bathrooms, location, and square footage.
                    </p>
                </div>
                <div className='mt-5 grid grid-cols-2 gap-x-5 gap-y-10 1436:gap-x-[51.88px] 1436:mt-[50px]'>
                    {(data.comparableHomes || []).slice(0, 6).map(home => (
                        <ComparableProperty key={home.zpid}
                            images={home.miniCardPhotos}
                            bedroomCount={home.bedrooms}
                            bathroomCount={home.bathrooms}
                            address={home.address}
                            area={`${home.livingArea} ${home.livingAreaUnits}`}
                            price={home.price}
                            navigates={false}
                            onOtherPropertyClick={onOtherPropertyClick}
                        />
                    ))}
                </div>
            </div> */}
		</div>
	);
};

// const OwnerTools = () => {
//     return (
//         <div className='py-5'>
//             <div className='flex flex-col space-y-4'>
//                 <h3 className='text-_25253C text-[24px] font-bold 1436:text-[56px] 1436:leading-[61.6px]'>Owner Option</h3>
//                 <div className='border-[2px] border-E8EBEC rounded-[25px] p-4 1436:p-[22px]'>
//                     <div className='flex justify-between'>
//                         <div className='flex flex-col space-y-1 1436:space-y-[7px]'>
//                             <div className='flex items-center space-x-1 1436:space-x-[13px]'>
//                                 <Key className='w-4 1436:h-10 1436:w-10' />
//                                 <p className='text-[20px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]'>List your home for rent</p>
//                             </div>
//                             <div className='flex flex-col space-y-3 1436:space-y-5'>
//                                 <p className='text-[17px] text-_25253C 1436:text-[20px] 1436:leading-[27.5px]'>Find your next renter with Zillow Rental Manager. Plus, with online applications, you can quickly screen prospective tenants – for free.
//                                 </p>
//                                 <button className='text-_25253C underline text-sm mr-auto 1436:text-[20px] 1436:leading-[27.5px]'>Learn more</button>
//                             </div>
//                         </div>
//                         <div className='flex flex-col flex-shrink-0 mt-3 ml-2 space-y-3 1436:ml-8'>
//                             <CommaSeperatedNumber dollarSign className={"text-_25253C font-bold text-[17px] 1436:text-[20px]"}
//                                 number={1545}
//                                 suffix={"/mo"}
//                             />
//                             <span className='text-sm text-_25253C 1436:text-[20px]'>Rent Estimate</span>
//                         </div>
//                     </div>
//                     <div className='flex justify-between mt-6 1436:mt-[80px]'>
//                         <div className='flex flex-col space-y-1 1436:space-y-[7px]'>
//                             <div className='flex items-center space-x-1 1436:space-x-[13px]'>
//                                 <Refinance className='w-4 1436:h-10 1436:w-[39.83px]' />
//                                 <p className='text-[20px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Refinance and save</p>
//                             </div>
//                             <div className='flex flex-col space-y-3 1436:space-y-5'>
//                                 <p className='text-[17px] text-_25253C 1436:text-[20px] 1436:leading-[27.5px]'>Refinancing to a lower rate could help reduce your monthly payments and save thousands over the life of the loan.
//                                 </p>
//                                 <button className='text-_25253C underline text-sm mr-auto 1436:text-[20px] 1436:leading-[27.5px]'>See today's rates</button>
//                             </div>
//                         </div>
//                         <div className='flex flex-col flex-shrink-0 mt-3 ml-2 space-y-3 1436:ml-8'>
//                             <CommaSeperatedNumber dollarSign className={"text-_25253C font-bold text-[17px] 1436:text-[20px]"}
//                                 number={1386}
//                                 suffix={"/mo"}
//                             />
//                             <span className='text-sm text-_25253C 1436:text-[20px]'>Est. payment</span>
//                         </div>
//                     </div>
//                     <p className='mt-5 text-[10px] italic text-_25253C 1436:text-sm 1436:mt-8'>
//                         *Note: This property is not currently for sale or for rent on Zillow. The description and property data below may&pos;ve been provided by a third party, the homeowner or public records.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     )
// }

const PriceAndTaxHistory = ({ priceData = [], taxData = [] }) => {
	return (
		<div className="border-[2px] border-E8EBEC rounded-[25px] p-5">
			<h3 className="text-[20px] text-_25253C font-bold leading-none 1436:text-[40px] 1436:leading-[44px]">
				Price and Tax history
			</h3>
			{/* price history */}
			<div>
				<h4 className="mt-[5px] 1436:mt-[13px] 1436:text-[32px] 1436:leading-[35.2px] font-bold text-[18px] text-_25253C leading-none">
					Price history
				</h4>
				<div className="flex mt-[13px] pb-3 border-b border-b-E8EBEC">
					<span className="font-bold text-[18px] text-_25253C w-[20%] 1436:text-[24px]">Date</span>
					<span className="font-bold text-[18px] text-_25253C w-[30%] 1436:text-[24px] text-center">
						Event
					</span>
					<span className="font-bold text-[18px] text-_25253C w-[50%] 1436:text-[24px] text-center">
						Price
					</span>
				</div>
				<div>
					{(priceData || []).map((entry, index) => (
						<div
							key={index}
							className={clsx("py-2 flex 1436:py-[14px]", {
								"border-b border-b-E8EBEC": !isLastItem(index, priceData),
							})}
						>
							<span className="text-[18px] text-_25253C w-[20%] 1436:text-[24px]">{entry.date}</span>
							<span className="text-[18px] text-_25253C w-[30%] 1436:text-[24px] text-center">
								{entry.event}
							</span>
							<span className="text-[18px] text-_25253C w-[50%] 1436:text-[24px] text-center">
								<CommaSeperatedNumber dollarSign number={entry.price} />{" "}
								<CommaSeperatedNumber number={entry.pricePerSquareFoot} dollarSign suffix={"/sqft"} />
							</span>
						</div>
					))}
				</div>
			</div>
			{/* public tax history */}
			<div className="mt-6 1436:mt-[50px]">
				<h4 className="mt-[5px] font-bold text-[18px] text-_25253C leading-none  1436:text-[32px] 1436:leading-[35.2px]">
					Public tax history
				</h4>
				<div className="flex mt-[13px] 1436:mt-[30px] 1436:pb-[15px] pb-3 border-b border-b-E8EBEC">
					<span className="font-bold text-[18px] text-_25253C w-[20%] 1436:text-[24px]">Date</span>
					<span className="font-bold text-[18px] text-_25253C w-[40%] 1436:text-[24px] text-center">
						Property taxes
					</span>
					<span className="font-bold text-[18px] text-_25253C w-[40%] 1436:text-[24px] text-center">
						Tax assessment
					</span>
				</div>
				<div>
					{(taxData || []).map((entry, index) => (
						<div
							key={index}
							className={clsx("py-2 1436:py-[15px] flex ", {
								"border-b border-b-E8EBEC": !isLastItem(index, taxData),
							})}
						>
							<span className="text-[18px] text-_25253C w-[20%] 1436:text-[24px]">
								{moment(entry.time).format("YYYY")}
							</span>
							<span className="w-[40%] text-[18px] text-_25253C text-center 1436:text-[24px]">
								<CommaSeperatedNumber number={entry.value} dollarSign />{" "}
								<CommaSeperatedNumber
									prefix={entry.valueIncreaseRate > 0 ? "+" : ""}
									number={
										(entry.valueIncreaseRate || 0) !== 0
											? (entry.valueIncreaseRate || 0).toFixed(2)
											: entry.valueIncreaseRate || 0
									}
									suffix={"%"}
									className={clsx("text-D50100", { "!text-_41F153": entry.valueIncreaseRate >= 0 })}
								/>
							</span>

							<span className="w-[40%] text-[18px] text-_25253C text-center 1436:text-[24px]">
								<CommaSeperatedNumber number={entry.taxPaid} dollarSign />{" "}
								<CommaSeperatedNumber
									prefix={entry.taxIncreaseRate > 0 ? "+" : ""}
									number={
										(entry.taxIncreaseRate || 0) !== 0
											? (entry.taxIncreaseRate || 0).toFixed(2)
											: entry.taxIncreaseRate || 0
									}
									suffix={"%"}
									className={clsx("text-D50100", { "!text-_41F153": entry.taxIncreaseRate >= 0 })}
								/>
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const HomeDetails = ({ data, onHomeDetailChange }) => {
	return (
		<div className="py-5 1436:py-[47px]">
			<div className="flex flex-col space-y-3 1436:space-y-[22px]">
				<h3 className="text-_25253C text-[24px] font-bold leading-none 1436:text-[56px] 1436:leading-[61.6px]">
					Home Details
				</h3>
				<p className="text-_25253C text-[18px] font-bold leading-none 1436:text-[32px] 1436:leading-[35.2px]">
					Facts and features
				</p>
			</div>
			<div className="mt-4 1436:mt-[55px] grid grid-cols-2">
				<div className="flex items-center space-x-1 1436:space-x-6">
					<FamilyType className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Type:</span> {data?.homeType}
					</p>
				</div>
				<div className="flex items-center space-x-1 1436:space-x-6">
					<Cooling className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Cooling:</span> {data?.cooling || "-"}
					</p>
				</div>
			</div>
			{/* second row */}
			<div className="grid grid-cols-2 mt-4">
				<div className="flex items-center space-x-1 1436:space-x-6">
					<YearBuilt className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Year Built:</span> {data?.yearBuilt || "-"}
					</p>
				</div>
				<div className="flex items-center space-x-1 1436:space-x-6">
					<Parking className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Parking:</span> {data?.garageAttached}
					</p>
				</div>
			</div>
			{/* third row */}
			<div className="grid grid-cols-2 mt-4">
				<div className="flex items-center space-x-1 1436:space-x-6">
					<Heating className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Heating:</span> {data?.heating || "-"}
					</p>
				</div>
				<div className="flex items-center space-x-1 1436:space-x-6">
					<Lot className="w-4 1436:w-[30px] 1436:h-[30px]" />
					<p className="text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]">
						<span className="font-bold">Lot:</span>{" "}
						<CommaSeperatedNumber
							number={data?.lot}
							className={"text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]"}
							suffix={"ft²"}
							displayType={onHomeDetailChange ? "input" : "text"}
							onChange={(value) => onHomeDetailChange("lot", value)}
						/>
					</p>
				</div>
			</div>

			{/* interior */}
			<div className="mt-[30px] 1436:mt-[74.9px] flex flex-col space-y-3 1436:space-y-[31px] pb-5 1436:pb-[37px] border-b border-b-E8EBEC">
				<h3 className="text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px]">Interior</h3>
				<div className="flex space-x-4 1436:space-x-10">
					<div className="flex flex-col space-y-5 1436:space-y-[50px]">
						{/* bedrooms and bathrooms */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Bedrooms & bathrooms
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Bedrooms:{" "}
								<CommaSeperatedNumber
									onChange={(value) => onHomeDetailChange("bedroomCount", value)}
									displayType={onHomeDetailChange ? "input" : "text"}
									number={data?.bedroomCount}
								/>
							</p>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Bathrooms:{" "}
								<CommaSeperatedNumber
									onChange={(value) => onHomeDetailChange("bathroomCount", value)}
									displayType={onHomeDetailChange ? "input" : "text"}
									number={data?.bathroomCount}
								/>
							</p>
						</div>
						{/* basement */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Basement
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Basement: {data?.basement}
							</p>
						</div>
						{/* heating */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Heating
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Heating features: {data?.heating || "-"}
							</p>
						</div>
					</div>

					<div className="flex flex-col space-y-5">
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Cooling
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Cooling Features: {data?.cooling || "-"}
							</p>
						</div>
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Other Interior Features
							</h3>
							{(data?.interiorFeatures || []).map((interiorFeature, index) => (
								<p key={index} className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
									{interiorFeature}
								</p>
							))}
						</div>
					</div>
				</div>
			</div>

			{/* property */}
			<div className="mt-[30px] flex flex-col space-y-3 pb-5 1436:space-y-[30px] border-b border-b-E8EBEC">
				<h3 className="text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px]">Property</h3>
				<div className="flex space-x-4 1436:space-x-10">
					<div className="flex flex-col space-y-5 1436:space-y-[30px]">
						{/* parking */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Parking
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Parking features: {data?.garageAttached}
							</p>
						</div>
						{/* lot */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Lot
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Lot size:{" "}
								<CommaSeperatedNumber
									number={data?.lot}
									className={"text-_25253C text-sm 1436:text-[24px] 1436:leading-[34px]"}
									suffix={"ft²"}
									displayType={onHomeDetailChange ? "input" : "text"}
									onChange={(value) => onHomeDetailChange("lot", value)}
								/>
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Construction */}
			<div className="mt-[30px] flex flex-col space-y-3 pb-5 1436:space-y-[30px] border-b border-b-E8EBEC">
				<h3 className="text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px]">
					Construction
				</h3>
				<div className="flex space-x-4 1436:space-x-10">
					<div className="flex flex-col space-y-5 1436:space-y-[30px]">
						{/* Type & style */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Type & style
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Home type: {data?.homeType}
							</p>
						</div>
						{/* Material information */}
						{/* <div className='flex flex-col space-y-1'>
                            <h3 className='text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Material information</h3>
                            <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Foundation: Crawl/Raised</p>
                            <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Roof: Asphalt</p>
                        </div> */}
					</div>
					<div className="flex flex-col space-y-5">
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Condition
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Year built: {data?.yearBuilt || "-"}
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* Community & Neighborhood */}
			<div className="mt-[30px] flex flex-col space-y-3 1436:space-y-[30px]">
				<h3 className="text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px]">
					Community & Neighborhood
				</h3>
				<div className="flex space-x-4 1436:space-x-10">
					<div className="flex flex-col space-y-5 1436:space-y-[30px]">
						{/* Location */}
						<div className="flex flex-col space-y-1">
							<h3 className="text-[18px] font-bold text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Location
							</h3>
							<p className="text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]">
								Region: {data?.location || "-"}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-5 1436:mt-[44px]">
				<PriceAndTaxHistory
					priceData={Array.isArray(data?.priceHistory) ? data?.priceHistory : [] || []}
					taxData={Array.isArray(data?.taxHistory) ? data?.taxHistory : [] || []}
				/>
			</div>
		</div>
	);
};

const NeighborhoodDetails = ({ data, basicInfo, onOtherPropertyClick }) => {
	return (
		<div className="py-5 1436:py-[22px]">
			<div className="flex flex-col space-y-3 1436:space-y-[30px]">
				<h3 className="text-_25253C text-[24px] font-bold leading-none 1436:text-[56px] 1436:leading-[61.6px]">
					Neighborhood{basicInfo?.address?.neighborhood ? `: ${basicInfo?.address?.neighborhood}` : ""}
				</h3>
				<p className="text-_25253C text-[18px] font-bold leading-none 1436:text-[32px] 1436:leading-[35.2px]">
					Nearby homes
				</p>
			</div>
			{/* nearby homes */}
			<div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-10 1436:mt-[30px] 1436:gap-x-[30px]">
				{(data?.nearbyHomes || []).slice(0, 6).map((home, index) => (
					<ComparableProperty
						key={index}
						images={home.miniCardPhotos}
						address={home.address}
						onOtherPropertyClick={onOtherPropertyClick}
						// bedroomCount={}
						// bathroomCount={}
						// area={}
						price={home.price}
						navigates={false}
					/>
				))}
			</div>
			<p className="italic text-_25253C mt-2 text-[10px] 1436:mt-[34px] 1436:text-[14px]">
				Neighborhood stats provided by third party data sources.
			</p>
			{/* nearby schools */}
			<div className="mt-4 flex flex-col space-y-3 1436:mt-[76px] 1436:space-y-[22px]">
				<h3 className="text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px]">
					Nearby schools
				</h3>
				<div className="flex flex-col space-y-5 1436:space-y-[34px]">
					{(data.nearbySchools || []).map((school, index) => (
						<div key={index} className="flex items-center space-x-2 1436:space-x-[30px]">
							<div className="h-10 w-10 1436:h-[96px] 1436:w-[96px] rounded-full flex items-center justify-center bg-FEDF18">
								<span className="text-_25253C text-[18px] 1436:text-[24px]">
									<span className="font-bold text-[20px] 1436:text-[32px]">{school.rating}</span>/10
								</span>
							</div>
							<div className="flex flex-col space-y-[2px] 1436:space-y-[6px]">
								<h3 className="text-_25253C font-bold text-sm 1436:text-[32px] 1436:leading-[35.2px]">
									{school.name}
								</h3>
								<p className="text-[10px] text-_25253C 1436:text-[24px] 1436:leading-[34px]">
									Grades: {school.grades} Distance: {school.distance} mi
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
			{/* nearby estates */}
			{/* <div className='mt-5 1436:mt-[63px]'>
                <h3 className='text-[18px] text-_25253C font-bold 1436:text-[32px] 1436:leading-[35.2px] 1436:mb-[22px]'>Nearby Estates</h3>
                <div className='flex flex-col space-y-4 1436:space-y-[22px]'>
                    <div className='grid grid-cols-2'>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                    </div>
                    <div className='grid grid-cols-2'>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                    </div>
                    <div className='grid grid-cols-2'>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                    </div>
                    <div className='grid grid-cols-2'>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                    </div>
                    <div className='grid grid-cols-2'>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                        <p className='text-sm text-_25253C 1436:text-[24px] 1436:leading-[34px]'>Greenwood real estate</p>
                    </div>
                </div>
            </div> */}
		</div>
	);
};

const TabContent = ({ isVisible, children }) =>
	isVisible ? (
		<div className={clsx("pointer-events-all transition-all no-scrollbar overflow-y-auto h-full w-full")}>
			{children}
		</div>
	) : null;

const ExtensivePropertyDetail = ({
	homeValue,
	ownerTools,
	homeDetails,
	neighborhoodDetails,
	basicInfo,
	className,
	onOtherPropertyClick,
	onHomeDetailChange,
}) => {
	const [activeTab, setActiveTab] = useState(TABS.HOME_VALUE);
	return (
		<div
			className={clsx(
				"max-w-[725px] 1436:w-[725px] 1436:flex-shrink-0 mt-[37px] rounded-[6.61px] border-[0.66px] pt-[28.69px] px-[10.7px] 1436:pt-[40px] 1436:px-[39px] border-E8EBEC mb-[55px] 1436:mb-0",
				className
			)}
			style={{ boxShadow: "1.94483px 1.94483px 1.94483px 0px rgba(201, 199, 199, 0.50)" }}
		>
			<div className="flex overflow-x-auto whitespace-nowrap border-b-[0.49px] border-b-_25253C">
				{Object.values(TABS).map((tab) => (
					<button
						onClick={() => setActiveTab(tab)}
						className="flex flex-col space-y-[4.07px] 1436:space-y-[9px]"
						key={tab}
					>
						<div className="px-[7.78px] leading-none 1436:px-4">
							<span
								className={clsx("text-_25253C text-[14px] 1436:text-[20px] leading-none", {
									"font-bold": tab === activeTab,
								})}
							>
								{tab}
							</span>
						</div>
						<div className={clsx("h-[3.4px] 1436:h-[7px] w-full", { "bg-FEDF18": tab === activeTab })} />
					</button>
				))}
			</div>
			<div className="flex-1">
				<TabContent isVisible={activeTab === TABS.HOME_VALUE}>
					{homeValue && <HomeValue data={homeValue} onOtherPropertyClick={onOtherPropertyClick} />}
				</TabContent>
				{/* <TabContent isVisible={activeTab === TABS.OWNER_TOOLS}>
                    {ownerTools && <OwnerTools data={ownerTools} />}
                </TabContent> */}
				<TabContent isVisible={activeTab === TABS.HOME_DETAILS}>
					{homeDetails && <HomeDetails data={homeDetails} onHomeDetailChange={onHomeDetailChange} />}
				</TabContent>
				<TabContent isVisible={activeTab === TABS.NEIGBOURHOOD_DETAILS}>
					{neighborhoodDetails && basicInfo && (
						<NeighborhoodDetails
							onOtherPropertyClick={onOtherPropertyClick}
							data={neighborhoodDetails}
							basicInfo={basicInfo}
						/>
					)}
				</TabContent>
			</div>
		</div>
	);
};

export default ExtensivePropertyDetail;
