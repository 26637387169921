import { CommaSeperatedNumber } from 'modules/common/components'
import React, { useMemo } from 'react'
import clsx from 'classnames'
import { ReactComponent as Bathroom } from 'modules/properties/assets/icons/bathroom.svg'
import { ReactComponent as Bedroom } from 'modules/properties/assets/icons/bedroom.svg'
import { ReactComponent as Area } from 'modules/properties/assets/icons/area.svg'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'
import { parseAddress } from 'modules/common/utils/functions'
import { getEditedImageUrl } from '../utils/functions'

const ComparableProperty = ({ isSquare, images, address, bathroomCount, bedroomCount, area, price, propertyType, id, navigates = true, onOtherPropertyClick }) => {
    // const [isLoved, setIsLoved] = useState(false)
    const navigate = useNavigate()
    const goToDetail = () => {
        if (onOtherPropertyClick) {
            onOtherPropertyClick(parseAddress(address))
        }
        if (navigates) {
            navigate(CONSTANTS.routes.propertyDetail(id), { replace: true })
            window.scroll(0, 0)
        }
    }
    const displayableImage = useMemo(() => {
        if (images?.length > 0) {
            return images[0]?.url
        }
    }, [images])
    return (
        <div role='presentation' onClick={goToDetail} className={clsx('flex flex-col space-y-3 max-w-[272px] w-full', { "!w-[272px]": isSquare, "cursor-pointer": !!(navigates || onOtherPropertyClick) })}>
            <div className={clsx('h-[272px] overflow-hidden max-w-[272px] w-full rounded-10 relative', { "!w-[272px]": isSquare })}>
                <img src={getEditedImageUrl(displayableImage)} className='h-full w-full left-0 top-0 absolute object-cover' alt='Property' />
                {/* <div onClick={e => e.stopPropagation()}>
                    {isLoved ?
                        <Loved className='absolute top-5 right-5' onClick={() => setIsLoved(false)} /> :
                        <UnLoved className='absolute top-5 right-5 h-[50px] w-[50px]' onClick={() => setIsLoved(true)} />
                    }
                </div> */}
            </div>
            <div className='flex flex-col space-y-2 w-full'>
                <p className='text-base'>{parseAddress(address)}</p>
                <CommaSeperatedNumber dollarSign
                    className={"text-[18px] font-bold lg:text-[32px] leading-[35.2px] text-_25253C"}
                    number={price}
                />
                <div className='flex justify-between'>
                    {bedroomCount && <div className='flex items-center space-x-1'>
                        <Bedroom className='w-4 1436:w-[25.69px] 1436:h-[25.69px]' />
                        <span className='text-[10px] font-bold 1436:text-[15.46px]'>{bedroomCount} Bed</span>
                    </div>}
                    {bathroomCount && <div className='flex items-center space-x-1'>
                        <Bathroom className='w-4 1436:w-[25.69px] 1436:h-[25.69px]' />
                        <span className='text-[10px] font-bold 1436:text-[15.46px]'>{bathroomCount} Bath</span>
                    </div>}

                    {area && <div className='flex items-center space-x-1'>
                        <Area className='w-4 1436:w-[25.69px] 1436:h-[25.69px]' />
                        <CommaSeperatedNumber
                            suffix={"ft²"}
                            number={area}
                            className='text-[10px] font-bold 1436:text-[15.46px]' />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ComparableProperty