import React from 'react'
// import { ScrollSnap } from 'react-scroll-snap'
import SectionHeader from './SectionHeader'
import { ReactComponent as Correct } from 'modules/home/assets/icons/correct.svg'
import CarouselIndicator from './CarouselIndicator'
import { useHorizontalScroll } from 'modules/home/hooks'
import FadeIn from './FadeIn'

const cards = [
    {
        header: "Any Situation", description: "We respect your privacy, and have discreetly helped people in many situations, including:", list: [
            "Pre-Foreclosure",
            "Divorce",
            "Behind on Payments",
            "Inherited Property",
            "Burned Out Landlord",
            "Bankruptcy",
            "Relocation",
            "Fire Damaged"
        ]
    },
    {
        header: "Any Home Condition",
        description: `We understand that life happens, and you may need to sell your home "as is." We buy homes in any condition (even if the house needs repairs).`,
        list: [
            "Foundation Issues",
            "Water Damage",
            "Mold",
            "Fire Damage",
            "Extensive Repairs",
            "Needs New Roof",
            "Vandalism",
            "Hoarding"
        ]
    },
    {
        header: "In Your Area",
        description: "We buy houses in your area (and many others). It doesn't matter where you live, we close quickly and pay all CASH.",
        list: [
            "The Greater Indianapolis",
            "Surrounding Areas",
            "Out of State"
        ]
    }
]

const Card = ({ details: { header, description, list } }) => {
    return (
        <div className="bg-_14142B rounded-10 w-[272px] px-5 py-11 flex-shrink-0 card 1400:bg-transparent 1400:py-0">
            <div className='flex flex-col space-y-[5px]'>
                <h3 className='font-bold text-[18px] leading-[28px] text-white'>{header}</h3>
                <p className='text-white text-base leading-6'>{description}</p>
            </div>
            <div className='mt-[19.18px] flex flex-col space-y-[14px]'>
                {list.map((item, index) => (
                    <div className='flex space-x-3 items-center' key={index}>
                        <Correct />
                        <span className='text-base leading-base text-white'>{item}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

const WhoWeHelp = () => {
    const { containerRef, activeItemIndex } = useHorizontalScroll(313)

    return (
        <FadeIn rootMargin='-150px 0px'>
            <div className='pb-[90px] lg:pb-[187px] lg:mx-auto 1400:flex 1400:flex-col 1400:items-center'>
                <div className='flex flex-col 1400:flex-row 1400:space-x-[200px] md:mr-5'>
                    <div className='flex items-center justify-center px-5 1400:px-0 1400:items-start'>
                        <SectionHeader text={"Who We Help"} />
                    </div>
                    <div className='flex space-x-[41px] px-5 overflow-x-auto mt-[54px] 1400:mt-0 no-scrollbar scroll-snap-container lg:ml-[122px]' ref={containerRef}>
                        {cards.map((card, index) => (
                            <Card details={card} key={index} />
                        ))}
                    </div>
                </div>
                <div className='mt-[45px] px-5 1400:hidden'>
                    <CarouselIndicator
                        activeColor={"#FFFFFF"}
                        activeIndex={activeItemIndex}
                        total={cards.length}
                        inActiveColor={"#E8EBEC"}
                    />
                </div>
            </div >
        </FadeIn>
    )
}

export default WhoWeHelp