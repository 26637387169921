import React from 'react';
import { CommaSeperatedNumber } from 'modules/common/components';

const Label = ({ text, subtext }) => {
    return (
        <>
            {text && (
                <h3 className='font-bold text-[18px] text-_25253C leading-6 lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left'>
                    {text}
                </h3>
            )}
            {subtext && (
                <p className='font-normal text-[#6F6C90] !my-0'>
                    {subtext}
                </p>
            )}
        </>
    );
};

const Paragraph = ({ text }) => {
    return (
        <>
            {text && (
                <p className='text-_25253C border-b border-b-[#EEEEEE] leading-6 1436:text-[18px] 1436:leading-[34px]'>
                    {text}
                </p>
            )}
        </>
    );
};

const ImportantDetails = ({ detail }) => {
    const {
        homesteadExemption,
        currentYearlyTaxAmount,
        loan1Payoff,
        loan1Reinstatement,
        loan1CurrentPayment,
        loan2Payoff,
        loan2Reinstatement,
        loan2CurrentPayment,
        cashToSeller,
        rentBackAmount,
        resalePrice,
        downPaymentForResale,
        dropBoxLink
    } = detail.importantDetails;

    return (
        <div>
            <div className='lg:flex lg:justify-between 1400:mx-auto 1400:max-w-[1320px]'>
                <div className='flex flex-col items-center justify-center'>
                    <div className='shadow mx-auto z-[1] rounded-[11.89px] lg:rounded-[20.22px] px-[17px] lg:px-[30px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] md:mb-[30px]'>
                        <h3 className='mt-[29px] font-black text-[20px] text-_25253C leading-[41.3px] lg:text-[40px] md:mb-5 lg:font-bold lg:leading-[61.6px] lg:text-left'>
                            Important Details
                        </h3>
                        <div className='flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-5 md:mt-10'>
                            <Label text={"Homestead Exemption"} />
                            <Paragraph text={homesteadExemption} />

                            <Label text={"Current Yearly Tax Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={currentYearlyTaxAmount}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"First Loan Payoff Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan1Payoff}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"First Loan Reinstatement Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan1Reinstatement}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"First Loan Current Payment"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan1CurrentPayment}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Second Loan Payoff Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan2Payoff}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Second Loan Reinstatement Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan2Reinstatement}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Second Loan Current Payment"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={loan2CurrentPayment}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Cash to Seller"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={cashToSeller}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Rent Back Amount"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={rentBackAmount}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Resale Price"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={resalePrice}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Down Payment for Resale"} />
                            <Paragraph
                                text={
                                    <CommaSeperatedNumber
                                        number={downPaymentForResale}
                                        dollarSign
                                    />
                                }
                            />

                            <Label text={"Dropbox Link"} />
                            <a href={dropBoxLink} target='_blank' className='text-[#0000FF] underline' rel="noreferrer">{dropBoxLink}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportantDetails;
