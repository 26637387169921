import React from 'react'
// import clsx from 'classnames'
import { ReactComponent as Star } from 'modules/admin/assets/icons/star.svg'
import { ReactComponent as Calendar } from 'modules/admin/assets/icons/calendar.svg'
import moment from 'moment'

const Message = ({ message, title, onStar, onClick }) => {
    return (
        <div onClick={onClick} className='py-2 cursor-pointer lg:py-0 min-h-[50px] pl-5 lg:pr-[26px] rounded-10 bg-white border border-E8EBEC flex flex-col space-y-3 lg:space-y-0  lg:flex-row lg:items-center flex-shrink-0'>
            <div className='lg:w-[290px] lg:mr-[98px] flex items-center'>
                {/* <button className={clsx('h-6 w-6 mr-[9px] rounded-[6px] border-[2px] border-E8EBEC bg-white', { "!bg-_25253C": isChecked })}
                    onClick={() => setIsChecked(!isChecked)}
                /> */}
                <Star className={message?.isStarred ? "filled-star lg:mr-[21px] cursor-pointer" : "lg:mr-[21px] cursor-pointer"}
                    onClick={(e) => {
                        e.stopPropagation()
                        onStar(message)
                    }}
                />
                <span className='lg:w-[210px] font-bold text-base text-_25253C select-none'>{title}</span>
            </div>
            <p className='lg:w-[457px] whitespace-nowrap overflow-hidden text-ellipsis text-_25253C text-base select-none'>{message.message}</p>
            <div className='lg:ml-auto flex space-x-[7px] items-center pr-2 flex-shrink-0'>
                <Calendar />
                <span className='font-bold select-none text-[10px] text-_25253C'>
                    {moment(message.createdAt).format("Do MMM YYYY, hh:mm A")}
                </span>
            </div>
        </div>
    )
}

export default Message