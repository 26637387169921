/* eslint-disable react-hooks/exhaustive-deps */
import { NavigationWrapper, Pagination } from 'modules/common/components'
import React, { useEffect, useState } from 'react'
import { PropertiesFilter, Property, PropertySkeleton } from '../components'
import API from 'api'
import { usePropertyFilters } from '../hooks'
import { useDebounce, useDebouncedLoader } from 'modules/common/hooks'
import { isEmpty } from 'lodash'

const PropertyList = () => {
    const { applyingFilters, searchProperties, getProperties, loading, properties, state, dispatch, metaData } = usePropertyFilters({
        allPropertiesFetcher: API.getProperties,
        filteredPropertiesFetcher: API.searchProperties,
    })

    const [textQuery, setTextQuery] = useState("")
    const [hasMounted, setHasMounted] = useState(false)
    const debouncedTextQuery = useDebounce(textQuery, 1000)

    useEffect(() => {
        getProperties()
        setHasMounted(true)
    }, [getProperties])

    const isLoading = useDebouncedLoader(loading)

    useEffect(() => {
        if (!hasMounted) {
            return
        }
        const { lastAppliedFilters, ...rest } = state
        if (!isEmpty(debouncedTextQuery.trim())) {
            searchProperties({ ...rest, text: debouncedTextQuery })
        }
        else {
            searchProperties({ ...rest })
        }
    }, [debouncedTextQuery])

    return (
        <NavigationWrapper containerClassName="bg-F6F7FB" title={"Properties"}>
            <div className='bg-_25253C h-[83.07px] lg:hidden' />
            <div className='px-5 bg-F6F7FB pt-[41px] lg:pt-[188px] 1436:max-w-[1436px] 1436:mx-auto 1436:px-[60px]'>
                <PropertiesFilter 
                    applyFilters={searchProperties}
                    applyingFilters={applyingFilters}
                    state={state}
                    dispatch={dispatch}
                    {...{ textQuery, setTextQuery }}
                />
                <h2 className='text-_25253C text-[32px] lg:text-[40px] leading-[44px] font-bold mt-[43px] lg:mt-[89px] mb-[25px]'>Properties under Contact to Assign</h2>
                <p className='text-_25253C text-base leading-6 mb-5 1436:max-w-[1235px]'>
                    If you are a wholesaler and are tired of sending 30 text pictures to every potential buyer, for every property you have, along with the property description and having that information get lost and having to resend it again.
                    I get it! It takes a lot of time and effort. This is where we can help you.
                </p>
                <p className='text-_25253C text-base leading-6 mb-[67px] lg:mb-[44px]'>
                    <span className='font-bold'>Let us build your property its own property page for FREE.</span> Then, all you need to do is text your buyer a link with all the information
                </p>
                <div className='mb-[43px] flex flex-col space-y-3'>
                    <p className='text-xl leading-[22px] font-bold text-_25253C lg:text-[24px]'>Properties Reviewed, on Sale and Sold.</p>
                    <p className='text-xl leading-[22px] font-bold text-_25253C lg:text-[24px]'> Call For More Information</p>
                </div>
                <div className='flex flex-col space-y-[18.09px] 390:space-y-0 390:grid 450:grid-cols-2 md:grid-cols-330 390:gap-x-4 390:gap-y-[25px] pb-[113.22px] 1436:!grid-cols-[644px_644px] 1436:gap-x-7 1436:gap-y-[33px]'>
                    {isLoading ?
                        <>
                            <PropertySkeleton />
                            <PropertySkeleton />
                            <PropertySkeleton />
                            <PropertySkeleton />
                            <PropertySkeleton />
                            <PropertySkeleton />
                        </>
                        :
                        <>
                            {properties.map((property) => (
                                <Property key={property._id}
                                    price={""}
                                    status={property.status}
                                    address={property.address}
                                    bathroomCount={property.bathroomCount}
                                    bedroomCount={property.bedroomCount}
                                    area={property.area}
                                    propertyType={property.type}
                                    images={property.pictures}
                                    id={property._id}
                                />
                            ))}
                        </>
                    }
                </div>
                <Pagination
                    metadata={metaData}
                    onPageChange={(page) => {
                        const { lastAppliedFilters, ...rest } = state
                        searchProperties(rest, null, page)
                    }}
                />
            </div>
        </NavigationWrapper>
    )
}

export default PropertyList