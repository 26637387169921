/* eslint-disable react-hooks/exhaustive-deps */
import { BlockingLoader } from 'modules/common/components'
import { useAuth, useDebouncedLoader } from 'modules/common/hooks'
import React, { useEffect, useState } from 'react'
import SearchPropertyResult from './SearchPropertyResult'
import { ReactComponent as Exit } from 'modules/common/assets/icons/exit-grey.svg'
import API from 'api'
import toast from 'react-hot-toast'

const OtherPropertySearch = ({ address, onList, onClose, onOtherPropertyClick }) => {
    const [detail, setDetail] = useState(null)
    const [loading, setLoading] = useState(false)
    const isLoading = useDebouncedLoader(loading)
    const { searchResults, setSearchResults, userInfo, authInfo } = useAuth()


    const getDetail = async () => {
        if (searchResults?.[address]) {
            setDetail(searchResults[address])
            return
        }
        if (!userInfo && !authInfo) {
            toast.error("Please sign in or register to view more details about this property", { duration: 10000 })
            onClose()
            return
        }
        setLoading(true)
        try {
            const data = await API.admin.searchWithAddress(address)
            setDetail(data)
            setSearchResults({ ...searchResults, [address]: data })
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!address) {
            if (detail) {
                setDetail(null)
            }
            if (loading) {
                setLoading(false)
            }
        }
        else {
            setDetail("")
            getDetail()
        }
    }, [address])

    if (!address) {
        return null
    }
    return (
        <div className='z-[100000] bg-black/[.8] fixed top-0 left-0 h-screen w-screen flex items-center justify-center'>
            <button onClick={onClose} className='rounded-full h-8 w-8 absolute top-4 right-4 bg-white flex items-center justify-center'>
                <Exit />
            </button>
            <BlockingLoader loading={isLoading} />
            <div className='bg-white max-h-[80vh] overflow-y-auto rounded-lg'>
                {detail &&
                    <SearchPropertyResult
                        result={detail}
                        onList={onList}
                        onOtherPropertyClick={onOtherPropertyClick}
                    />
                }
            </div>

        </div>
    )
}

export default OtherPropertySearch