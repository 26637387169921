import { NavigationWrapper } from 'modules/common/components'

const TermsData = [
  {
    id: 0,
    header: "TERMS OF SERVICE",
  },
  {
    id: 1,
    header: "1. ACCEPTANCE OF TERMS",
    pgh:
      "By accessing or using the Frontier Street website and services, you agree to be bound by these terms and conditions. If you do not agree to these terms, please refrain from using our website and services.",
  },
  {
    id: 2,
    header: "2. USER RESPONSIBILITIES",
    pgh:
      "Users are responsible for maintaining the confidentiality of their account information and passwords. You agree to notify Frontier Street immediately of any unauthorized use of your account or any other breach of security.",
    pgh1:
      "Users are prohibited from using our website for any unlawful or prohibited purpose and agree to comply with all applicable laws and regulations.",
  },
  {
    id: 3,
    header: "3. INTELLECTUAL PROPERTY",
    pgh:
      "All content on the Frontier Street website, including text, graphics, logos, and images, is the property of Frontier Street and is protected by copyright laws. Unauthorized use of our content may violate copyright, trademark, and other laws.",
  },

  {
    id: 4,
    header: "4. DISCLAIMERS",
    pgh:
      "Frontier Street provides the website and services 'as is' without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or timeliness of the content on our website.",
    pgh1:
      "Use of our website and services is at your own risk. Frontier Street shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of our website.",
  },
  {
    id: 5,
    header: "5. LINKS TO THIRD-PARTY SITES",
    pgh:
      "Our website may contain links to third-party websites. These links are provided for your convenience only. Frontier Street does not endorse or control the content of these third-party sites and is not responsible for their content or practices.",
  },
  {
    id: 6,
    header: "6. CHANGES TO TERMS AND CONDITIONS",
    pgh:
      "Frontier Street reserves the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for changes.",
    pgh1:
      "Your continued use of the Frontier Street website and services after the posting of any changes to these terms constitutes acceptance of those changes.",
  },
  {
    id: 8,
    header: "7. INTELLECTUAL PROPERTY",
    pgh:
      "All content on the Frontier Street website, including text, graphics, logos, images, audio clips, and software, is the property of Frontier Street and is protected by applicable intellectual property laws. Unauthorized use of any content may violate copyright, trademark, and other laws.",
    pgh1:
      "You may not modify, reproduce, distribute, create derivative works, publicly display, or otherwise use any content from the Frontier Street website without prior written consent.",
  },
  {
    id: 9,
    header: "8. GOVERNING LAW",
    pgh:
      "These terms and conditions are governed by and construed in accordance with the laws of the state of Indiana, United States. Any legal action or proceeding relating to your access to or use of the Frontier Street website shall be instituted in the state or federal courts located in the state of Indiana.",
  },
  {
    id: 10,
    header: "9. ENTIRE AGREEMENT",
    pgh:
      "These terms and conditions constitute the entire agreement between you and Frontier Street and supersede all prior agreements and understandings, whether oral or written. Any ambiguities in the interpretation of these terms shall not be construed against the drafting party.",
  },
  {
    id: 11,
    header: "10. SEVERABILITY",
    pgh:
      "If any provision of these terms and conditions is determined to be invalid or unenforceable, such provision shall be deemed severed from the remainder of these terms, which shall remain in full force and effect.",
  },
  {
    id: 12,
    header: "11. CONTACT",
    pgh:
      "For any questions or concerns about these terms and conditions, please contact us at jack.crenshaw@frontierstreet.us.",
    pgh1:
      "These terms and conditions were last updated on 29th of November 2023.",
  },
]

const TcTag = ({ content }) => (
  <p className="text-[15px] md:text-[16px] font-[400]  text-_25253C text-left  md:my-[10px]  leading-[22px]">
    {content}
  </p>
)

const TermsCard = ({ header, pgh, pgh1, pgh2, pgh3, pgh4, pgh5, pgh6, pgh7, pgh8 }) => {
  return (
    <div className={`  flex flex-col justify-start w-1/1  md:w-[1000px] m-auto  py-4 md:py-3 px-3 md:px-8`}>
      <h1 className="font-bold text-[20px] gradient-text  md:text-[32px] leading-[29px] md:leading-[52px] text-left md:mt-[20px] mb-[32px] md:mb-[0px]">
        {header}
      </h1>
      <TcTag content={pgh} />
      {pgh1 && <TcTag content={pgh1} />}
      {pgh2 && <TcTag content={pgh2} />}
      {pgh3 && <TcTag content={pgh3} />}
      {pgh4 && <TcTag content={pgh4} />}
      {pgh5 && <TcTag content={pgh5} />}
      {pgh6 && <TcTag content={pgh6} />}
      {pgh7 && <TcTag content={pgh7} />}
      {pgh8 && <TcTag content={pgh8} />}

    </div>

  );
};

const TermsPage = () => {
  return (
    <NavigationWrapper containerClassName="bg-_25253C lg:bg-white">
      <div className="bg-_25253C h-[83.07px] lg:hidden"></div>
      <div className=" px-[20px] py-[60px] md:py-[150px]  bg-white">
        {TermsData.map(({ id, header, pgh, pgh1, pgh2, pgh3, pgh4, pgh5, pgh6, pgh7, pgh8, }) => (
          <TermsCard key={id} header={header} pgh={pgh} pgh1={pgh1} pgh2={pgh2} pgh3={pgh3} pgh8={pgh8} pgh4={pgh4} pgh5={pgh5} pgh6={pgh6} pgh7={pgh7} />
        ))}
      </div>
    </NavigationWrapper>

  );
};

export default TermsPage;
