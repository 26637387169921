import React, { useEffect, useState } from 'react'
import { ReactComponent as Logo } from 'modules/common/assets/icons/logo-dark.svg'
import { ReactComponent as Profile } from 'modules/admin/assets/icons/profile.svg'
import { ReactComponent as Password } from 'modules/admin/assets/icons/password.svg'
import clsx from 'classnames'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import AdminTopNav from 'modules/admin/components/navigation/AdminTopNav'
import { storeToLS, validators } from 'modules/common/utils/functions'
import { Button } from 'modules/common/components'
import CONSTANTS from 'modules/common/utils/constants'
import { useAuth, useDebouncedLoader } from 'modules/common/hooks'
import toast from 'react-hot-toast'
import API, { setAPIToken } from 'api'

const { routes, STORAGE_KEYS } = CONSTANTS


const Register = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const isLoading = useDebouncedLoader(loading)
    const { authInfo, setUserInfo, userInfo } = useAuth()


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: ""
        }
    })

    const signUp = async (data) => {
        if (data.password !== data.confirmPassword) {
            toast.error("Passwords do not match")
            return
        }
        setLoading(true)
        try {
            const { confirmPassword, ...rest } = data
            const response = await API.register(rest)
            setUserInfo(response)
            storeToLS(STORAGE_KEYS.USER_DATA, response)
            setAPIToken(response.token)
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
        }
        console.log(data)
    }

    useEffect(() => {
        if (userInfo) {
            navigate(routes.propertiesList)
            return
        }
        if (authInfo) {
            navigate(routes.admin.addListing)
        }
    }, [userInfo, authInfo, navigate])

    return (
        <div>
            <AdminTopNav />
            <div className={clsx('min-h-screen pt-[74px] px-5 bg-F6F7FB')}>
                <div className='max-w-[1440px] mx-auto'>
                    <div className='pb-[10vh] pt-[18.89vh] flex justify-center'>
                        <div className='bg-white border-E8EBEC border rounded-10 pt-[61px] pb-[53px] px-[46px] w-full max-w-[396px]'>
                            <div className='flex justify-center'>
                                <Logo className='w-[111.47px] h-[44.63px]' />
                            </div>
                            <form autoComplete={"off"} onSubmit={handleSubmit(signUp)} className='mt-[81.37px]'>
                                <div className='flex flex-col space-y-[37.84px]'>
                                    {/* email */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.email })}>
                                        <Profile className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input autoComplete='new-password' className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='Email'
                                            type='email'
                                            {...register("email", { required: true, validate: v => validators.validateEmail(v) })}
                                        />
                                    </div>
                                    {/* end email */}
                                    {/* first name */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.email })}>
                                        <Profile className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='First Name'
                                            {...register("firstName", { required: true, validate: v => validators.validateString(v) })}
                                        />
                                    </div>
                                    {/* end first name */}
                                    {/* last name */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.email })}>
                                        <Profile className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='Last Name'
                                            {...register("lastName", { required: true, validate: v => validators.validateString(v) })}
                                        />
                                    </div>
                                    {/* end last name */}
                                    {/* password */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.password })}>
                                        <Password className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder="Password"
                                            type='password'
                                            {...register("password", { required: true, validate: v => validators.validateString(v) })}
                                        />
                                    </div>
                                    {/* end password */}
                                    {/* password */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.password })}>
                                        <Password className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='Confirm Password'
                                            type='password'
                                            {...register("confirmPassword", { required: true, validate: v => validators.validateString(v) })}
                                        />
                                    </div>
                                    {/* end password */}
                                </div>
                                <div className='mt-[50px] flex items-center justify-center'>
                                    <Button
                                        text={"Register"}
                                        loading={isLoading}
                                        type='submit'
                                        classNames={"w-[229px] h-[50px]"}
                                    />
                                </div>
                            </form>
                            <div className='mt-4 flex items-center justify-center'>
                                <Link to={routes.login} className='text-center text-sm underline'>Already have an account ?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register