
import { useState, useEffect, useRef } from "react";

const useHorizontalScroll = (itemWidth) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const effectContainerRef = containerRef.current
        const onScroll = () => {
            const container = containerRef.current;
            const cardWidth = itemWidth || container.offsetWidth;
            const scrollLeft = container.scrollLeft;
            const newActiveCardIndex = Math.ceil(scrollLeft / cardWidth);

            setActiveItemIndex(newActiveCardIndex);
        };

        containerRef.current.addEventListener('scroll', onScroll);
        onScroll();

        return () => {
            effectContainerRef?.removeEventListener('scroll', onScroll);
        };
    }, [itemWidth]);

    return {
        activeItemIndex,
        containerRef
    }
}

export default useHorizontalScroll

