import React from 'react'
import { CommaSeperatedNumber } from 'modules/common/components'

const Label = ({ text, subtext }) => {
  return (
      <>
          {text && (
              <h3 className='font-bold text-[18px]  text-_25253C leading-6 lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold  lg:text-left'>
                  {text}
              </h3>
          )}
          {subtext && (
              <p className='font-normal text-[#6F6C90] !my-0'>
                  {subtext}
              </p>
          )}
      </>
  );
};

const Paragraph = ({ text }) => {
  return (
      <>
          {text && (
            <p className='text-_25253C  border-b border-b-[#EEEEEE] leading-6 1436:text-[18px] 1436:leading-[34px]'>
                {text}
            </p>
          )}
      </>
  );
};

const PurchaseDetails = ({detail}) => {
    const { 
      wholesaleFeeForBuyer,
      realEstateAgentFee,
      grossCashToSeller,
      buyerNeedsToPayForFurnitureOrMovingExpenses,
      buyerExpenses
    } = detail.purchaseRelatedDetails
   return (
         <div>  
             <div className=' lg:flex  lg:justify-between 1400:mx-auto 1400:max-w-[1320px]'>
                 {/* <SellYourHouseDiamonds className='absolute h-full w-full left-0 top-0 lg:block hidden' /> */}
                 <div className='flex flex-col items-center justify-center'>
                     <div className='mt-[29px] shadow mx-auto z-[1]  rounded-[11.89px] lg:rounded-[20.22px]   px-[17px] lg:px-[30px] pt-[36.25px] pb-[17.03px] lg:pb-[100.46px] ]'>
                      <h3 className='font-black text-[20px] text-_25253C leading-[24px]  lg:text-[40px] md:mb-5 lg:font-bold lg:leading-[61.6px] lg:text-left'>
                        Purchase Related Questions
                       </h3>
                         <div className='flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-10'>
                         <Label text={"How much is the wholesale fee for the BUYER if any?"}/> 
                         <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={wholesaleFeeForBuyer}
                                         dollarSign
                                      />
                                    }
                            /> 
                          <Label text={"How much is the real estate agent fee to the SELLER for the re-purchase if any?"}/> 
                          <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={realEstateAgentFee}
                                         dollarSign
                                      />
                                    }
                            />  
                          <Label text={"Gross Cash to Seller"}/>
                          <Paragraph 
                                   text={
                                     <CommaSeperatedNumber
                                        number={grossCashToSeller}
                                         dollarSign
                                      />
                                    }
                            />  

                          <Label text={"Does the BUYER need to pay for furniture, or moving expenses, or anything like that?"}/>
                          <Paragraph text={buyerNeedsToPayForFurnitureOrMovingExpenses ? "Yes" : "No"}/>
                          
                          <Label text={'If, "YES," please line-item those BUYER expenses'}/>
                          <Paragraph text={buyerExpenses}/>
                          
                         </div>  
                     </div>
                 </div>
                 
             </div>
         </div>
  )
}

export default PurchaseDetails