import React, { useEffect, useState } from 'react'
import PopularBlogSkeleton from './skeletons/PopularBlogSkeleton'
import API from 'api'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from 'modules/common/utils/constants'

const PopularBlogs = ({ refresh }) => {
    const [loading, setLoading] = useState(true)
    const [popularBlogs, setPopularBlogs] = useState([])
    const navigate = useNavigate()

    const getPopularBlogs = async () => {
        try {
            setLoading(false)
            const data = await API.getPopularBlogs()
            setPopularBlogs(data)
        }
        catch (e) {
            setLoading(false)
        }
    }


    useEffect(() => {
        getPopularBlogs()
    }, [])

    useEffect(() => {
        if (refresh) {
            getPopularBlogs()
        }
    }, [refresh])

    return (
        <div className='flex-shrink-0 rounded-[9.67px] border-[#848484] relative border-[0.97px] py-[23.2px] mb-[74px] h-[362px] 1300:h-auto self-start 1300:sticky 1300:top-0 overflow-y-auto no-scrollbar px-[27.07px] max-w-[360px] 1300:w-[360px]'>
            <h2 className='text-_25253C font-bold text-[30.93px] text-center mb-[36.52px]'>Popular Posts</h2>
            {
                loading ?

                    <PopularBlogSkeleton /> :
                    <div className='flex flex-col space-y-[27.07px]'>
                        {
                            popularBlogs.map(blog => (
                                <div
                                    role='presentation'
                                    onClick={() => navigate(CONSTANTS.routes.blogDetail(blog._id))}
                                    className='cursor-pointer flex items-center space-x-[15.47px]' key={blog._id}>
                                    <div className='w-[100.53px] h-[100.53px] border-[0.97px] border-E8EBEC rounded-[9.67px] flex-shrink-0 relative'>
                                        <img src={blog.coverPhotoUrl}
                                            alt='blog'
                                            className='absolute top-0 left-0 h-full w-full'
                                        />
                                    </div>
                                    <p className='text-[15.47px] leading-[23.2px] text-_25253C'>
                                        {blog.title}
                                    </p>
                                </div>

                            ))
                        }
                    </div>
            }
        </div>
    )
}

export default PopularBlogs