import React, { useEffect, useState } from 'react'
import clsx from 'classnames'
import { ReactComponent as Logo } from 'modules/common/assets/icons/logo-dark.svg'
import { ReactComponent as Profile } from 'modules/admin/assets/icons/profile.svg'
import { ReactComponent as Password } from 'modules/admin/assets/icons/password.svg'
import { Button } from 'modules/common/components'
import { useForm } from 'react-hook-form'
import { useAuth, useDebouncedLoader } from 'modules/common/hooks'
import API, { setAPIToken } from 'api'
import { removeFromLS, storeToLS, validators } from 'modules/common/utils/functions'
import CONSTANTS, { navigateToAdminRoute } from 'modules/common/utils/constants'
import { Link, useNavigate } from 'react-router-dom'
import AdminTopNav from '../../admin/components/navigation/AdminTopNav'

const { routes, STORAGE_KEYS } = CONSTANTS

const Login = () => {
    const [loading, setLoading] = useState(false)
    const isLoading = useDebouncedLoader(loading)
    const { authInfo, setAuthInfo, setUserInfo } = useAuth()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            password: ""
        }
    })

    const login = async (payload) => {
        removeFromLS(STORAGE_KEYS.AUTH_DATA)
        removeFromLS(STORAGE_KEYS.USER_DATA)
        setLoading(true)
        try {
            const data = await API.login(payload)
            if (data.roles.some(role => role === "ADMIN")) {
                setAuthInfo(data)
                storeToLS(STORAGE_KEYS.AUTH_DATA, data)
            }
            else {
                navigate(routes.propertiesList)
                setUserInfo(data)
                storeToLS(STORAGE_KEYS.USER_DATA, data)
            }
            setLoading(false)
            setAPIToken(data.token)
        }
        catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (authInfo) {
            navigateToAdminRoute(navigate, routes.admin.addListing)
        }

    }, [authInfo, navigate])


    return (
        <div>
            <AdminTopNav />
            <div className={clsx('min-h-screen pt-[74px] px-5 bg-F6F7FB')}>
                <div className='max-w-[1440px] mx-auto'>
                    <div className='pb-[10vh] pt-[18.89vh] flex justify-center'>
                        <div className='bg-white border-E8EBEC border rounded-10 pt-[61px] pb-[53px] px-[46px] w-full max-w-[396px]'>
                            <div className='flex justify-center'>
                                <Logo className='w-[111.47px] h-[44.63px]' />
                            </div>
                            <form onSubmit={handleSubmit(login)} className='mt-[81.37px]'>
                                <div className='flex flex-col space-y-[37.84px]'>
                                    {/* email */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.email })}>
                                        <Profile className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='Email'
                                            type='email'
                                            {...register("email", { required: true, validate: v => validators.validateEmail(v) })}
                                        />
                                    </div>
                                    {/* end email */}
                                    {/* password */}
                                    <div className={clsx('pb-[3px] border-b-[#CCD7D9] relative border-b-[2px]', { "!border-b-[red]": errors.password })}>
                                        <Password className='flex-shrink-0 absolute left-0 pointer-events-none bottom-[3px]' />
                                        <input className='pl-[47px] w-full flex-1 placeholder:text-E8EBEC text-base border-none outline-none text-black'
                                            placeholder='*******************'
                                            type='password'
                                            {...register("password", { required: true, validate: v => validators.validateString(v) })}
                                        />
                                    </div>
                                    {/* end password */}
                                </div>
                                <div className='mt-[50px] flex items-center justify-center'>
                                    <Button
                                        text={"Login"}
                                        loading={isLoading}
                                        type='submit'
                                        classNames={"w-[229px] h-[50px]"}
                                    />
                                </div>
                            </form>
                            <div className='mt-4 flex items-center justify-center'>
                                <Link to={routes.register} className='text-center text-sm underline'>Don't have an account ?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login