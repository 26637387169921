import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const PopularBlogSkeleton = () => {
    return (
        <div className='flex flex-col space-y-7'>
            <div className='flex items-center space-x-4'>
                <Skeleton
                    className='h-[104px] !w-[104px] rounded-10 flex-shrink-0'
                />
                <div className='flex-1'>
                    <Skeleton
                        className='!h-[71px] !w-full'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <Skeleton
                    className='h-[104px] !w-[104px] rounded-10 flex-shrink-0'
                />
                <div className='flex-1'>
                    <Skeleton
                        className='!h-[71px] !w-full'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <Skeleton
                    className='h-[104px] !w-[104px] rounded-10 flex-shrink-0'
                />
                <div className='flex-1'>
                    <Skeleton
                        className='!h-[71px] !w-full'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <Skeleton
                    className='h-[104px] !w-[104px] rounded-10 flex-shrink-0'
                />
                <div className='flex-1'>
                    <Skeleton
                        className='!h-[71px] !w-full'
                    />
                </div>
            </div>
            <div className='flex items-center space-x-4'>
                <Skeleton
                    className='h-[104px] !w-[104px] rounded-10 flex-shrink-0'
                />
                <div className='flex-1'>
                    <Skeleton
                        className='!h-[71px] !w-full'
                    />
                </div>
            </div>
        </div>
    )
}

export default PopularBlogSkeleton