import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { BlockingLoader, Button, Input, Select } from 'modules/common/components';
import toast from 'react-hot-toast';
import { EditorState, convertToRaw } from 'draft-js';
import { Controller, useForm } from 'react-hook-form';
import { validators } from 'modules/common/utils/functions';
import API from 'api';
import { useDebouncedLoader } from 'modules/common/hooks';
import CONSTANTS from 'modules/common/utils/constants';
import draftToHtml from 'draftjs-to-html';
import { useDropzone } from 'react-dropzone';

const { BLOG: { PRODUCTIVITY, TIPS_AND_TRICKS, MUST_READ } } = CONSTANTS

const blogTypes = [
    { label: PRODUCTIVITY, value: PRODUCTIVITY },
    { label: TIPS_AND_TRICKS, value: TIPS_AND_TRICKS },
    { label: MUST_READ, value: MUST_READ },
]

const AddBlog = () => {


    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        inputRef
    } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.png']
        },
        maxFiles: 1,
    });

    const { control, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: {
            title: '',
            blogType: null,
        }
    })
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false)

    const onSubmit = async (data) => {
        if (!editorState) {
            toast.error("Blog content is empty!")
            return
        }
        if (!acceptedFiles.length) {
            toast.error("Please add a cover photo")
            return
        }
        const content = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );
        if (!content?.trim()?.length || content === "<p></p>") {
            toast.error("Please input the publication body");
            return;
        }
        const payload = {
            content,
            blogType: data.blogType.value,
            title: data.title
        }
        setLoading(true);
        try {
            const formData = new FormData()
            Object.keys(payload).forEach(key => {
                formData.append(key, payload[key])
            })
            acceptedFiles.forEach(acceptedFile => {
                formData.append('coverPhoto', acceptedFile)
            })
            await API.admin.addNewBlog(formData)
            setEditorState(null)
            reset()
            setLoading(false)
            acceptedFiles.splice(0, acceptedFiles.length)
            inputRef.current.value = ''
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    const isLoading = useDebouncedLoader(loading)
    console.log(acceptedFiles)
    return (
        <form className='p-5 flex flex-col space-y-6 min-h-[50vh]' onSubmit={handleSubmit(onSubmit)}>
            <BlockingLoader
                loading={isLoading}
                text={"Submitting"}
            />
            <div className='flex flex-col space-y-1 z-[4]'>
                <span>Select blog type</span>
                <Controller
                    control={control}
                    rules={{ required: true }}
                    name='blogType'
                    render={({ field: { value, onChange } }) => (
                        <Select
                            options={blogTypes}
                            onChange={val => onChange(val)}
                            value={value}
                            hasError={!!errors.blogType}
                        />
                    )}
                />
            </div>
            <div className='flex flex-col space-y-1'>
                <span className='text-base leading-6 text-_25253C'>Add Cover Photo</span>
                <div
                    {...getRootProps()}
                    className='bg-white relative h-[100px] border border-E8EBEC rounded-10 flex flex-col items-center justify-center'>
                    <input
                        {...getInputProps()}
                    />
                    <div className='h-6 w-6 rounded-full flex items-center justify-center shadow-[1px_1px_4px_0px_#D9D9D9] mb-[9px]'
                    >
                        <span>+</span>
                    </div>
                    <div>
                        <h3 className='text-base leading-6 text-[#848484] text-center'>{acceptedFiles.length ? `Cover photo uploaded` : "Upload Cover Photo here"}</h3>
                        <p className='text-E8EBEC italic text-[10px] text-center'>Add 1 photo</p>
                    </div>
                </div>

            </div>
            <div className='flex flex-col space-y-1'>
                <span>Blog title</span>
                <Controller
                    control={control}
                    rules={{ validate: validators.validateString }}
                    name='title'
                    render={({ field: { value, onChange } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            hasError={!!errors.title}
                        />
                    )}
                />
            </div>
            <Editor
                onEditorStateChange={(edState) => {
                    setEditorState(edState);
                }}
                editorState={editorState}
                wrapperClassName="flex-1 h-full min-h-[40vh] border border-E8EBEC p-2"
            />
            <Button
                type='submit'
                text={'Submit'}
                loading={loading}
            />
        </form>

    )
}

export default AddBlog