// import React, { Component } from 'react';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

// class PropertyLocation extends Component {
//     state = {
//         location: null,
//     };

//     componentDidMount() {
//         const { address } = this.props;
//         this.geocodeAddress(address);
//     }

//     geocodeAddress = (address) => {
//         const { google } = this.props;
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ address: address }, (results, status) => {
//             if (status === 'OK' && results.length > 0) {
//                 try {
//                     const location = results[0].geometry.location;
//                     this.setState({ location: { lat: location.lat(), lng: location.lng() } });
//                 }
//                 catch (e) { }
//             } else {
//                 console.error('Geocode was not successful for the following reason:', status);
//             }
//         });
//     };

//     render() {
//         const { google } = this.props;
//         const { location } = this.state;

//         if (!location) {
//             return null;
//         }

//         return (
//             <div onClick={() => {
//                 if (location) {
//                     window.open(`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`, "_blank")
//                 }
//             }}
//             >
//                 <Map
//                     google={google}
//                     zoom={15}
//                     zoomControl={false}
//                     streetViewControl={false}
//                     // initialCenter={location}
//                     style={{ zIndex:2}}
//                     mapTypeControl={false}
//                     scaleControl={true}
//                     fullscreenControl={false}
//                 >
//                     <Marker position={location} />
//                 </Map>
//             </div>
//         );
//     }
// }

// export default GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_GOOGLE_KEY,
// })(PropertyLocation);

import React, { Component } from 'react';
import {  GoogleApiWrapper,  } from 'google-maps-react';
import MapIcon from "../assets/images/map.png"

class PropertyLocation extends Component {
    state = {
        location: null,
    };

    componentDidMount() {
        const { address } = this.props;
        this.geocodeAddress(address);
    }

    geocodeAddress = (address) => {
        const { google } = this.props;
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address: address }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
                try {
                    const location = results[0].geometry.location;
                    this.setState({ location: { lat: location.lat(), lng: location.lng() } });
                } catch (e) {
                    console.error('Error getting location:', e);
                }
            } else {
                console.error('Geocode was not successful for the following reason:', status);
            }
        });
    };

    render() {
        const { location } = this.state;

        if (!location) {
            return null;
        }

        return (
            <div
                onClick={() => {
                    if (location) {
                        window.open(`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`, "_blank");
                    }
                }}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
            >
                <img
                    src={MapIcon} // Replace with the path to your custom map image
                    alt="Property location"
                    style={{ height: '80px', width: '80px',  objectFit: 'cover' }}
                />
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
})(PropertyLocation);
