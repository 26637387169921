import React, { useEffect } from 'react'
import { ReactComponent as DiamondsMisc } from 'modules/home/assets/images/diamonds-misc.svg'
import { About, CashOfferInfo, FAQ, Hero, HowItWorks, WhoWeHelp, WhyUs } from '../components'
import { NavigationWrapper } from 'modules/common/components'
import { removeFromLS, retreiveFromLS, scrollToFaq } from 'modules/common/utils/functions'
import CONSTANTS from 'modules/common/utils/constants'

const Landing = () => {
    useEffect(() => {
        const visitationRequestToFaqs = retreiveFromLS(CONSTANTS.STORAGE_KEYS.WANTS_TO_VISIT_FAQS)
        if (visitationRequestToFaqs) {
            scrollToFaq()
        }
        return () => {
            removeFromLS(CONSTANTS.STORAGE_KEYS.WANTS_TO_VISIT_FAQS)
        }
    }, [])
    return (
        <NavigationWrapper>
            <div className='bg-_25253C'>
                <Hero />
                <div className='relative'>
                    <DiamondsMisc className='absolute top-0 left-0 h-full w-full scale-150 pointer-events-none' />
                    <About />
                    <WhoWeHelp />
                    <HowItWorks />
                </div>
                <WhyUs />
                <CashOfferInfo />
                <FAQ />
            </div>
        </NavigationWrapper>
    )
}

export default Landing