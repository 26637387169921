import { Input, PhoneNumberInput } from "modules/common/components";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";
import StepButtons from "../StepButtons";
import { useAuth } from "modules/common/hooks";
import API from "api";
import { useNavigate, useSearchParams } from "react-router-dom";
import CONSTANTS, { initialRTOFormState } from "modules/common/utils/constants";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import toast from "react-hot-toast";

const StepOne = ({ currentPage, onPrev, onNext, isUpdate }) => {
	let [searchParams, setSearchParams] = useSearchParams();
	const { userInfo, setUpdatingRtoForm, setRtoFormDetails } = useAuth();
	const navigate = useNavigate();
	const {
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			...initialRTOFormState.contactDetails,
			name: `${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`,
			email: userInfo?.email || "",
		},
	});

	const { storeFormDetails, isEmpty } = useRtoForm({
		setter: reset,
		step: formSteps.contactDetails,
	});

	useEffect(() => {
		if (isUpdate && searchParams.get("continuation") === "true") {
			onNext();
			searchParams.delete("continuation");
			setSearchParams(searchParams);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const onSubmit = async (data) => {
		// if it's step one of a brand new submission
		if (!isUpdate) {
			setUpdatingRtoForm(true);
			try {
				const formData = new FormData();
				const excluded = ["_id", "submit"];
				Object.keys(data).forEach((key) => {
					if (!excluded.includes(key)) {
						const value = data[key];
						if (!isEmpty(value)) {
							return formData.append(`contactDetails[${key}]`, data[key]);
						}
					}
				});
				const response = await API.submitRTOForm(formData);
				setRtoFormDetails(response);
				const id = response._id;
				setUpdatingRtoForm(false);
				navigate(CONSTANTS.routes.rtoFormUpdate(id) + "?continuation=true", { replace: true });
			} catch (e) {
				setUpdatingRtoForm(false);
			}
		} else {
			const success = await storeFormDetails(data);
			if (success) {
				onNext();
			}
		}
	};

	const fullAddressOfProperty = watch("fullAddressOfProperty");
	console.log(fullAddressOfProperty);
	return (
		<div>
			<div className="bg-white lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
				<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
				<div className="flex flex-col items-center justify-center">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-[29px] md:h-[400px] shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px]  px-[16px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
					>
						<h3 className="font-black max-w-[508px] text-[20px] text-_25253C leading-[41.3px] text-center lg:text-[24px] lg:font-bold lg:leading-[61.6px] lg:text-left">
							Contact Details
						</h3>
						<p className="font-normal text-center md:text-start text-[15px] md:text-[16px] text-[#6F6C90]">
							Contact info of the person submitting the form
						</p>
						<div className="flex flex-col space-y-[13.08px] lg:space-y-[30px] mt-10">
							<div className="flex  flex-col md:flex-row justify-between space-y-3 md:space-y-0 md:space-x-3 items-start">
								<Controller
									control={control}
									name="name"
									rules={{
										validate: validators.validateString,
									}}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Primary contact name of home owner"}
											onChange={onChange}
											hasError={!!errors.name}
											value={value}
											ref_={ref}
											className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										/>
									)}
								/>

								<Controller
									control={control}
									name="email"
									rules={{ validate: validators.validateEmail }}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Primary contacts primary email"}
											type="email"
											onChange={onChange}
											hasError={!!errors.email}
											value={value}
											ref_={ref}
											className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										/>
									)}
								/>
							</div>
							<div className="flex  flex-col md:flex-row md:space-y-0 justify-between space-y-3 md:space-x-3 items-start">
								<Controller
									control={control}
									name="phoneNumber"
									rules={{
										validate: validators.validateNonRequiredPhoneNumber,
									}}
									render={({ field: { onChange, value, ref } }) => (
										<PhoneNumberInput
											ref_={ref}
											placeholder={"Primary contacts phone number"}
											value={value}
											onChange={onChange}
											hasError={!!errors.phoneNumber}
											className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										/>
									)}
								/>

								{/* <Controller
									control={control}
									name="fullAddressOfProperty"
									rules={{
										validate: validators.validateNonRequiredString,
									}}
									render={({ field: { onChange, value, ref } }) => (
										<Input
											placeholder={"Property Address"}
											ref_={ref}
											value={value}
											onChange={onChange}
											hasError={!!errors.fullAddressOfProperty}
											className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										/>
									)}
								/> */}
								<div className="w-full">
									<GooglePlacesAutocomplete
										apiKey={process.env.REACT_APP_GOOGLE_KEY}
										debounce={500}
										minLengthAutocomplete={4}
										onLoadFailed={(error) =>
											toast.error("Could not inject Google script. Please reload the page", error)
										}
										selectProps={{
											isClearable: true,
											placeholder: "Property Address",
											// query: {
											// 	value: { description: fullAddressOfProperty },
											// 	label: fullAddressOfProperty,
											// },
											value: fullAddressOfProperty
												? { description: fullAddressOfProperty, label: fullAddressOfProperty }
												: null,
											onChange: (val) => {
												setValue("fullAddressOfProperty", val?.value?.description);
											},
											components: {
												IndicatorSeparator: null,
											},
											classNames: {
												container: () =>
													`!w-full h-[40px] lg:h-[59.98px] text-[14px] lg:text-base leading-[14.12px] lg:leading-6 w-full  outline-none !rounded-[7.93px] !lg:rounded-[13.48px] !bg-white placeholder:text-C9C7C7`,
												control: (state) =>
													"h-[40px] lg:h-[59.98px] text-[14px] lg:text-base leading-[14.12px] lg:leading-6 w-full !outline-none !rounded-[7.93px] !lg:rounded-[13.48px] !px-[12.69px] !lg:px-[21.57px] !bg-white placeholder:text-C9C7C7 !shadow-none",
											},
										}}
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<StepButtons currentPage={currentPage} onPrev={onPrev} onProceed={handleSubmit(onSubmit)} />
		</div>
	);
};

export default StepOne;
