import React, { useState, useEffect } from "react";
import { StepOne, StepTwo, StepThree, StepFour, StepFive, Important, Last } from "./formSteps";
import MultiStepProgressBar from "./multiStepBar/MultiStepBar";

function StepForm({ isUpdate }) {
	const [page, setPage] = useState(0);
	const [pageName, setPageName] = useState("");
	useEffect(() => {
		// Update pageName based on the value of page
		if (page === 1) {
			setPageName("Important Details");
		} else if (page === 2) {
			setPageName(" Property Details");
		} else if (page === 3) {
			setPageName("Mortgage Details");
		} else if (page === 4) {
			setPageName("Purchase Related Questions");
		} else if (page === 5) {
			setPageName("Lease Purchase Questions");
		} else {
			setPageName("Contact Details"); // Default value if page is not 1-6
		}
	}, [page]);

	function handlePageNumberClick(pageNumber) {
		setPage(pageNumber);
		window.scrollTo({ top: 0, behavior: "smooth" });
	  }

	function handleClick() {
		setPage(page + 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
		console.log("clicked");
	}

	function handlePrevious() {
		setPage(page - 1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	const commonProps = {
		currentPage: page,
		onPrev: handlePrevious,
		onNext: handleClick,
		isUpdate,
	};

	const conditionalComponent = () => {
		switch (page) {
			case 0:
				return <StepOne {...commonProps} />;
			case 1:
				return <Important {...commonProps} />;
			case 2:
				return <StepTwo {...commonProps} />;
			case 3:
				return <StepThree {...commonProps} />;
			case 4:
				return <StepFour {...commonProps} />;
			case 5:
				return <StepFive {...commonProps} />;
			case 6:
				return <Last {...commonProps} />;
			default:
				return <StepOne {...commonProps} />;
		}
	};
	

	return (
		<div className="my-10 md:my-20">
			<h3 className="font-black mb-4 text-[33.38px]  text-_25253C leading-[41.3px] text-center lg:text-[56px] lg:font-bold lg:leading-[61.6px]">
				{pageName}
			</h3>
			<MultiStepProgressBar page={page} onPageNumberClick={handlePageNumberClick} />
			{conditionalComponent()}
		</div>
	);
}

export default StepForm;
