import React from 'react'
import SectionHeader from './SectionHeader'
import FadeIn from './FadeIn'

const About = () => {
    return (
        <FadeIn>
            <div className='mt-[178.5px] lg:mt-[500px] px-5 pb-[108px] lg:pb-[187px]'>
                <div className='flex flex-col space-y-[19px] items-center justify-center lg:flex-row lg:space-y-0 lg:space-x-[200px] lg:items-start'>
                    <SectionHeader text={"About us"} />
                    <p className='text-base leading-6 text-center text-white lg:max-w-[421px] lg:text-left'>
                        Our mission is simple - to make selling your home as fast and easy as possible! We are a local company that specializes in helping home owners
                        sell their houses without agents, fees, or commissions! It doesn't matter what reason you have for selling, or the condition of your property
                        - we can help. We make all CASH offers, and close quickly.
                    </p>
                </div>
            </div>
        </FadeIn>
    )
}

export default About