import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import clsx from 'classnames'
import { ExtensivePropertyDetail, PropertyImagesCarousel } from 'modules/properties/components'
import { ReactComponent as Location } from 'modules/properties/assets/icons/location.svg'
import { formatText, parseAddress } from 'modules/common/utils/functions'
import { ReactComponent as Star } from 'modules/admin/assets/icons/star.svg'
import { Button } from 'modules/common/components'



const SearchPropertyResult = ({ result: detail, onList, onOtherPropertyClick }) => {
    const [rating, setRating] = useState(5)
    const homeValue = detail.homeValue
    const basicInfo = detail.basicInfo
    const homeDetails = detail.homeDetails
    const neighbourHoodDetails = detail.neighbourHoodDetails
    const miscData = detail.miscData


    return (
        <div className='px-5 bg-white pt-[59px] pb-5 rounded-10'>
            <div className='pb-2 flex items-center justify-between'>
                {onList && <Button
                    text={"Publish Property"}
                    classNames={"ml-auto"}
                    onClick={() => {
                        onList({
                            rating,
                            basicInfo,
                            homeDetails,
                            neighbourHoodDetails,
                            miscData,
                            homeValue
                        })
                    }}
                />}
            </div>
            <PropertyImagesCarousel
                imageUrls={(basicInfo.images || [])}
                smallImg={(basicInfo.images || []).length > 1 ? basicInfo.smallImage : null}
                showBackButton={false}
                address={parseAddress(basicInfo?.address || {})}
            />
            <div className='1400:mx-auto 1400:max-w-[1320px]'>
                <div className='mt-[51px] lg:mt-[95px] flex justify-between'>
                    <h3 className='text-_25253C font-bold text-[32px]'>{basicInfo?.tag}</h3>
                    <div className='flex flex-col space-y-[11px] flex-shrink-0'>
                        <span className='text-base leading-none text-_25253C ml-auto'>Ratings</span>
                        <div className='flex space-x-[3px] items-center'>
                            <NumericFormat
                                decimalScale={0}
                                allowNegative={false}
                                value={rating}
                                onValueChange={(values) => setRating(values.floatValue)}
                                isAllowed={(values) => {
                                    if (!values.value) return true;
                                    const { floatValue } = values;
                                    return floatValue < 6 && floatValue > 0;
                                }}
                                className={clsx("h-8 w-[39px] text-center bg-white border border-E8EBEC rounded-10 outline-none px-1 text-base")}
                            />
                            <div className='flex space-x-[2px]'>
                                {
                                    Array(5).fill(0).map((item, index) => {
                                        const isFilled = (rating || 0) > index
                                        return (
                                            <Star key={index} className={isFilled ? 'filled-star' : ''} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[17px] lg:mt-[10px] flex items-center space-x-[1.68px]'>
                    <Location className='1436:h-[46px] 1436:w-[46px]' />
                    <p className='text-[13.41px] text-_25253C leading-[19px] 1436:text-[24px] 1436:leading-[34px]'>{parseAddress(basicInfo?.address || {})}</p>
                </div>
                <div className='flex flex-col space-y-[59px] w-full'>
                    <div className='mt-[46.3px] flex flex-col space-y-6 mb-[37px] 1436:mt-[95px] 1436:mb-0 1436:flex-1'>
                        <h2 className='text-[24px] text-_25253C font-bold leading-[34px] 1436:text-[48px] 1436:leading-[52.8px]'>Property Description</h2>
                        <p className='text-_25253C text-base leading-6 1436:text-[24px] 1436:leading-[34px]'>
                            {formatText(basicInfo?.description)}
                        </p>
                    </div>
                    <ExtensivePropertyDetail
                        className={"!max-w-full !w-full"}
                        homeDetails={homeDetails}
                        homeValue={homeValue}
                        neighborhoodDetails={neighbourHoodDetails}
                        basicInfo={basicInfo}
                        onOtherPropertyClick={onOtherPropertyClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default SearchPropertyResult