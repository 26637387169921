import { Input } from "modules/common/components";
import { ReactComponent as Picture } from "modules/common/assets/images/picture.svg";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { pluralize, validators } from "modules/common/utils/functions";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { convertBlobToFile, createBlobFromFile } from "modules/rtoForm/util";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";
import StepButtons from "../StepButtons";
import UploadedFormMedia from "../UploadedFormMedia";
import { initialRTOFormState } from "modules/common/utils/constants";

const StepTwo = ({ currentPage, onPrev, onNext, isUpdate }) => {
	const [propertyFiles, setPropertyFiles] = useState([]);
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			...initialRTOFormState.propertyDetails,
		},
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".png"],
		},
		maxFiles: 20,
		maxSize: 3145728,
		onDrop: (acceptedFiles) => {
			setPropertyFiles(acceptedFiles);
		},
	});

	const customResetter = useCallback(async (data) => {
		const prevData = data || {};
		const { pictures = [], ...rest } = prevData;
		reset(rest);
		const errors = [];
		const availablePictures = [];

		await Promise.all(
			pictures.map(async (picture) => {
				const { file: newFile, error } = await convertBlobToFile(picture, "picture");
				if (error || !newFile) {
					errors.push("Error");
				} else {
					availablePictures.push(newFile);
				}
			})
		);
		setPropertyFiles(availablePictures);
		if (errors.length) {
			toast.error("Please reupload the property pictures");
		}
	}, []);

	const { storeFormDetails, pictures, submissionId } = useRtoForm({
		setter: customResetter,
		step: formSteps.propertyDetails,
	});

	const onSubmit = async (data) => {
		// if (!propertyFiles.length) {
		// 	toast.error("Please upload at least one picture of the property");
		// 	return;
		// }
		const errors = [];
		const pictures = [];
		propertyFiles.forEach((file, index) => {
			const { blob, error } = createBlobFromFile(file);
			if (error) {
				errors.push(propertyFiles[index].name);
			} else {
				pictures.push(blob);
			}
		});
		if (errors.length) {
			toast.error(
				`Please review the following pictures : ${errors.join(", ")}. There was an error while uploading them`
			);
			return;
		}
		if (isUpdate) {
			const { propertyPictures, ...rest } = data;
			if (await storeFormDetails({ ...rest }, propertyFiles, "pictures")) {
				onNext();
			}
		} else {
			if (await storeFormDetails({ ...data, pictures }, propertyFiles, "pictures")) {
				onNext();
			}
		}
	};

	const Label = ({ text, subtext, star }) => {
		return (
			<>
			{text && (
				<div className="flex items-center gap-2">
				{star && <p className="text-[#FF0000] text-[30px]">*</p>}
				<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
					{text}
				</h3>
				</div>
            )}
				{subtext && <p className="font-normal text-[14px] md:text-[16px] text-[#6F6C90] !my-0">{subtext}</p>}
			</>
		);
	};

	return (
		<div>
			<div className="bg-white lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
				<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
				<div className="flex flex-col items-center justify-center">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-[29px]  shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
					>
						<h3 className="font-black md:max-w-[508px] text-[20px] text-_25253C leading-[24px]  lg:text-[24px] lg:font-bold lg:leading-[40px] lg:text-left">
							Notes about the seller and their situation
						</h3>
						<p className="font-normal text-[#6F6C90]">
							Be as detailed as possible... e.g. Why are they selling? What is their motivation? What
							problem are we solving?Are they behind on their payments? When did they get behind? Why did
							they get behind? Are they currently making their payments? Ws there a sheriff sale scheduled
							yet? Why should we think they can afford rent payments if they were having trouble with
							their mortgage payments? What needs to be done in their financial world for them to be able
							to re-purchase the home in the future? etc
						</p>
						<div className="flex flex-col space-y-3 lg:space-y-[30px] mt-2">
							<Controller
								control={control}
								name="notesAboutSeller"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your answer"}
										onChange={onChange}
										hasError={!!errors.notesAboutSeller}
										value={value}
										ref_={ref}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label
							    star
								text={"Is the Homestead Exemption currently in place?"}
								subtext={"(Are property taxes currently approx. 1% of the assessed value?)"}
							/>
							<Controller
								control={control}
								name="homesteadExemptionCurrentlyInPlace"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.homesteadExemptionCurrentlyInPlace}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label star text={"How much are the current YEARLY property taxes?"} />
							<Controller
								control={control}
								name="yearlyPropertyTaxes"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										isNumeric
										prefix="$"
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.yearlyPropertyTaxes}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<div>
								<h3 className="font-bold  text-[16px] text-_25253C leading-[20.3px] lg:text-[20px] lg:font-bold lg:leading-[61.6px] lg:text-left">
									Property Images
								</h3>
								{isUpdate && (
									<div className="flex space-x-1 mb-4">
										{pictures.map((picture) => (
											<UploadedFormMedia
												key={picture._id}
												mediaType={"pictures"}
												mediaId={picture._id}
												mediaUrl={picture.url}
												submissionId={submissionId}
											/>
										))}
									</div>
								)}
								<div
									{...getRootProps()}
									className="!my-5 shadow lg:!my-[0px] border-[2px] border-white rounded-[13.48px] border-dashed h-[102.72px] lg:h-[156px] flex flex-col items-center justify-center space-y-[6px]"
								>
									<input {...getInputProps()} />
									<Picture />
									<p className="text-center text-[#C9C7C7] text-[10.54px] lg:text-base lg:leading-6">
										{propertyFiles.length
											? `${propertyFiles?.length} ${pluralize(
													propertyFiles?.length,
													"picture"
											  )} uploaded`
											: "Upload Pictures of the Property"}
									</p>
								</div>
							</div>
							<Label
								text={"Composition (e.g. beds, baths, loft, etc.)? "}
								subtext={
									"In a bath, if there is no shower, it is a half bath. Does the master bedroom have an en suite?"
								}
							/>
							<Controller
								control={control}
								name="composition"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										ref_={ref}
										placeholder={"Your answer"}
										value={value}
										onChange={onChange}
										hasError={!!errors.composition}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>

							<div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0">
								<div className="flex-1">
									<Label text={"Acreage"} />
									<Controller
										control={control}
										name="acreage"
										rules={{
											validate: validators.validateNonRequiredNumber,
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												isNumeric
												ref_={ref}
												placeholder={"Your answer"}
												value={value}
												onChange={onChange}
												hasError={!!errors.acreage}
												className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
											/>
										)}
									/>
								</div>
								<div className="flex-1">
									<Label text={"Total Finished Square Footage of House?"} />
									<Controller
										control={control}
										name="totalFinishedSquareFootageOfHouse"
										rules={{
											validate: validators.validateNonRequiredNumber,
										}}
										render={({ field: { onChange, value, ref } }) => (
											<Input
												isNumeric
												placeholder={"Your answer"}
												ref_={ref}
												value={value}
												onChange={onChange}
												hasError={!!errors.totalFinishedSquareFootageOfHouse}
												className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
											/>
										)}
									/>
								</div>
							</div>
							<Label
								text={"Property Amenities"}
								subtext={
									"(e.g. 40x60 pole barn, whole house generator, fenced yard, pond, finished basement, etc)"
								}
							/>
							<Controller
								control={control}
								name="propertyAmenities"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.propertyAmenities}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							
							<Label
								text={"Link to Property on Zillow (or other if not available on Zillow)?"}
								subtext={"https://www.zillow.com"}
							/>
							<Controller
								control={control}
								name="linkToProperty"
								rules={{ validate: validators.validateNonRequiredUrl }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.linkToProperty}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label text={"Zillow Zestimate(value)"} />
							<Controller
								control={control}
								name="zillowEstimate"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										ref_={ref}
										placeholder={"Your answer"}
										value={value}
										onChange={onChange}
										prefix="$"
										isNumeric
										hasError={!!errors.zillowEstimate}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label text={"Monthly rent amount being offered by seller for the lease purchase?"} />
							<Controller
								control={control}
								name="monthlyRentOfferedForLeasePurchase"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your answer"}
										ref_={ref}
										isNumeric
										value={value}
										prefix="$"
										onChange={onChange}
										hasError={!!errors.monthlyRentOfferedForLeasePurchase}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label text="How much do you think it is worth AS-IS (no repairs)?" />
							<Controller
								control={control}
								name="estimatedWorthAsIs"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										isNumeric
										prefix="$"
										onChange={onChange}
										hasError={!!errors.estimatedWorthAsIs}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label text={"Other Notes about the Property"} />
							<Controller
								control={control}
								name="otherNotesAboutProperty"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.otherNotesAboutProperty}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>

							{/* <Button
                                    loading={loading}
                                    type="submit" text={"Submit"} classNames={"!mt-0 py-[13.57px] w-full lg:!py-[23.07px]"} textClassNames={"lg:!text-[20px]"} /> */}
						</div>
					</form>
				</div>
			</div>
			<StepButtons currentPage={currentPage} onPrev={onPrev} onProceed={handleSubmit(onSubmit)} />
		</div>
	);
};

export default StepTwo;
