/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AdminProperty } from '../components'
import API from 'api'
import { PropertiesFilter, PropertySkeleton } from 'modules/properties/components'
import { usePropertyFilters } from 'modules/properties/hooks'
import { ReactComponent as Search } from 'modules/common/assets/icons/search.svg'
import { useDebounce, useDebouncedLoader } from 'modules/common/hooks'
import { isEmpty } from 'lodash'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { BlockingLoader } from 'modules/common/components'

const AdminPropertyList = () => {
    const { applyingFilters, searchProperties, getProperties, setProperties, loading, properties, state, dispatch, metaData } = usePropertyFilters({
        allPropertiesFetcher: API.admin.getAllListedProperties,
        filteredPropertiesFetcher: API.admin.searchListedProperties,
    })
    const [textQuery, setTextQuery] = useState("")
    const [hasMounted, setHasMounted] = useState(false)
    const [editing, setEditing] = useState(false)
    const debouncedTextQuery = useDebounce(textQuery, 1000)

    const isLoading = useDebouncedLoader(loading)
    const isEditing = useDebouncedLoader(editing)

    const onEdit = async (propertyId, newStatus) => {
        setEditing(true)
        try {
            await API.admin.editPropertyStatus({ propertyId, status: newStatus })
            setProperties(properties => properties.map(property => {
                if (property._id === propertyId) {
                    return { ...property, status: newStatus }
                }
                return property
            }))
            setEditing(false)
        }
        catch (e) {
            setEditing(false)
        }
    }

    useEffect(() => {
        getProperties()
        setHasMounted(true)
    }, [getProperties])

    useEffect(() => {
        if (!hasMounted) {
            return
        }
        const { lastAppliedFilters, ...rest } = state
        if (!isEmpty(debouncedTextQuery.trim())) {
            searchProperties({ ...rest, text: debouncedTextQuery })
        }
        else {
            searchProperties({ ...rest })
        }
    }, [debouncedTextQuery])

    const onEndReached = () => {
        if (metaData?.nextPage && !isLoading) {
            const { lastAppliedFilters, ...rest } = state
            searchProperties(rest, null, metaData.nextPage, false)
        }
    };
    const scrollRef = useBottomScrollListener(onEndReached, {
        offset: 30
    });

    return (
        <div className='pt-[6px] lg:pr-6 px-[10px]'>
            <BlockingLoader
                loading={isEditing}
            />
            <PropertiesFilter applyFilters={searchProperties}
                applyingFilters={applyingFilters}
                isMinimal
                state={state}
                dispatch={dispatch}
            />
            <div className='mt-[11px] flex flex-col space-y-3 lg:flex-row  lg:justify-between lg:items-center'>
                <h1 className='text-[20px] lg:text-[40px] font-bold text-_25253C'>Published Property List</h1>
                <div className='h-12 lg:h-[62px] rounded-[50px] border border-E8EBEC bg-white relative lg:w-[451px] overflow-hidden'>
                    <Search className='absolute left-[22px] top-[12px] lg:h-[38px] lg:w-[38px] pointer-events-none hidden lg:block' />
                    <input className='h-12 lg:h-[62px] pl-2 lg:pl-[70px] pr-2 w-full outline-none border-none text-base text-_25253C placeholder:text-E8EBEC'
                        placeholder='Search Property'
                        value={textQuery}
                        onChange={({ target }) => setTextQuery(target.value)}
                    />
                </div>
            </div>
            <div ref={scrollRef} className='max-h-[calc(100vh_-_247px)] overflow-y-auto grid gap-y-[30px] pb-6 630:grid-cols-538 mt-8 gap-x-[15px] fancy-scrollbar overflow-x-hidden'>
                {isLoading ?
                    <>
                        <PropertySkeleton />
                        <PropertySkeleton />
                        <PropertySkeleton />
                        <PropertySkeleton />
                    </>
                    :
                    <>
                        {properties.map((property) => (
                            <AdminProperty key={property._id}
                                price={property.price}
                                status={property.status}
                                address={property.address}
                                moreImagesLink={property.MoreImagesLink}
                                bathroomCount={property.bathroomCount}
                                bedroomCount={property.bedroomCount}
                                area={property.area}
                                propertyType={property.type}
                                images={property.pictures}
                                id={property._id}
                                onEdit={onEdit}
                            />
                        ))}
                    </>
                }
            </div>
        </div>
    )
}

export default AdminPropertyList