import API from "api";


export const createBlobFromFile = (file) => {
  try {
    const blob = URL.createObjectURL(file);
    return { blob, error: false };
  } catch (e) {
    return { blob: null, error: true };
  }
};

export const convertBlobToFile = async (blob, fileName) => {
  try {
    let file = await fetch(blob);
    file = await file.blob();
    file = new File([file], fileName || "image", { type: file.type });
    return { file, error: false };
  } catch (e) {
    return { file: null, error: true };
  }
};

const generateHtmlFromFormData = (formData) => {
  const entries = Array.from(formData.entries());
  return `
    <ul style="margin-bottom:12px">
      ${entries.map(([key, value]) => `<li><strong>${key}:</strong> ${value}</li>`).join('')}
    </ul>
  `;
};


export const sendEmailNotification = async (formData) => {
  const emailContent = generateHtmlFromFormData(formData);

  const emailData = {
      to: ["resolvetech2020@gmail.com"],
      subject: "New RTOW FORM Submitted",
      text: "An RTOW FORM has been submitted successfully.",
      html: `<p>An RTOW FORM has been submitted successfully.</p>${emailContent}`,
  };

  try {
      const response = await API.admin.sendEmail(emailData);
      console.log("Email sent successfully:", response);
  } catch (error) {
      console.error("Error sending email:", error);
  }
};
