import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const BlogCardSkeleton = () => {
    return (
        <Skeleton className='bg-F6F7FB min-h-[418px] border-[0.82px] border-E8EBEC rounded-[8.21px] overflow-hidden'>
            <Skeleton className='h-[256.22px] rounded-[8.21px] relative overflow-hidden' >
                <Skeleton
                    className='object-cover absolute h-full w-full left-0 top-0'
                />
            </Skeleton>
            <Skeleton className=' px-5 py-[15px] h-full flex flex-col space-y-5'>
                <Skeleton className='text-_25253C font-bold text-[19.71px] leading-[27.92px] w-full'></Skeleton>
                <span className='text-[#848484] font-bold text-[11.5px]'>Nov 30, -0001</span>
            </Skeleton>
        </Skeleton>
    )
}

export default BlogCardSkeleton