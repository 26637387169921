import { Input } from "modules/common/components";
import { ReactComponent as SellYourHouseDiamonds } from "modules/home/assets/images/sell-your-house-diamonds.svg";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { validators } from "modules/common/utils/functions";
import StepButtons from "../StepButtons";
import useRtoForm, { formSteps } from "modules/rtoForm/hooks/useRtoForm";
import { initialRTOFormState } from "modules/common/utils/constants";

const StepThree = ({ currentPage, onPrev, onNext }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			...initialRTOFormState.mortgageDetails,
		},
	});

	const { storeFormDetails } = useRtoForm({
		setter: reset,
		step: formSteps.mortgageDetails,
	});

	const onSubmit = async (data) => {
		if (await storeFormDetails(data)) {
			onNext();
		}
	};
	const Label = ({ text, subtext, subtext2, star }) => {
		return (
			<>
				{text && (
				<div className="flex items-center gap-2">
				{star && <p className="text-[#FF0000] text-[30px]">*</p>}
				<h3 className="font-bold text-[16px] text-_25253C leading-[24px] lg:leading-[40px] !mb-0 lg:text-[20px] lg:font-bold lg:text-left">
					{text}
				</h3>
				</div>
            )}
				{subtext && <p className="font-normal text-[14px] md:text-[16px] text-[#6F6C90] !my-0">{subtext}</p>}
			</>
		);
	};

	return (
		<div>
			<div className="bg-white  lg:flex lg:justify-between  lg:pr-0 1400:max-w-[1400px] 1400:mx-auto">
				<SellYourHouseDiamonds className="absolute h-full w-full left-0 top-0 lg:block hidden" />
				<div className="flex flex-col items-center justify-center">
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="mt-[29px] shadow mx-auto z-[1] bg-white rounded-[11.89px] lg:rounded-[20.22px] lg:w-[900px] px-[17px] lg:px-[30px] pt-[36.25px] lg:pt-[60px] pb-[17.03px] lg:pb-[100.46px] mb-[30px]"
					>
						<h3 className="font-black text-[20px] text-_25253C leading-[41.3px]  lg:text-[24px] lg:font-bold lg:leading-[61.6px] lg:text-left">
							FIRST MORTGAGE DETAILS
						</h3>
						<div className="flex flex-col  mt-5">
							<Label text={"Is there a First Mortgage on the property?"} />

							<Controller
								control={control}
								name="firstMortgageExists"
								rules={{ required: true }}
								render={({ field }) => (
									<div>
										<input
											type="radio"
											id="compositionOption1"
											value=""
											checked={field.value === true}
											onChange={(value) => setValue("firstMortgageExists", true)}
										/>
										<label htmlFor="compositionOption1">Yes</label>

										<input
											type="radio"
											id="compositionOption2"
											value="option2"
											checked={field.value === false}
											onChange={(value) => setValue("firstMortgageExists", false)}
										/>
										<label htmlFor="compositionOption2">No</label>
									</div>
								)}
							/>
							<Label
								text={"Payoff Amount"}
								star
								subtext={
									"An official payoff should be requested from the lender to determine this amount."
								}
							/>
							<Controller
								control={control}
								name="payoffAmount"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										ref_={ref}
										placeholder={"Your answer"}
										value={value}
										onChange={onChange}
										isNumeric
										prefix="$"
										hasError={!!errors.payoffAmount}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>

							<Label star text={"Reinstatement Amount"} subtext={"If they aren't behind, put a 0 "} />
							<p>
								This is the amount that would bring the loan current (missed payments, late fees, legal
								fees, etc). An official reinstatement amount should be requested from the lender or the
								foreclosure attorney
							</p>
							<Controller
								control={control}
								name="reInstatementAmount"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.reInstatementAmount}
										isNumeric
										prefix="$"
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label star text={"Total Monthly Payment"} />
							<Controller
								control={control}
								name="totalMonthlyPayment"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										isNumeric
										prefix="$"
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.totalMonthlyPayment}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							<Label text={"Interest Rate of the Loan (not APR)"} />
							<Controller
								control={control}
								name="interestRateOfLoan"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										isNumeric
										suffix=" %"
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.interestRateOfLoan}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
							
							<Label
								text={"Approximately how much principal is paid down with each monthly payment?"}
								subtext={"A recent mortgage statement or the online account may tell you."}
								subtext2={
									'Or, using this link, https://www.bankrate.com/mortgages/amortization-calculator/, You can also put in the initial loan amount, loan term, interest rate, and date the loan was started... once it processes, in the middle, change it from "chart" to schedule and scroll to today\'s date on the amortization table to find it.'
								}
							/>
							<Controller
								control={control}
								name="approximatePrincipalPaidDownMonthly"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.approximatePrincipalPaidDownMonthly}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										isNumeric
										prefix="$"
									/>
								)}
							/>
							<Label text={"Approximately how long is left on the loan (IN YEARS)?"} />
							<Controller
								control={control}
								name="durationLeftOnLoanInYears"
								rules={{ validate: validators.validateNonRequiredNumber }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										value={value}
										onChange={onChange}
										isNumeric
										hasError={!!errors.durationLeftOnLoanInYears}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
										ref_={ref}
									/>
								)}
							/>
							<Label
								text={
									"Are there any other mortgages, HELOCS, loan modifications, judgements, liens, etc. against the property?"
								}
							/>
							<Controller
								control={control}
								name="otherMortgagesAgainstPropertyExist"
								render={({ field }) => (
									<div>
										<input
											type="radio"
											id="compositionOption1"
											value="Yes"
											{...field}
											checked={field.value === true}
											onChange={(value) => setValue("otherMortgagesAgainstPropertyExist", true)}
										/>
										<label>Yes</label>

										<input
											type="radio"
											id="compositionOption2"
											value="No"
											{...field}
											checked={field.value === false}
											onChange={(value) => setValue("otherMortgagesAgainstPropertyExist", false)}
										/>
										<label>No</label>
									</div>
								)}
							/>
							<Label
								text='If,"YES," please elaborate on these additional encumbrances.?'
								subtext={"For each please list:"}
								subtext2={
									"Type of encumbrance + Loan Balance or Payoff + Reinstatement amount if behind + Interest rate (also if fixed or variable) + Monthly payment + Principal reduction with each payment + How long is left until it is paid off or forgiven"
								}
							/>
							<Controller
								control={control}
								name="additionalEncumbrances"
								rules={{ validate: validators.validateNonRequiredString }}
								render={({ field: { onChange, value, ref } }) => (
									<Input
										placeholder={"Your Answer"}
										ref_={ref}
										value={value}
										onChange={onChange}
										hasError={!!errors.additionalEncumbrances}
										className={"shadow border-[#EFF0F6] placeholder:text-[#6F6C90]"}
									/>
								)}
							/>
						</div>
					</form>
				</div>
			</div>
			<StepButtons {...{ currentPage, onPrev, onProceed: handleSubmit(onSubmit) }} /> 
		</div>
	);
};

export default StepThree;
